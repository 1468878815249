import React from "react";
import "./detail_heading.css";

const DetailHeading = (props: any) => {
  return (
    <div className="detail" {...props}>
      <h5 className="detail-heading">{props.heading}</h5>
      {props.subHeading && <h6 className="detail-sub-heading">{props.subHeading}</h6>}
    </div>
  );
};

export default DetailHeading;
