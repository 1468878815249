import Locality from "../../../models/Locality";
import {
  addFirebaseDataById,
  getFirebaseData,
  getFirebaseSingleData,
  removeFirebaseData,
} from "../../firebaseService";
import * as actionTypes from "../actionType";

const UpdateLocalityData = (data: Locality[], dispatch: any) => {
  dispatch({
    type: actionTypes.UpdateLocalityData,
    data: data,
  });
};

export const getLocalitiesData = (
  dispatch: any,
  callback: (localities: Locality[]) => void = () => {}
) => {
  getFirebaseData("localities", (localities: Locality[]) => {
    if (localities !== null) {
      UpdateLocalityData(localities, dispatch);
      callback(localities);
    }
  });
};

export const removeLocalityData = (
  id: string,
  callback: (err: Error | null) => void
) => {
  removeFirebaseData(`/localities/${id}`, callback);
};

export const updateLocalityData = (
  data: Locality,
  callback: (err: Error | null) => void
) => {
  addFirebaseDataById("localities", data, callback);
};

export const getLocalityData = (
  id: string,
  callback: (error?: any) => void = () => {}
) => {
  getFirebaseSingleData("localities", "id", id, (result: any) => {
    const locality = result as Locality;
    if (locality) {
      callback(locality);
    } else {
      callback(result);
    }
  });
};

const reducer = (state: any = { locality_data_list: [] }, action: any) => {
  switch (action.type) {
    case actionTypes.UpdateLocalityData:
      return {
        ...state,
        locality_data_list: action.data,
      };
    default:
      return state;
  }
};
export default reducer;
