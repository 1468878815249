import React, { ChangeEvent, useEffect, useState } from "react";
import BackLink from "../../../components/common/back_link/back_link";
import DetailHeading from "../../../components/common/detail_heading/detail_heading";
import { connect } from "react-redux";
import { Button, Form, Input, message, Select } from "antd";
import "./user_detail_page.css";
import User from "../../../models/User";
import ParamTypes from "../../../models/ParamTypes";
import { useHistory, useParams } from "react-router-dom";
import { RoleType } from "../../../constants/enums";
import {
  getSingleUserData,
  updateUserData,
} from "../../../services/redux/reducers/user_data_service";

const { Option } = Select;

const layout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 2, span: 12 },
};

const UserDetailPage = (props: any) => {
  const history = useHistory();
  let { id } = useParams<ParamTypes>();
  const [user, setUser] = useState<User | undefined>(undefined);
  
  useEffect(() => {
    getSingleUserData(id, (user: User) => {
      setUser(user);
    });
  }, [id]);

  const handleUserChange = (user: User) => {
    setUser(user);
  };
  const onFinish = (values: any) => {
    message.success("Success:", values);
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error("Failed:", errorInfo);
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    
    user && updateUserData(user, (err) => {
      if (err) {
        message.error(err.message);
      } else {
        history.push("/manage-users");
      }
    });
  };

  return (
    <div>
      <BackLink link="/manage-users" />
      <DetailHeading
        heading="User details"
        subHeading="Showing Details for a user"
        style={{ marginBottom: "48px" }}
      />
      <Form
        {...layout}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name="name"
          label="Name"
          initialValue={user?.name}
          rules={[{ required: true, message: "Please enter a name" }]}
        >
          <Input
            value={user?.name}
            onChange={(e: ChangeEvent<{ value: string }>) => {
              handleUserChange({ ...user!!, name: e.target.value });
            }}
          />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          initialValue={user?.email}
          rules={[{ required: true, message: "Please enter a email" }]}
        >
          <Input
            value={user?.email}
            onChange={(e: ChangeEvent<{ value: string }>) => {
              handleUserChange({ ...user!!, email: e.target.value });
            }}
            readOnly
          />
        </Form.Item>
        <Form.Item
          name="role"
          label="Role"
          initialValue={user?.role}
          rules={[{ required: true, message: "Please select a role" }]}
        >
          <Select
            value={user?.role}
            onChange={(value: string) => {
              handleUserChange({ ...user!!, role: value });
            }}
            allowClear
          >
            <Option value="">Select one</Option>
            <Option value={RoleType.USER}>User</Option>
            <Option value={RoleType.ADMIN}>Admin</Option>
          </Select>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <div className="user-detail-buttons">
            <Button
              size="large"
              type="default"
              onClick={(e: any) => history.push("/manage-users")}
            >
              CANCEL
            </Button>
            <Button
              size="large"
              type="primary"
              onClick={handleSave}
            >
              SAVE
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any, _: any) => ({
  dispatch: dispatch,
});

const mapStateToProps = (state: any, _: any) => ({
  UserData: state.UserData,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailPage);
