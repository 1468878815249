import User from "../../../models/User";
import { getFirebaseSingleData } from "../../firebaseService";
import * as actionTypes from "../actionType";
const initialState = {
  userInfo: { name: "" },
  currentMenuIndex: 0,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.UpdateUserData:
      return {
        ...state,
        userInfo: action.data,
      };
    case actionTypes.SetMenu:
      return {
        ...state,
        currentMenuIndex: action.data,
      };
    default:
      return state;
  }
};
export const UpdateUserData = (data: any, dispatch: any) => {
  dispatch({
    type: actionTypes.UpdateUserData,
    data: data,
  });
};
export const getUserData = (
  id: string,
  dispatch: any,
  callback: (error?: any) => void = () => {}
) => {
  getFirebaseSingleData("users", "uid", id, (result: any) => {
    const user = result as User;
    if (user) {
      UpdateUserData(user, dispatch);
      callback(user);
    } else {
      callback(undefined);
    }
  });
};
export const SetMenuIndex = (key: number, dispatch: any) => {
  dispatch({
    type: actionTypes.SetMenu,
    data: key,
  });
};

export default reducer;
