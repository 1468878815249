import React from "react";
import { Col, Row } from "antd";
import DashboardSubTitle from "../../../components/dashboard_page_components/dashboard_sub_title/dashboard_sub_title";
import OneIssueWidget from "../../../components/one_issue_widget/one_issue_widget";
import Issue from "../../../models/Issue";
import "./popular_issues_container.css";
import { RoleType } from "../../../constants/enums";
function PopularIssuesContainer(props: any) {
  const { issuedVoteList, role, title } = props;
  const issuesUIList = issuedVoteList.map((issueInfo: Issue, index: number) => {
    const detailUrl =
      role === RoleType.USER
        ? `/issue-detail/${issueInfo.id}`
        : `/voting-detail/${issueInfo.id}`;
    return (
      <Col key={`issue_key_${index}`} span={"sm"}>
        <OneIssueWidget issueInfo={issueInfo} detailUrl={detailUrl} />
      </Col>
    );
  });
  return (
    <>
      <DashboardSubTitle title={title} />
      {issuedVoteList && issuedVoteList.length > 0 && (
        <div className="popular-issues-container">
          <Row gutter={[20, 10]}>{issuesUIList}</Row>
        </div>
      )}
    </>
  );
}

export default PopularIssuesContainer;
