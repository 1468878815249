import React, { useEffect } from "react";
import "./community_page.css";
import PopularIssuesContainer from "../dashboard_page/popular_issues_container/popular_issues_container";
import { connect } from "react-redux";
import { getIssuesData } from "../../services/redux/reducers/issue_data_service";
import Issue from "../../models/Issue";
import { VOTE_TYPE } from "../../constants/enums";
const CommunityPage = (props: any) => {
  const { dispatch, IssueData, CommonData } = props;
  const { userInfo } = CommonData;
  useEffect(() => {
    window.scrollTo(0, 0);
    getIssuesData(dispatch);
  }, [dispatch]);

  const { issue_data_list } = IssueData || {};
  const issues = ((issue_data_list || []) as Issue[]).filter(
    (issue) =>
      issue.votingType === VOTE_TYPE.issues &&
      issue.locality === userInfo?.locality?.locality
  );
  return (
    <React.Fragment>
      <div className="community-page-container">
        <PopularIssuesContainer
          role={userInfo.role}
          title="Community Issues"
          issuedVoteList={issues}
        />
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch: any, _: any) => ({
  dispatch: dispatch,
});
const mapStateToProps = (state: any, _: any) => ({
  IssueData: state.IssueData,
  CommonData: state.CommonData,
});
export default connect(mapStateToProps, mapDispatchToProps)(CommunityPage);
