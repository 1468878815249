import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Input, message, Modal, Spin, Table } from "antd";
import {
  LoadingOutlined,
  EditFilled,
  DeleteFilled,
  ExclamationCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import DateWithCalendar from "../../components/dashboard_page_components/date_with_calendar/date_with_calendar";
import { connect } from "react-redux";
import {
  getProposalsData,
  removeProposalData,
} from "../../services/redux/reducers/proposal_data_service";
import Proposal from "../../models/Proposal";
import "./manage_proposals_page.css";
import Issue from "../../models/Issue";
const { confirm } = Modal;
const antIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

const ManageProposalsPage = (props: any) => {
  const history = useHistory();
  const { ProposalData, dispatch } = props;
  const { proposal_data_list } = ProposalData;
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getProposalsData(dispatch, (proposals: Proposal[] | undefined) => {
      setIsLoading(false);
      if (proposals) {
        setProposals(
          (proposals as Issue[]).filter(
            (proposal) => !Boolean(proposal.status)
          ) as Proposal[]
        );
      }
    });
  }, [dispatch]);
  const proposalList = proposal_data_list as Proposal[];
  const [proposals, setProposals] = useState(proposalList as Proposal[]);
  const handleEditProposal = (id: string) => {
    history.push(`/proposal-detail/${id}`);
  };

  const handleRemoveProposal = (id: string) => {
    const proposal = proposals.find((proposal: Proposal) => proposal.id === id);
    confirm({
      title: `Are you sure you want to remove ${proposal?.title ?? ""}?`,
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        removeProposalData(id, (err) => {
          if (err != null) {
            message.error(err.message);
          } else {
            message.success(`Successfully removed ${proposal?.title ?? ""}`);
          }
        });
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      render: (time: number) => <DateWithCalendar date={time} size={"14px"} />,
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (id: string) => (
        <div style={{ display: "flex", gap: "18px" }}>
          <Button
            type="text"
            icon={<EditFilled />}
            onClick={(e: any) => {
              e.preventDefault();
              handleEditProposal(id);
            }}
          />
          <Button
            type="text"
            icon={<DeleteFilled />}
            onClick={(e: any) => {
              e.preventDefault();
              handleRemoveProposal(id);
            }}
          />
        </div>
      ),
    },
  ];
  return (
    <div>
      <h3>Manage Proposals</h3>
      <div className="manage-proposals-input-create">
        <Input
          className="manage-proposals-input"
          prefix={<SearchOutlined />}
          value={searchText}
          onChange={(e: ChangeEvent<{ value: string }>) => {
            const value = e.target.value;
            setSearchText(value);
            setProposals(
              (proposalList as Proposal[]).filter(
                (proposal: Proposal) =>
                  proposal.title.includes(value) ||
                  proposal.description.includes(value) ||
                  proposal.tags.some((tag) => tag.includes(value)) ||
                  value === ""
              )
            );
          }}
        />
        <Button
          size="large"
          type="primary"
          className="manage-proposals-input"
          onClick={() => history.push("/post-suggestion")}
        >
          Create Proposal
        </Button>
      </div>

      {isLoading ? (
        <Spin
          indicator={antIcon}
          style={{ display: "flex", justifyContent: "center" }}
        />
      ) : (
        <Table
          dataSource={(proposals ?? []).map((proposal: any) => {
            const { description, ...restOfProposal } = proposal;
            return { ...restOfProposal };
          })}
          columns={columns}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: any, _: any) => ({
  dispatch: dispatch,
});
const mapStateToProps = (state: any, _: any) => ({
  ProposalData: state.ProposalData,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageProposalsPage);
