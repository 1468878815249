import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Popover, Button, Modal } from "antd";
import { MenuOutlined, RightOutlined, CloseOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { Badge } from "antd";
import Notification from "../../models/Notification";
import { Link, useHistory } from "react-router-dom";
import DateWithCalendar from "../dashboard_page_components/date_with_calendar/date_with_calendar";
import { RoleType } from "../../constants/enums";
import { getNotificationsData } from "../../services/redux/reducers/notification_data_service";
import { firebaseService } from "../../services/firebaseService";
import { updateUserData } from "../../services/redux/reducers/user_data_service";
import { useMediaQuery } from "react-responsive";
const { Header } = Layout;

const { confirm } = Modal;

const MyHeader = (props: any) => {
  const auth = firebaseService.auth();
  const history = useHistory();
  const { CommonData, dispatch } = props;
  const { userInfo } = CommonData;
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const NotificationContent = () => {
    return (
      <>
        {notifications
          .sort((a, b) => a.created + b.created)
          .map((notification: Notification, index: number) => {
            return (
              <React.Fragment key={`notification_${index}`}>
                <div
                  onClick={() => {
                    const ids = userInfo?.notificationIds;
                    if ((ids && !ids?.includes(notification.id)) || !ids) {
                      userInfo.notificationIds = ids
                        ? [...ids, notification.id]
                        : [notification.id];
                      updateUserData(userInfo, (err) => {
                        if (err) {
                          console.log(err);
                        }
                      });
                    }
                    history.push(notification.link);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <b>{notification.title}</b>
                  <p>{notification.description}</p>
                  <DateWithCalendar date={notification.created} />
                </div>
                <hr />
              </React.Fragment>
            );
          })}
      </>
    );
  };

  useEffect(() => {
    getNotificationsData(dispatch, (notifications: Notification[]) => {
      if (notifications) {
        setNotifications(
          notifications.filter(
            (notification) =>
              !userInfo?.notificationIds?.includes(notification.id) ||
              !Boolean(userInfo?.notificationIds)
          )
        );
      }
    });
  }, [dispatch, userInfo.notificationIds]);

  return isTabletOrMobile ? <MobileHeader /> : <DesktopHeader />;

  function DesktopHeader() {
    return (
      <Header className="header-style">
        <Row align={"middle"}>
          <Col span={12}></Col>
          <Col span={12}>
            <div className="header-user-info-container">
              <div className="header-user-avatar-container">
                <Popover
                  placement="bottomRight"
                  title={userInfo?.name ?? ""}
                  trigger="click"
                  content={
                    <Button
                      type="link"
                      onClick={() => {
                        auth.signOut().then(() => {
                          if (!auth.currentUser) {
                            history.push("/login");
                          } else {
                            history.push("/dashboard");
                          }
                        });
                      }}
                    >
                      Logout
                    </Button>
                  }
                >
                  <img
                    style={{ borderRadius: "50%" }}
                    src={userInfo.profileUrl ?? "assets/images/male.png"}
                    width={40}
                    height={40}
                    alt="profile"
                  />
                </Popover>
              </div>
              {userInfo.role === RoleType.USER && (
                <div className="header-alert-count-container">
                  {notifications.length > 0 ? (
                    <Popover
                      placement="bottomRight"
                      title="Notifications"
                      content={NotificationContent}
                      trigger="click"
                    >
                      <Badge count={notifications.length}>
                        <img
                          src={"assets/images/bell.png"}
                          height={30}
                          alt="bell with notifications"
                        />
                      </Badge>
                    </Popover>
                  ) : (
                    <img
                      src={"assets/images/bell.png"}
                      height={30}
                      alt="bell with no notifications"
                    />
                  )}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Header>
    );
  }

  function MobileHeader() {
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [isNotificationsVisible, setIsNotificationsVisible] = useState(false);

    const hideMenu = () => {
      setIsMenuVisible(false);
    };
    const hideNotifications = () => {
      setIsNotificationsVisible(false);
    };

    return (
      <div className="mobile-header">
        {userInfo.role === RoleType.USER && (
          <div className="header-alert-count-container">
            {notifications.length > 0 ? (
              <Popover
                placement="bottomRight"
                title={
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    Notifications{" "}
                    <Button
                      type="link"
                      style={{ color: "black" }}
                      onClick={hideNotifications}
                    >
                      <CloseOutlined />
                    </Button>
                  </div>
                }
                visible={isNotificationsVisible}
                onVisibleChange={setIsNotificationsVisible}
                content={NotificationContent}
                trigger="click"
              >
                <Badge count={notifications.length}>
                  <img
                    src={"assets/images/bell.png"}
                    height={24}
                    width={24}
                    alt="bell with notifications"
                  />
                </Badge>
              </Popover>
            ) : (
              <img
                src={"assets/images/bell.png"}
                height={24}
                width={24}
                alt="bell with no notifications"
              />
            )}
          </div>
        )}
        <div className="header-user-avatar-container">
          <Popover
            placement="bottomRight"
            visible={isMenuVisible}
            onVisibleChange={setIsMenuVisible}
            title={
              <div>
                <img
                  style={{ borderRadius: "50%" }}
                  src={userInfo.profileUrl ?? "assets/images/male.png"}
                  width={35}
                  height={35}
                  alt="profile"
                />{" "}
                {userInfo?.name ?? ""}
                <Button
                  type="link"
                  style={{ color: "black" }}
                  onClick={hideMenu}
                >
                  <CloseOutlined />
                </Button>
              </div>
            }
            trigger="click"
            content={
              <div>
                <Link to="/verify" style={{ color: "black" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>Verify Casted Votes</span> <RightOutlined />
                  </div>
                </Link>
                <hr />
                <Link to="/profile" style={{ color: "black" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>Profile</span> <RightOutlined />
                  </div>
                </Link>
                <hr />
                <Button
                  type="link"
                  style={{ paddingLeft: 0, color: "black" }}
                  onClick={() => {
                    hideMenu();
                    confirm({
                      title: "Are you sure you want to logout?",
                      okText: "Logout",
                      icon: undefined,
                      onOk() {
                        auth.signOut().then(() => {
                          if (!auth.currentUser) {
                            history.push("/login");
                          } else {
                            history.push("/dashboard");
                          }
                        });
                      },
                      onCancel() {},
                    });
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "190%",
                      alignItems: "center",
                    }}
                  >
                    <span>Logout</span> <RightOutlined />
                  </div>
                </Button>
              </div>
            }
          >
            <MenuOutlined size={30} />
          </Popover>
        </div>
      </div>
    );
  }
};
const mapStateToProps = (state: any, _: any) => ({
  CommonData: state.CommonData,
});
const mapDispatchToProps = (dispatch: any, _: any) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(MyHeader);
