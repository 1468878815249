import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  PaperClipOutlined,
  LoadingOutlined,
  SendOutlined,
} from "@ant-design/icons";
import {
  Button,
  message,
  Row,
  Col,
  Modal,
  Input,
  Spin,
  Badge,
} from "antd";
import { connect } from "react-redux";
import "./town_hall_page.css";
import { getProposalsDataByUid } from "../../services/redux/reducers/proposal_data_service";
import Proposal from "../../models/Proposal";
import DateWithCalendar from "../../components/dashboard_page_components/date_with_calendar/date_with_calendar";
import { AuthContext } from "../../services/Auth";
import {
  addFirebaseData,
  setFirebaseData,
  storage,
} from "../../services/firebaseService";
import Feedback from "../../models/Feedback";
import { Attachment } from "../../models/Attachment";
import { v4 as uuidv4 } from "uuid";
import { getFeedbacksDataByUid } from "../../services/redux/reducers/feedback_data_service";
import { useMediaQuery } from "react-responsive";

const TownHallPage = (props: any) => {
  const currentUser = useContext(AuthContext);
  const history = useHistory();
  const { ProposalData, dispatch, CommonData } = props;
  const [isNewUser, setNewUser] = useState(true);
  const [locality, setLocality] = useState("");
  const [showFeedback, setShowFeedback] = useState(false);
  const { userInfo } = CommonData;
  const { proposal_data_list } = ProposalData;
  const [proposals, setProposals] = useState(proposal_data_list);
  const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
  const [feedback, setFeedback] = useState<Feedback | undefined>(undefined);
  const [fileMessageAttachments, setFileMessageAttachments] = useState<
    Attachment[]
  >([]);

  const hiddenMessageAttachmentInput: any = React.createRef();
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);
  const [proposalId, setProposalId] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
    getProposalsDataByUid(dispatch, currentUser?.uid, (proposals) => {
      if (proposals) {
        setProposals(proposals);
        setNewUser(proposals.length === 0);
      }
    });
    setLocality(userInfo?.locality ?? "");
    // eslint-disable-next-line
  }, [dispatch, userInfo]);

  const handleSubmit = () => {
    if (currentUser?.uid) {
      setFirebaseData(`users/${userInfo?.id}/locality`, locality, (err) => {
        if (err != null) {
          console.log(err);
          message.error(err.message);
        } else {
          if (locality) {
            message.success("Locality captured, thank you!");
            setLocality(locality);
          } else {
            message.error("Please enter a locality");
          }
        }
      });
    }
  };
  const handleAddMessageAttachment = (_: any) => {
    hiddenMessageAttachmentInput.current.click();
  };

  const handleMessageAttachmentChange = (e: any) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      setFileMessageAttachments([
        ...fileMessageAttachments,
        {
          name: file.name,
          url: URL.createObjectURL(file),
          raw: file,
        },
      ]);
    }
  };
  const handleLocalityChange = (e: any) => {
      setLocality(e.target.value);
  
  };
  const handlePostProposal = () => {
    history.push("/post-suggestion");
  };

  const handleFeedBackProposal = (id: string) => {
    setShowFeedback(true);
    setProposalId(id);
    if (id) {
      getFeedbacksDataByUid(
        dispatch,
        currentUser?.uid,
        (feedbacks: Feedback[]) => {
          if (feedbacks) {
            setFeedbacks(feedbacks.filter((f) => f.proposalId === id));
          }
        }
      );
    }
  };
  const postFeedback = (
    feedback: Feedback | undefined,
    attachmentUrls: string[],
    userInfo: any,
    id: string
  ) => {
    const feedback1 = {
      created: feedback?.created ?? Date.now(),
      attachments: attachmentUrls,
      uid: currentUser?.uid ?? "",
      proposalId: id,
      message: feedback?.message ?? "",
    };
    addFirebaseData("feedbacks", feedback1, (err: Error | null) => {
      console.log(err);
      if (err) {
        message.error(err?.message);
      } else {
        setIsSendButtonDisabled(false);
      }
    });
  };

  const handleSendMessage = (e: any) => {
    // eslint-disable-next-line
    if ((feedback && feedback.message == "") || feedback === undefined) {
      message.warning("Enter in text in feedback block");
      return;
    }
    setIsSendButtonDisabled(true);
    var counter = 0;
    var attachmentUrls: string[] = [];
    const uploadAttachment = (attachment: Attachment) => {
      if (attachment && attachment.raw) {
        const attachmentNameParts = attachment.name.split(".");
        const attachmentName = attachmentNameParts[0];
        const attachmentExtension = attachmentNameParts[1];
        const attachmentFileName = `${attachmentName}_${uuidv4()}.${attachmentExtension}`;
        const uploadAttachmentTask = storage
          .ref(`images/${attachmentFileName}`)
          .put(attachment.raw);

        uploadAttachmentTask.on(
          "state_changed",
          () => {},
          (error) => {
            message.error(error);
            setIsSendButtonDisabled(false);
          },
          () => {
            ++counter;
            uploadAttachmentTask.snapshot.ref
              .getDownloadURL()
              .then((downloadURL) => {
                attachmentUrls.push(downloadURL);
                if (counter < fileMessageAttachments.length) {
                  uploadAttachment(fileMessageAttachments[counter]);
                } else {
                  postFeedback(feedback, attachmentUrls, userInfo, proposalId);
                }
              });
          }
        );
      } else {
        postFeedback(feedback, [], userInfo, proposalId);
      }
    };

    uploadAttachment(fileMessageAttachments[counter]);
    setFeedback(undefined);
    setFileMessageAttachments([]);
  };

  const handFeedback = (feedback: Feedback | undefined) => {
    if (feedback) {
      setFeedback(feedback);
    }
  };
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  return (
    <div>
      <h3>My Town Hall</h3>
      <div className="town-hall-main">
        <div>
          <div className="town-hall-submit-section">
            <Input
              className="town-hall-field"
              placeholder=""
              onChange={handleLocalityChange}
              value={locality}
            />
            <Button
              type="primary"
              className="town-hall-submit-btn"
              size="large"
              onClick={handleSubmit}
            >
              {isTabletOrMobile ? "Submit" : "Submit and Confirm"}
            </Button>
          </div>
          <p className="grey-text">
            This is your Digital Town Hall, You can add your locality but you
            will not be bound to vote on or post issue that do not concern your
            locality
          </p>
        </div>
        {isNewUser ? (
          <div className="town-hall-margin-bottom-32">
            <h6 className="town-hall-margin-bottom-32">
              Post your Suggestions
            </h6>
            <div className="town-hall-suggestion-box">
              <h3 className="grey-text">Be the Voice of your Locality</h3>
              <p className="grey-text center-text">
                You can Post Suggestions in your town hall which will be visible
                to your council and people on the system. They will vote on your
                issue to make it a mandate and take that mandate to the congress
                for lesgislation.
              </p>
              <Button
                className="ant-btn-inverted"
                size="large"
                onClick={handlePostProposal}
              >
                Post a suggestion now
              </Button>
            </div>
          </div>
        ) : (
          <div className="town-hall-margin-bottom-32">
            <h4 className="town-hall-margin-bottom-32">Your Suggestions</h4>
            <Row gutter={[48, 32]}>
              <Col span="sm">
                <Link to="/post-suggestion" className="town-hall-link">
                  <div className="center-text">
                    <img src="/assets/images/plus.png" alt="plus" />
                    <div className="town-hall-br"></div>
                    <b>Post a new Suggestion</b>
                  </div>
                </Link>
              </Col>

              {proposals.map((proposal: Proposal, index: number) => (
                <Col key={`suggestion_${index}`} span="sm">
                  <div className="town-hall-suggestion">
                    <img
                      className="town-hall-suggestion-image"
                      src={proposal.coverImage}
                      alt={proposal.title}
                    />
                    <div className="town-hall-suggestion-detail">
                      <b>{proposal.title}</b>
                      <p>
                        Posted on{" "}
                        <DateWithCalendar date={proposal.created} size={14} />
                      </p>
                    </div>
                  </div>
                  <Button
                    className="ant-btn-inverted town-hall-suggestion-button"
                    size="large"
                    onClick={() => {
                      handleFeedBackProposal(proposal.id);
                    }}
                  >
                    See Feedback
                  </Button>
                </Col>
              ))}
            </Row>
          </div>
        )}
      </div>
      <Modal
        title="Feedback"
        visible={showFeedback}
        footer={null}
        onCancel={() => {
          setShowFeedback(false);
        }}
      >
        <div className="proposal-detail-block">
          <div className="proposal-detail-profile">
            <img
              className="proposal-detail-profile-pic"
              alt="profile pic"
              src={userInfo?.profileUrl ?? "/assets/images/male.png"}
            />
            <div>
              <b>{userInfo.name}</b>
              <p className="grey-text">{userInfo.role}</p>
            </div>
          </div>
          <hr />
          <div style={{ height: "500px", overflowY: "scroll" }}>
            {feedbacks &&
              feedbacks.map((feedback: Feedback) => {
                return (
                  <div className="proposal-detail-date">
                    <div
                      className="proposal-detail-message-block"
                      style={{
                        justifyContent:
                          // eslint-disable-next-line
                          feedback.uid == userInfo.uid ? "end" : "start",
                      }}
                    >
                      <Badge dot={true} color="green">
                        <img
                          className="proposal-detail-message-profile-pic"
                          alt="profile message pic"
                          src={
                            userInfo?.profileUrl ?? "/assets/images/male.png"
                          }
                        />
                      </Badge>
                      <div
                        className="proposal-detail-message-text"
                        style={{
                          background:
                            // eslint-disable-next-line
                            feedback.uid == userInfo.uid ? "#fff" : "#EBEBEB",
                        }}
                      >
                        <p>{feedback.message}</p>
                        {feedback.attachments && <PaperClipOutlined />}
                        {feedback.attachments &&
                          feedback.attachments.map((attachment, index) => {
                            return (
                              <a
                                key={`message_attachment_link_${index}`}
                                href={attachment}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span className="green-text">
                                  {"Attachment "}
                                  {index}
                                  {feedback.attachments.length - 1 === index
                                    ? ""
                                    : ", "}
                                </span>
                              </a>
                            );
                          })}
                      </div>{" "}
                    </div>
                    <DateWithCalendar
                      date={feedback.created}
                      size={14}
                      // eslint-disable-next-line
                      textAlign={feedback.uid == userInfo.uid ? "end" : "start"}
                    />
                  </div>
                );
              })}
          </div>
          <div style={{ width: "100%" }}>
            <input
              type="file"
              ref={hiddenMessageAttachmentInput}
              onChange={handleMessageAttachmentChange}
              style={{ display: "none" }}
            />
            <Input
              placeholder="Type your text here"
              size="large"
              value={feedback?.message}
              className="proposal-detail-send-input"
              style={{
                backgroundColor: "#000",
                color: "#fff",
                borderRadius: "5px",
              }}
              onChange={(e: ChangeEvent<{ value: string }>) => {
                handFeedback({
                  id: feedback?.id ?? "",
                  created: feedback?.created ?? Date.now(),
                  attachments: fileMessageAttachments.map((f) => f.url),
                  uid: userInfo?.uid ?? "",
                  proposalId: proposalId,
                  message: e.target.value,
                });
              }}
              prefix={
                isSendButtonDisabled ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        className="proposal-detail-white-text"
                        spin
                      />
                    }
                  />
                ) : (
                  <PaperClipOutlined
                    className="proposal-detail-white-text"
                    onClick={handleAddMessageAttachment}
                  />
                )
              }
              suffix={
                isSendButtonDisabled ? (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ color: "white" }} spin />
                    }
                  />
                ) : (
                  <SendOutlined
                    className="proposal-detail-white-text"
                    onClick={handleSendMessage}
                  />
                )
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage(e);
                }
              }}
            />
          </div>
          {fileMessageAttachments && fileMessageAttachments.length > 0 && (
            <PaperClipOutlined />
          )}
          {fileMessageAttachments &&
            fileMessageAttachments.map((attachment: Attachment, index) => {
              return (
                <a
                  key={`message_link_${index}`}
                  href={attachment.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="green-text">
                    {attachment.name}
                    {fileMessageAttachments.length - 1 === index ? "" : ", "}
                  </span>
                </a>
              );
            })}
        </div>
      </Modal>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any, _: any) => ({
  dispatch: dispatch,
});
const mapStateToProps = (state: any, _: any) => ({
  ProposalData: state.ProposalData,
  CommonData: state.CommonData,
  LocalityData: state.LocalityData,
});
export default connect(mapStateToProps, mapDispatchToProps)(TownHallPage);
