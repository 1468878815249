import Proposal from "../../../models/Proposal";
import {
  getFirebaseData,
  getFirebaseDataByMatcher,
  getFirebaseSingleData,
  removeFirebaseData,
} from "../../firebaseService";
import * as actionTypes from "../actionType";

const UpdateProposalData = (data: Proposal[], dispatch: any) => {
  dispatch({
    type: actionTypes.UpdateProposalData,
    data: data,
  });
};

export const getProposalsData = (
  dispatch: any,
  callback: (proposals: Proposal[] | undefined) => void = () => {}
) => {
  getFirebaseData("proposals", (proposalData: Proposal[]) => {
    const proposals = proposalData;
    if (proposals) {
      UpdateProposalData(proposals, dispatch);
      callback(proposals);
    } else {
      callback(undefined);
    }
  });
};

export const getProposalsDataByUid = (
  dispatch: any,
  uid?: string,
  callback: (proposals: Proposal[] | undefined) => void = () => {}
) => {
  getFirebaseDataByMatcher("proposals", "uid", uid, (proposalData: Proposal[]) => {
    const proposals = proposalData;
    if (proposals) {
      UpdateProposalData(proposals, dispatch);
      callback(proposals);
    } else {
      callback(undefined);
    }
  });
};

export const removeProposalData = (
  id: string,
  callback: (err: Error | null) => void
) => {
  removeFirebaseData(`/proposals/${id}`, callback);
};

export const getProposalData = (
  id: string,
  callback: (error?: any) => void = () => {}
) => {
  getFirebaseSingleData("proposals", "id", id, (result: any) => {
    const proposal = result as Proposal;
    if (proposal) {
      callback(proposal);
    } else {
      callback(result);
    }
  });
};

const reducer = (state: any = { proposal_data_list: [] }, action: any) => {
  switch (action.type) {
    case actionTypes.UpdateProposalData:
      return {
        ...state,
        proposal_data_list: action.data,
      };
    default:
      return state;
  }
};
export default reducer;
