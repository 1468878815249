import { createStore, combineReducers } from "redux";
import UIreducer from "./reducers/ui_reducer";
import CommonDataService from "./reducers/commondata_service";
import UserDataService from "./reducers/user_data_service";
import IssueDataService from "./reducers/issue_data_service";
import ProposalDataService from "./reducers/proposal_data_service";
import FeedbackDataService from "./reducers/feedback_data_service";
import LocalityDataService from "./reducers/locality_data_service";
import NotificationDataService from "./reducers/notification_data_service";
import VerifiedVoteDataService from "./reducers/verified_votes_data_service";
const rootReducer = combineReducers({
  UIreducer: UIreducer,
  CommonData: CommonDataService,
  UserData: UserDataService,
  IssueData: IssueDataService,
  ProposalData: ProposalDataService,
  FeedbackData: FeedbackDataService,
  LocalityData: LocalityDataService,
  NotificationData: NotificationDataService,
  VerifiedVoteData: VerifiedVoteDataService,
});

export default createStore(rootReducer);
