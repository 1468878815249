import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { firebaseService } from "../../../services/firebaseService";
import { withRouter } from "react-router-dom";
import { routeInfo } from "../../../services/constantsService";
import { SetMenuIndex } from "../../../services/redux/reducers/commondata_service";
import { TabBar } from "antd-mobile";
import "./BottomBar.css";

import User from "../../../models/User";
import { RoleType } from "../../../constants/enums";
import DashboardIcon from "./icons/DashboardIcon";
import BoxIcon from "./icons/BoxIcon";
import AwardIcon from "./icons/AwardIcon";
import HomeIcon from "./icons/HomeIcon";
import VoteIcon from "./icons/VoteIcon";

const BottomBar = (props: any) => {
  const auth = firebaseService.auth();
  const { CommonData } = props;
  const userInfo: User = CommonData.userInfo;
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname;
  const index = routeInfo.findIndex((p) => p.route === path);
  useEffect(() => {
    if (index >= 0) {
      SetMenuIndex(index, props.dispatch);
    }
  }, [index, props.dispatch]);

  const onClickMenu = (key: any) => {
    const keyIndex = parseInt(key);
    if (routeInfo[keyIndex].route === "/logout") {
      auth.signOut().then(() => {
        if (!auth.currentUser) {
          history.push("/login");
        } else {
          history.push("/dashboard");
        }
      });
    } else {
      history.push(routeInfo[keyIndex].route);
    }
  };
  if (userInfo.role !== RoleType.USER) {
    return <React.Fragment></React.Fragment>;
  }
  return (
    <div className="bottom">
      <TabBar
        defaultActiveKey={CommonData.currentMenuIndex.toString()}
        onChange={(value) => onClickMenu(value)}
      >
        <TabBar.Item key="0" title="Dashboard" icon={<DashboardIcon />} />
        <TabBar.Item key="1" title="Mandates" icon={<BoxIcon />} />
        <TabBar.Item key="2" title="Issues" icon={<AwardIcon />} />
        <TabBar.Item key="5" title="Your Town Hall" icon={<HomeIcon />} />
        <TabBar.Item key="3" title="Votes" icon={<VoteIcon />} />
      </TabBar>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any, _: any) => ({
  dispatch: dispatch,
});
const mapStateToProps = (state: any, _: any) => ({
  CommonData: state.CommonData,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BottomBar));
