import React, { useEffect } from 'react'
import { Layout } from 'antd'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom';
import './mysidebar.css'
import MyMenu from './mymenu';
import { firebaseService } from '../../../services/firebaseService';
import { withRouter } from 'react-router-dom';
import { routeInfo } from '../../../services/constantsService';
import { SetMenuIndex } from '../../../services/redux/reducers/commondata_service';

const { Sider } = Layout;
function MySidebar(props: any) {
    const auth = firebaseService.auth();
    const { CommonData } = props;
   
    const history = useHistory();
    const location = useLocation();
    const path = location.pathname;
    const index = routeInfo.findIndex(p => p.route === path);
    useEffect(() => {
        if (index >= 0) {
            SetMenuIndex(index, props.dispatch);
        }
    }, [index, props.dispatch]);


    const onClickMenu = ({ key }: any) => {
        if (routeInfo[key].route === "/logout") {
            auth.signOut().then(() => {
                if(!auth.currentUser){
                    history.push("/login");
                }else{
                    history.push("/dashboard");
                }
            });
        } else {
            history.push(routeInfo[key].route);
        }
    }
    return (
        <div>
            <Sider
                style={{
                    overflow: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                }}
                className='my-sidebar-style'
            >
                <MyMenu
                    onClickMenu={onClickMenu}
                    activeIndex={CommonData.currentMenuIndex}
                />
            </Sider>
        </div>
    )
}
const mapStateToProps = (state: any, _: any) => ({
    CommonData: state.CommonData,
})
const mapDispatchToProps = (dispatch: any, _: any) => ({
    dispatch: dispatch
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(MySidebar));