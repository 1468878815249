import React, { useEffect } from "react";
import "./dashboard_page.css";
import PopularIssuesContainer from "./popular_issues_container/popular_issues_container";
import { connect } from "react-redux";
import { getIssuesData } from "../../services/redux/reducers/issue_data_service";
import Issue from "../../models/Issue";
import { VOTE_TYPE } from "../../constants/enums";
const DashboardPage = (props: any) => {
  const { dispatch, IssueData, CommonData } = props;
  const { userInfo } = CommonData;
  useEffect(() => {
    window.scrollTo(0, 0);
    getIssuesData(dispatch);
  }, [dispatch]);

  const { issue_data_list } = IssueData || {};
  const issues = ((issue_data_list || []) as Issue[]).filter(
    (issue) => issue.votingType === VOTE_TYPE.issues
  );
  return (
    <React.Fragment>
      <div className="dashboard-page-container">
        <PopularIssuesContainer
          role={userInfo.role}
          title="Popular Issues"
          issuedVoteList={issues
            .sort(
              (a: Issue, b: Issue) =>
                (b.yeses ? b.yeses.length : 0) +
                (b.noes ? b.noes.length : 0) -
                ((a.yeses ? a.yeses.length : 0) + (a.noes ? a.noes.length : 0))
            )
            .slice(0, 4)}
        />
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch: any, _: any) => ({
  dispatch: dispatch,
});
const mapStateToProps = (state: any, _: any) => ({
  IssueData: state.IssueData,
  CommonData: state.CommonData,
});
export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
