import React from "react";
import { Menu } from "antd";
import { connect } from "react-redux";
import User from "../../../models/User";
import { RoleType } from "../../../constants/enums";

const MyMenu = (props: any) => {
  const { activeIndex, onClickMenu, CommonData } = props;
  const userInfo: User = CommonData.userInfo;
  return (
    <div>
      <div className="menu-logo-style">
        <div
          style={{
            margin: 18,
            width: "200px",
            background: "white",
            borderRadius: 10,
            color: "black",
            textAlign: "center",
          }}
        >
          <img
            style={{ padding: "5px" }}
            src="/logo.svg"
            width="200px"
            alt="we the people logo"
          />
        </div>
      </div>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[`${activeIndex}`]}
        className="my-sidebar-menu-style"
        onClick={onClickMenu}
      >
        <Menu.Item key={0} className="my-sidebar-menu-item">
          Dashboard
        </Menu.Item>

        {userInfo.role === RoleType.USER && (
          <React.Fragment>
            <Menu.Item key={1} className="my-sidebar-menu-item">
              Mandates
            </Menu.Item>
            <Menu.Item key={2} className="my-sidebar-menu-item">
              Issues
            </Menu.Item>
            <Menu.Item key={3} className="my-sidebar-menu-item">
              Voting
            </Menu.Item>
            <Menu.Item key={4} className="my-sidebar-menu-item">
              Verify your votes
            </Menu.Item>
            <Menu.Item key={5} className="my-sidebar-menu-item">
              Your Town Hall
            </Menu.Item>
            <Menu.Item key={7} className="my-sidebar-menu-item">
              Community
            </Menu.Item>
          </React.Fragment>
        )}

        {userInfo.role === RoleType.ADMIN && (
          <React.Fragment>
            <Menu.Item key={10} className="my-sidebar-menu-item">
              Manage Users
            </Menu.Item>
            <Menu.Item key={12} className="my-sidebar-menu-item">
              Manage Voting
            </Menu.Item>
            <Menu.Item key={16} className="my-sidebar-menu-item">
              Verified Votes
            </Menu.Item>
            <Menu.Item key={11} className="my-sidebar-menu-item">
              Manage Proposals
            </Menu.Item>
            <Menu.Item key={15} className="my-sidebar-menu-item">
              Manage Notifications
            </Menu.Item>
            <Menu.Item key={13} className="my-sidebar-menu-item">
              App Usage Stats
            </Menu.Item>
          </React.Fragment>
        )}

        <div className="my-menu-item-spacer" />
        <Menu.Item key={6} className="my-sidebar-menu-item">
          Settings
        </Menu.Item>
        <Menu.Item key={8} className="my-sidebar-menu-item">
          Log out
        </Menu.Item>
      </Menu>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any, _: any) => ({
  dispatch: dispatch,
});
const mapStateToProps = (state: any, _: any) => ({
  CommonData: state.CommonData,
});
export default connect(mapStateToProps, mapDispatchToProps)(MyMenu);
