import React, { ChangeEvent, useState } from "react";
import BackLink from "../../../components/common/back_link/back_link";
import DetailHeading from "../../../components/common/detail_heading/detail_heading";
import { Button, Form, Input, message } from "antd";
import "./create_notification_page.css";
import { useHistory } from "react-router-dom";
import Notification from "../../../models/Notification";
import { addFirebaseData } from "../../../services/firebaseService";

const layout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 2, span: 12 },
};

const CreateNotificationPage = (_: any) => {
  const history = useHistory();
  const [notification, setNotification] = useState<Notification>({
    id: "",
    title: "",
    description: "",
    link: "",
    created: Date.now(),
  });

  const handleNotificationChange = (notification: Notification) => {
    setNotification(notification);
  };
  const onFinish = (values: any) => {
    message.success("Success:", values);
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error("Failed:", errorInfo);
  };

  const handleCreate = (e: any) => {
    e.preventDefault();
    if (!notification.title) {
      message.error("Please enter a title");
      return;
    }
    if (!notification.description) {
      message.error("Please enter a description");
      return;
    }
    if (!notification.link) {
      message.error("Please enter a link");
      return;
    }
    addFirebaseData("notifications", notification, (err) => {
      if (err) {
        message.error(err.message);
      } else {
        history.push("/manage-notifications");
      }
    });
  };

  return (
    <div>
      <BackLink link="/manage-notifications" />
      <DetailHeading
        heading="Create a notification"
        style={{ marginBottom: "48px" }}
      />
      <Form
        {...layout}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name="title"
          label="Title"
          initialValue={notification.title}
          rules={[{ required: true, message: "Please enter a title" }]}
        >
          <Input
            value={notification.title}
            onChange={(e: ChangeEvent<{ value: string }>) => {
              handleNotificationChange({
                ...notification,
                title: e.target.value,
              });
            }}
          />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          initialValue={notification.description}
          rules={[{ required: true, message: "Please enter a description" }]}
        >
          <Input
            value={notification.description}
            onChange={(e: ChangeEvent<{ value: string }>) => {
              handleNotificationChange({
                ...notification,
                description: e.target.value,
              });
            }}
          />
        </Form.Item>
        <Form.Item
          name="link"
          label="Link"
          initialValue={notification.link}
          rules={[{ required: true, message: "Please enter a link" }]}
        >
          <Input
            value={notification.link}
            onChange={(e: ChangeEvent<{ value: string }>) => {
              handleNotificationChange({
                ...notification,
                link: e.target.value,
              });
            }}
          />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <div className="create-notification-buttons">
            <Button
              size="large"
              type="default"
              onClick={(e: any) => history.push("/manage-notifications")}
            >
              CANCEL
            </Button>
            <Button size="large" type="primary" onClick={handleCreate}>
              CREATE
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateNotificationPage;
