import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "antd/dist/antd.css";
import { Provider } from "react-redux";
import myStore from "./services/redux/index";
import { AuthProvider } from "./services/Auth";

ReactDOM.render(
  <Provider store={myStore}>
    <AuthProvider>
      <App />
    </AuthProvider>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
