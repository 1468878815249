import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { Row, Col, message, Input } from "antd";
import {
  addFirebaseDataByUid,
  firebaseService,
} from "../../services/firebaseService";
import { connect } from "react-redux";
import "./login_page.css";
import { getUserData } from "../../services/redux/reducers/commondata_service";
import * as firebase from "firebase/app";
import { RoleType } from "../../constants/enums";
import User from "../../models/User";
import { useMediaQuery } from "react-responsive";
import PhoneInput from "react-phone-number-input/input";

function LoginPage(props: any) {
  const auth = firebaseService.auth();
  const { dispatch } = props;
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [hasCode, setHasCode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  let location = useLocation();
  var screenHeight = window.innerHeight;
  let { from }: any = location.state || { from: { pathname: "/dashboard" } };
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  auth.useDeviceLanguage();

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setIsLoading(false);
      //@ts-ignore
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha"
      );
    }, 2000);

    return () => {
      clearTimeout(timeOutId);
    };
  }, []);

  const handleLogin = async () => {
    if (code && code.length > 6 && /[0-9]/i.test(code)) {
      message.error("Please enter a valid code");
      return;
    }
    //@ts-ignore
    const appVerifier = window.recaptchaVerifier;
    try {
      if (!hasCode) {
        await auth
          .signInWithPhoneNumber(phoneNumber, appVerifier)
          .then((confirmationResult) => {
            //@ts-ignore
            window.confirmationResult = confirmationResult;
            setHasCode(true);
          });
      } else {
        //@ts-ignore
        const result = await window.confirmationResult?.confirm(code);
        //@ts-ignore
        const user = result?.user;
        if (user?.uid) {
          const uid = user.uid;
          const name = user?.displayName ?? "";
          const email = user?.email ?? "";
          const phoneNumber = user?.phoneNumber ?? "";
          const profileUrl = user?.photoURL ?? "";
          getUserData(user.uid, dispatch, async (user) => {
            if (user) {
              history.push(from);
            } else {
              var uInfo: User = {
                name,
                email,
                phoneNumber,
                uid,
                profileUrl,
                role: RoleType.USER,
                created: Date.now(),
              };

              await addFirebaseDataByUid("users", uInfo, (err) => {
                if (err) {
                  message.error(err.message);
                } else {
                  history.push(from);
                  user && getUserData(user.uid, dispatch);
                }
              });
            }
          });
        }
      }
    } catch (error) {
      //@ts-ignore
      message.error(error.message);
    }
  };

  const onPhoneNumberChange = (value: any) => {
    setPhoneNumber(value);
  };

  function SplashView() {
    return (
      <div className="splash">
        <img className="splash-logo" src="/logo.svg" alt="we the people logo" />
        <p className="login-footer">...We believe in true democracy...</p>
      </div>
    );
  }

  if (auth.currentUser) {
    return isLoading && isTabletOrMobile ? (
      <SplashView />
    ) : (
      <Redirect to="/dashboard" />
    );
  }
  const textLogin = !hasCode ? "Login" : "Enter code";
  const textSub = !hasCode
    ? isTabletOrMobile
      ? "Enter the following details and you're good to go"
      : "Put in the details and you are good to go"
    : "Please enter the verification code that was sent to your mobile device.";
  return (
    <React.Fragment>
      {isTabletOrMobile ? (
        <React.Fragment>
          {isLoading ? (
            <SplashView />
          ) : (
            <React.Fragment>
              <div className="mobile-login">
                <h3>{textLogin}</h3> <p>{textSub}</p>
                {!hasCode ? (
                  <PhoneInput
                    className="mobile-login-detail"
                    country="US"
                    maxLength="16"
                    value={phoneNumber}
                    onChange={(value: any) => onPhoneNumberChange(value)}
                  />
                ) : (
                  <Input
                    className="mobile-login-detail"
                    maxLength={6}
                    value={code}
                    onChange={(e: any) => setCode(e.target.value)}
                  />
                )}
                {
                  //@ts-ignore
                  !window.confirmationResult && <div id="recaptcha"></div>
                }
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <button className="mobile-login-button" onClick={handleLogin}>
                    {textLogin}
                  </button>
                </div>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <div className="login-page">
          <Row align={"middle"} className="login-control-container">
            <Col span={12} style={{ height: screenHeight }}>
              <div className="logo-container">
                <div className="logo-container-text">
                  We Believe in True Democracy
                </div>
                <div className="logo-container-text-small">
                  Sign up today and protect it with us
                </div>
              </div>
            </Col>
            <Col span={12} style={{ textAlign: "center" }}>
              <div className="login-info-container-style">
                <div className="login-title-style">{textLogin}</div>
                <div className="login-description-style">{textSub}</div>
                {!hasCode ? (
                  <PhoneInput
                    className="mobile-login-detail"
                    country="US"
                    maxLength="16"
                    value={phoneNumber}
                    onChange={(value: any) => onPhoneNumberChange(value)}
                  />
                ) : (
                  <Input
                    className="mobile-login-detail"
                    maxLength={6}
                    value={code}
                    onChange={(e: any) => setCode(e.target.value)}
                  />
                )}
                {
                  //@ts-ignore
                  !window.confirmationResult && <div id="recaptcha"></div>
                }
                <button className="login-submit-style" onClick={handleLogin}>
                  {textLogin}
                </button>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch: any, _: any) => ({
  dispatch: dispatch,
});

export default connect(null, mapDispatchToProps)(LoginPage);
