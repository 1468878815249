import React from "react";
import moment from "moment";
import "./date_with_calendar.css";
const DateWithCalendar = (props: any) => {
  const { date, size, textAlign } = props;
  const dateObj = moment(date).format("MM/DD/YYYY HH:mm");
  return (
    <div
      className="date-with-calendar-style"
      style={{ fontSize: size, textAlign: textAlign }}
    >
      <span>
        <i className="far fa-calendar date-with-calendar-calendar" />
        {dateObj}
      </span>
    </div>
  );
};

export default DateWithCalendar;
