import React from "react";
import { Route, Switch } from "react-router-dom";
import MyLayout from "../components/Layout/layout";
import MandatesPage from "../pages/mandates_page/mandates_page";
import IssuesPage from "../pages/issues_page/issues_page";
import IssueDetailPage from "../pages/issue_detail_page/issue_detail_page";
import VoteDetailPage from "../pages/vote_detail_page/vote_detail_page";
import DashboardPage from "../pages/dashboard_page/dashboard_page";
import VotingPage from "../pages/voting_page/voting_page";
import TownHallPage from "../pages/town_hall_page/town_hall_page";
import PostSuggestionPage from "../pages/post_suggestion_page/post_suggestion_page";
import ProfilePageView from "../pages/settings_page/profile_page";
import VotingResultsPage from "../pages/voting_results_page/voting_results_page";
import VerifyVotesPage from "../pages/verify_votes_page/verify_votes_page";
import ManageUsersPage from "../pages/manage_users/manage_users_page";
import UserDetailPage from "../pages/manage_users/user_detail_page/user_detail_page";
import ManageProposalsPage from "../pages/manage_proposals/manage_proposals_page";
import ManageVotingPage from "../pages/manage_voting/manage_voting_page";
import AppUsageStatsPage from "../pages/app_usage_stats/app_usage_stats";
import ProposalDetailPage from "../pages/proposal_detail_page/proposal_detail_page";
import ManageNotificationsPage from "../pages/manage_notifications/manage_notifications_page";
import CreateNotificationPage from "../pages/manage_notifications/create_notification_page/create_notification_page";
import NotificationDetailPage from "../pages/manage_notifications/notification_detail_page/notification_detail_page";
import VotingDetailPage from "../pages/manage_voting/voting_detail_page/voting_detail_page";
import CommunityPage from "../pages/community_page/community_page";
import { connect } from "react-redux";
import { RoleType } from "../constants/enums";
const MyRoutes = (props: any) => {
  const { CommonData } = props;
  const { userInfo } = CommonData;

  return (
    <MyLayout>
      <Switch>
        <Route path="/post-suggestion" component={PostSuggestionPage} />
        <Route path="/dashboard" component={DashboardPage} />
        <Route path="/profile" component={ProfilePageView} />

        {userInfo.role === RoleType.USER && (
          <>
            <Route path="/community" component={CommunityPage} />
            <Route path="/mandates" component={MandatesPage} />
            <Route path="/issues" component={IssuesPage} />
            <Route path="/issue-detail/:id" component={IssueDetailPage} />
            <Route path="/voting" component={VotingPage} />
            <Route path="/vote-detail/:id" component={VoteDetailPage} />
            <Route path="/town-hall" component={TownHallPage} />
            <Route path="/results/:id" component={VotingResultsPage} />
            <Route
              path="/verify"
              render={() => <VerifyVotesPage isAdmin={false} />}
            />
          </>
        )}

        {userInfo.role === RoleType.ADMIN && (
          <>
            <Route path="/manage-users" component={ManageUsersPage} />
            <Route path="/manage-proposals" component={ManageProposalsPage} />
            <Route path="/manage-voting" component={ManageVotingPage} />
            <Route path="/app-usage-stats" component={AppUsageStatsPage} />
            <Route path="/user-detail/:id" component={UserDetailPage} />
            <Route path="/proposal-detail/:id" component={ProposalDetailPage} />
            <Route
              path="/manage-notifications"
              component={ManageNotificationsPage}
            />
            <Route
              path="/create-notification"
              component={CreateNotificationPage}
            />
            <Route
              path="/notification-detail/:id"
              component={NotificationDetailPage}
            />
            <Route path="/voting-detail/:id" component={VotingDetailPage} />
            <Route
              path="/verified-votes"
              render={() => <VerifyVotesPage isAdmin={true} />}
            />
          </>
        )}
      </Switch>
    </MyLayout>
  );
};

const mapDispatchToProps = (dispatch: any, _: any) => ({
  dispatch: dispatch,
});

const mapStateToProps = (state: any, _: any) => ({
  CommonData: state.CommonData,
});

export default connect(mapStateToProps, mapDispatchToProps)(MyRoutes);
