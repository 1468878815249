import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { VOTE_TYPE } from "../../constants/enums";
import { Col, Row, Input, Select } from "antd";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import "./mandates_page.css";
import OneTypeVotingWidget from "../voting_page/one_type_voting_widget/one_type_voting_widget";

import Issue from "../../models/Issue";
import { AuthContext } from "../../services/Auth";
import { getIssuesDataByUid } from "../../services/redux/reducers/issue_data_service";

const { Option } = Select;

const defaultFilters: string[] = ["title", "description"];

const MandatesPage = (props: any) => {
  const currentUser = useContext(AuthContext);
  const { IssueData, dispatch } = props;
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    getIssuesDataByUid(dispatch, currentUser?.uid);
  }, [currentUser?.uid, dispatch]);
  const [searchText, setSearchText] = useState("");
  const [filters, setFilters] = useState<string[]>([]);

  const handleSelectChange = (value: string[]) => {
    setFilters(value);
  };

  const handleTextFieldChange = (e: any) => {
    setSearchText(e.target.value);
  };
  const { issue_data_list } = IssueData;

  const onClickOneVote = (voteId: string, isClosed: boolean) => {
    if (isClosed) {
      history.push(`/results/${voteId}`);
    } else {
      history.push(`/vote-detail/${voteId}`);
    }
  };
  const votesUIList = (issue_data_list || [])
    .filter((x: Issue) => x.votingType === VOTE_TYPE.mandates)
    .filter((issue: Issue) => {
      if (filters.every((f) => f === "title")) {
        return issue.title.includes(searchText);
      } else if (filters.every((f) => f === "description")) {
        return issue.description.includes(searchText);
      } else if (
        filters.some((f) => f === "title") ||
        filters.some((f) => f === "description")
      ) {
        return (
          issue.title.includes(searchText) ||
          issue.description.includes(searchText)
        );
      } else {
        return true;
      }
    })
    .map((voteInfo: Issue, index: number) => {
      return (
        <Col key={`vote_key_${index}`} span="sm">
          <OneTypeVotingWidget
            voteInfo={voteInfo}
            onClickOneVote={onClickOneVote}
          />
        </Col>
      );
    });
  return (
    <div className="mandates-main">
      <h3>All Mandates</h3>
      <div className="header-section">
        <h6 className="header-section-title">Showing all Mandates.</h6>
        <Select
          suffixIcon={<FilterOutlined />}
          allowClear
          showArrow
          mode="multiple"
          className="header-section-input"
          placeholder="Apply Filters"
          onChange={handleSelectChange}
        >
          {defaultFilters.map((filter, index) => {
            return (
              <Option key={`filter_key_${index}`} value={filter}>
                {filter}
              </Option>
            );
          })}
        </Select>
        <Input
          className="header-section-input"
          allowClear
          placeholder="Search in mandates"
          prefix={<SearchOutlined />}
          onChange={handleTextFieldChange}
        />
      </div>
      <Row className="popular-mandates-items-container" gutter={[32, 32]}>
        {votesUIList}
      </Row>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any, _: any) => ({
  dispatch: dispatch,
});
const mapStateToProps = (state: any, _: any) => ({
  IssueData: state.IssueData,
  UserData: state.UserData,
});
export default connect(mapStateToProps, mapDispatchToProps)(MandatesPage);
