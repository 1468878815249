import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  EnvironmentOutlined,
  PaperClipOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import BackLink from "../../components/common/back_link/back_link";
import DetailHeading from "../../components/common/detail_heading/detail_heading";
import { Button, Modal, Popover } from "antd";
import DateWithCalendar from "../../components/dashboard_page_components/date_with_calendar/date_with_calendar";
import VotingDetailWidget from "./voting_detail_widget/voting_detail_widget";
import { connect } from "react-redux";
import "./vote_detail_page.css";
import { DataEntry } from "react-minimal-pie-chart/types/commonTypes";
import VotingResultsGraph from "../../components/voting_results_graphs/voting_results_graphs";
import { Attachment, BasicAttachment } from "../../models/Attachment";
import Issue from "../../models/Issue";
import { getIssueData } from "../../services/redux/reducers/issue_data_service";
import ShareButtonsModal from "../../components/common/share_buttons/share_buttons";
import { useMediaQuery } from "react-responsive";

interface ParamTypes {
  id: string;
}

const VoteDetailPage = (props: any) => {
  let { id } = useParams<ParamTypes>();
  const { dispatch } = props;
  const [isMandateTextVisible, setIsMandateTextVisible] = useState(false);
  const [isExplanatoryMemorandaVisible, setIsExplanatoryMemorandaVisible] =
    useState(false);
  const [showShareResults, setShowShareResults] = useState(false);
  const [voteInfo, setVoteInfo] = useState<Issue | undefined>(undefined);
  useEffect(() => {
    getIssueData(id, (issue) => {
      setVoteInfo(issue);
    });
  }, [dispatch, id]);

  const attachments: Attachment[] = voteInfo?.attachments
    ? voteInfo.attachments.map((attachment: BasicAttachment) => {
        const attach = {
          url: attachment.url,
          name: attachment.name,
          raw: undefined,
        };
        return attach;
      })
    : [];

  const graphData: DataEntry[] = [
    {
      title: "Voted in favor",
      value: voteInfo?.yeses?.length ?? 0,
      color: "#04C139",
    },
    {
      title: "Voted Against",
      value: voteInfo?.noes?.length ?? 0,
      color: "#FF6B6B",
    },
  ];

  const handleShare = () => {
    setShowShareResults(true);
  };

  const handleMandateTextModal = (value: boolean) => {
    setIsMandateTextVisible(value);
  };
  const handleExplanatoryMemorandaModal = (value: boolean) => {
    setIsExplanatoryMemorandaVisible(value);
  };
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  return (
    <div>
      {isTabletOrMobile ? (
        <BackLink link="/voting" heading="Voting In Progress" />
      ) : (
        <>
          <BackLink link="/voting" />
          <DetailHeading
            heading="Voting In Progress"
            subHeading="Showing Details for vote"
          />
        </>
      )}
      <div className="vote-detail-main">
        <div className="vote-detail-center">
          <VotingDetailWidget voteInfo={voteInfo} />
          <div className="vote-detail-mandate-buttons">
            {isTabletOrMobile && (
              <div className="vote-detail-mandate-outer-section">
                {" "}
                <div className="vote-detail-mandate-section">
                  <b>Mandate Text</b>
                  <p>Text of the mandate as introduced into the parliament</p>
                </div>
                {voteInfo?.mandateText ? (
                  <Button
                    type="default"
                    className="ant-btn-inverted"
                    size="large"
                    onClick={() => handleMandateTextModal(true)}
                  >
                    View
                  </Button>
                ) : (
                  <Popover content="No mandate text available" trigger="click">
                    <Button
                      type="default"
                      className="ant-btn-inverted"
                      size="large"
                    >
                      View
                    </Button>
                  </Popover>
                )}
              </div>
            )}

            {!isTabletOrMobile && (
              <div className="vote-detail-mandate-section">
                <b>Mandate Text</b>
                <p>Text of the mandate as introduced into the parliament</p>
                {voteInfo?.mandateText ? (
                  <Button
                    type="default"
                    className="ant-btn-inverted"
                    size="large"
                    onClick={() => handleMandateTextModal(true)}
                  >
                    View Mandate Text
                  </Button>
                ) : (
                  <Popover content="No mandate text available" trigger="click">
                    <Button
                      type="default"
                      className="ant-btn-inverted"
                      size="large"
                    >
                      View Mandate Text
                    </Button>
                  </Popover>
                )}
              </div>
            )}

            {isTabletOrMobile && (
              <div className="vote-detail-memoranda-outer-section">
                <div className="vote-detail-memoranda-section">
                  <b>Explanatory Memoranda</b>
                  <p>
                    Accompanies and provides an explanation of the content
                    introduced version (first reading) of the mandate.
                  </p>
                </div>
                {voteInfo?.explanatoryMemoranda ? (
                  <Button
                    type="default"
                    className="ant-btn-inverted"
                    size="large"
                    onClick={() => handleExplanatoryMemorandaModal(true)}
                  >
                    View
                  </Button>
                ) : (
                  <Popover
                    content="No explanatory memoranda available"
                    trigger="click"
                  >
                    <Button
                      type="default"
                      className="ant-btn-inverted"
                      size="large"
                    >
                      View
                    </Button>
                  </Popover>
                )}
              </div>
            )}
            {!isTabletOrMobile && (
              <div className="vote-detail-memoranda-section">
                <b>Explanatory Memoranda</b>
                <p>
                  Accompanies and provides an explanation of the content
                  introduced version (first reading) of the mandate.
                </p>
                {voteInfo?.explanatoryMemoranda ? (
                  <Button
                    type="default"
                    className="ant-btn-inverted"
                    size="large"
                    onClick={() => handleExplanatoryMemorandaModal(true)}
                  >
                    View Explanatory Memoranda
                  </Button>
                ) : (
                  <Popover
                    content="No explanatory memoranda available"
                    trigger="click"
                  >
                    <Button
                      type="default"
                      className="ant-btn-inverted"
                      size="large"
                    >
                      View Explanatory Memoranda
                    </Button>
                  </Popover>
                )}
              </div>
            )}
          </div>
          <div className="vote-detail-info">
            <div>
              <b>Posted by</b>
              <div className="grey-text">{voteInfo?.postedBy}</div>
              <div className="grey-text">
                on <DateWithCalendar date={voteInfo?.created} size={14} />
              </div>
            </div>
            {voteInfo?.scopeOfIssue === 'local' && <>
              <div>
                <b>Concerning Localities</b>
                <p>
                  <EnvironmentOutlined />
                  <span className="grey-text">{voteInfo?.locality}</span>
                </p>
              </div>
            </>}
            {voteInfo?.scopeOfIssue === 'state' && <>
              <div>
                <b>State</b>
                <p>
                  <EnvironmentOutlined />
                  <span className="grey-text">{voteInfo?.state}</span>
                </p>
              </div>
            </>}
            <div>
              <b>Attachments</b>
              <p>
                <PaperClipOutlined />
                {attachments.map((attachment, index) => {
                  return (
                    <a
                      key={`attachment_link_${index}`}
                      href={attachment.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="green-text">
                        {attachment.name}{" "}
                        {attachments.length - 1 === index ? "" : ", "}
                      </span>
                    </a>
                  );
                })}
              </p>
            </div>
          </div>
          <div>
            <b>Description</b>
            <p>{voteInfo?.description}</p>
          </div>
        </div>
        <div className="vote-detail-side">
          <div className="vote-detail-block">
            <h6>Voting Results</h6>
            <VotingResultsGraph data={graphData} />

            <div style={{ paddingTop: "40px" }} className="center-text">
              <Button
                type="primary"
                onClick={handleShare}
                size="large"
                icon={<ShareAltOutlined />}
              >
                Share these Results
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Mandate Text"
        visible={isMandateTextVisible}
        onCancel={() => handleMandateTextModal(false)}
        footer={null}
        focusTriggerAfterClose={false}
      >
        {voteInfo?.mandateText}
      </Modal>
      <Modal
        title="Explanatory Memoranda"
        visible={isExplanatoryMemorandaVisible}
        onCancel={() => handleExplanatoryMemorandaModal(false)}
        footer={null}
        focusTriggerAfterClose={false}
      >
        {voteInfo?.explanatoryMemoranda}
      </Modal>
      <ShareButtonsModal
        show={showShareResults}
        onHide={() => {
          setShowShareResults(false);
        }}
        linkToShare={`${window.location.href}`}
        source={`wethepeople`}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch: any, _: any) => ({
  dispatch: dispatch,
});
const mapStateToProps = (state: any, _: any) => ({
  IssueData: state.IssueData,
});
export default connect(mapStateToProps, mapDispatchToProps)(VoteDetailPage);
