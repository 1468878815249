import React from "react";
import KeywordItem from "../../../components/dashboard_page_components/keyword_item/keyword_item";
import StatusHouse from "../../../components/dashboard_page_components/status_house.css/status_house";
import "./voting_detail_widget.css";
import { Steps } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import DateWithCalendar from "../../../components/dashboard_page_components/date_with_calendar/date_with_calendar";
import { VOTE_STATUS } from "../../../constants/enums";
const { Step } = Steps;
function VotingDetailWidget(props: any) {
  const { voteInfo } = props;
  const keywordUI = (voteInfo?.tags ?? []).map((tag: string, index: number) => {
    return <KeywordItem key={`one_keyword_key_${index}`} keyword={tag} />;
  });
  return (
    <div className="one-vote">
      <div
        className="voting-detail-container"
        style={{
          background:
            voteInfo?.status === VOTE_STATUS.closed ? "#FF6B6B" : "#00BA34",
        }}
      >
        <div className="voting-detail-info-container">
          <div className="voting-detail-info-title">{voteInfo?.title}</div>
          <div className="voting-detail-info-keyword">{keywordUI}</div>
          <DateWithCalendar date={voteInfo?.created} size={14} />
          <div className="voting-detail-active-one-vote-info-status">
            <Steps size="small">
              <Step
                title={<span>Preparing Mandate for <br/> submission</span>}
                status={
                  voteInfo?.status > VOTE_STATUS.started
                    ? "finish"
                    : voteInfo?.status === VOTE_STATUS.started
                    ? "process"
                    : "wait"
                }
                icon={<CheckCircleFilled style={{ fontSize: 32 }} />}
              />
              <Step
                title="At Public Servants"
                status={
                  voteInfo?.status > VOTE_STATUS.progress
                    ? "finish"
                    : voteInfo?.status === VOTE_STATUS.progress
                    ? "process"
                    : "wait"
                }
                icon={
                  <StatusHouse
                    background={
                      voteInfo?.status &&
                      voteInfo?.status >= VOTE_STATUS.progress
                        ? "#ab63fe"
                        : "rgba(0, 0, 0, 0.25)"
                    }
                  />
                }
              />
              <Step
                title="Complete"
                status={
                  voteInfo?.status === VOTE_STATUS.closed ? "finish" : "wait"
                }
                icon={<CheckCircleFilled style={{ fontSize: 32 }} />}
              />
            </Steps>
          </div>
        </div>
        <div className="voting-detail-select-container">
          {voteInfo?.status === VOTE_STATUS.closed ? (
            <span>Closed</span>
          ) : (
            <span>Open</span>
          )}
        </div>
      </div>
    </div>
  );
}

export default VotingDetailWidget;
