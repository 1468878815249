import React, { useContext, useEffect, useState } from "react";
import "./profile_page.css";
import { connect } from "react-redux";
import ProfilePage from "./profile_page/profile_page";
import { AuthContext } from "../../services/Auth";
import { getSingleUserData } from "../../services/redux/reducers/user_data_service";

const ProfilePageView = (props: any) => {
  const currentUser = useContext(AuthContext);
  const { dispatch } = props;
  const { userInfo } = props.CommonData;
  const [user, setUser] = useState(userInfo);

  useEffect(() => {
    window.scrollTo(0, 0);
    getSingleUserData(currentUser?.uid!, (user) => setUser(user));
  }, [currentUser?.uid, dispatch]);

  return (
    <React.Fragment>
      <h3>Profile</h3>
      <ProfilePage user={user!} />
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch: any, _: any) => ({
  dispatch: dispatch,
});
const mapStateToProps = (state: any, _: any) => ({
  CommonData: state.CommonData,
});
export default connect(mapStateToProps, mapDispatchToProps)(ProfilePageView);
