import React, { ChangeEvent, useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, message, Spin } from "antd";
import User from "../../../models/User";
import { LoadingOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { updateUserData } from "../../../services/redux/reducers/user_data_service";
import { Attachment } from "../../../models/Attachment";
import { storage } from "../../../services/firebaseService";
import { Moment } from "moment";
import moment from "moment";
const loadingIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
);
const layout = {
  labelCol: { span: "sm" },
  wrapperCol: { span: 12 },
};

interface ProfilePageProps {
  user: User;
}

const ProfilePage = (userProp: ProfilePageProps) => {
  const [user, setUser] = useState<User>(userProp.user);
  const imageAttachment: Attachment = { raw: undefined, url: "", name: "" };
  const [image, setImage] = useState(imageAttachment);
  const [isPostButtonDisabled, setIsPostButtonDisabled] = useState(false);
  const hiddenFileInput: any = React.createRef();
  const handleUpload = (_: any) => {
    hiddenFileInput.current.click();
  };

  const handleImageChange = (e: any) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      setImage({
        url: URL.createObjectURL(file),
        raw: file,
        name: file.name,
      });
      handleUserChange({ ...user, profileUrl: file.name });
    }
  };

  const updateUser = (user: User) => {
    updateUserData(user, (err) => {
      if (err) {
        setIsPostButtonDisabled(false);
        message.error(err.message);
      } else {
        setIsPostButtonDisabled(false);
        message.success("Profile updated successfully");
      }
    });
  };

  const uploadImageAndUpdateUser = () => {
    if (image.raw) {
      const imageNameParts = image.name.split(".");
      const imageName = imageNameParts[0];
      const imageExtension = imageNameParts[1];
      const imageFileName = `${imageName}_${uuidv4()}.${imageExtension}`;
      const uploadTask = storage.ref(`images/${imageFileName}`).put(image.raw);
      uploadTask.on(
        "state_changed",
        () => {},
        (error: any) => {
          setIsPostButtonDisabled(false);
          message.error(error.stack);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            user.profileUrl = downloadURL;
            updateUser(user);
          });
        }
      );
    } else {
      updateUser(user);
    }
  };

  const handleUserChange = (user: User) => {
    setUser(user);
  };
  const onFinish = (values: any) => {
    message.success("Success:", values);
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error("Failed:", errorInfo);
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    setIsPostButtonDisabled(true);
    uploadImageAndUpdateUser();
  };

  return (
    <div>
      <Form
        {...layout}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item name="profileUrl" style={{ textAlign: "center" }}>
          <div>
            {image.url ? (
              <img
                style={{ borderRadius: "50%" }}
                src={image.url}
                alt="profile"
                width="200"
                height="200"
              />
            ) : (
              <img
                style={{ borderRadius: "50%" }}
                src={
                  user.profileUrl ? user.profileUrl : "/assets/images/male.png"
                }
                alt="profile"
                width="200"
                height="200"
              />
            )}
            <Button type="primary" onClick={handleUpload}>
              {user.profileUrl
                ? "Update profile image"
                : "Choose profile image"}
            </Button>
          </div>

          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleImageChange}
            style={{ display: "none" }}
          />
        </Form.Item>

        <Form.Item
          name="name"
          initialValue={user.name}
          rules={[{ required: true, message: "Please enter a name" }]}
        >
          <Input
            value={user.name}
            placeholder="Name"
            onChange={(e: ChangeEvent<{ value: string }>) => {
              handleUserChange({ ...user, name: e.target.value });
            }}
          />
        </Form.Item>
        <Form.Item
          name="email"
          label=""
          initialValue={user.email}
          rules={[{ required: true, message: "Please enter a email" }]}
        >
          <Input
            value={user.email}
            placeholder="Email"
            onChange={(e: ChangeEvent<{ value: string }>) => {
              handleUserChange({ ...user, email: e.target.value });
            }}
          />
        </Form.Item>
        <Form.Item
          name="address"
          initialValue={user.address}
          rules={[{ required: true, message: "Please enter a address" }]}
        >
          <Input
            value={user.address}
            placeholder="Address"
            onChange={(e: ChangeEvent<{ value: string }>) => {
              handleUserChange({ ...user, address: e.target.value });
            }}
          />
        </Form.Item>
        <Form.Item
          name="dateOfBirth"
          rules={[{ required: true, message: "Please enter a date of birth" }]}
        >
          <DatePicker
            format="MM/DD/YYYY"
            placeholder="Date of Birth"
            defaultValue={
              user.dateOfBirth ? moment(user.dateOfBirth) : moment()
            }
            onChange={(date: Moment | null, _: string) => {
              if (date) {
                handleUserChange({
                  ...user,
                  dateOfBirth: date.toDate().valueOf(),
                });
              }
            }}
          />
        </Form.Item>

        <Form.Item
          name="isRegisteredVoter"
          valuePropName="checked"
          style={{ marginBottom: 0 }}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            <span>Are you a registered voter?</span>
            <Checkbox
              defaultChecked={user?.isRegisteredVoter ?? false}
              onChange={(e: any) => {
                handleUserChange({
                  ...user,
                  isRegisteredVoter: e.target.checked,
                });
              }}
            ></Checkbox>
          </div>
        </Form.Item>
        <Form.Item name="isUSCitizen" valuePropName="checked">
          <div style={{ display: "flex", gap: "10px" }}>
            <span>Are you a US citizen?</span>
            <Checkbox
              defaultChecked={user?.isUSCitizen ?? false}
              onChange={(e: any) => {
                console.log("isUSCitizen", e);
                handleUserChange({ ...user, isUSCitizen: e.target.checked });
              }}
            ></Checkbox>
          </div>
        </Form.Item>
        <button onClick={handleSave} className="mobile-settings-button">
          {isPostButtonDisabled ? (
            <Spin indicator={loadingIcon} />
          ) : (
            <span>SAVE</span>
          )}
        </button>
      </Form>
    </div>
  );
};

export default ProfilePage;
