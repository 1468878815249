import { Col, Row, Input, Select } from "antd";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import OneIssueWidget from "../../components/one_issue_widget/one_issue_widget";
import "./issues_page.css";
import { connect } from "react-redux";
import Issue from "../../models/Issue";
import { getIssuesData } from "../../services/redux/reducers/issue_data_service";
import { VOTE_TYPE } from "../../constants/enums";
const { Option } = Select;

const defaultFilters: string[] = ["title", "description"];

const IssuesPage = (props: any) => {
  const { IssueData, dispatch } = props;
  const [searchText, setSearchText] = useState("");
  const [filters, setFilters] = useState<string[]>([]);

  const handleSelectChange = (value: string[]) => {
    setFilters(value);
  };

  const handleTextFieldChange = (e: any) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getIssuesData(dispatch);
  }, [dispatch]);
  const { issue_data_list } = IssueData || {};
  const issuesUIList = ((issue_data_list || []) as Issue[])
    .filter((issue) => issue.votingType === VOTE_TYPE.issues)
    .filter((issue) => {
      if (filters.every((f) => f === "title")) {
        return issue.title.includes(searchText);
      } else if (filters.every((f) => f === "description")) {
        return issue.description.includes(searchText);
      } else if (
        filters.some((f) => f === "title") ||
        filters.some((f) => f === "description")
      ) {
        return (
          issue.title.includes(searchText) ||
          issue.description.includes(searchText)
        );
      } else {
        return true;
      }
    })
    .map((issueInfo: Issue, index: number) => {
      const detailUrl: string = `/issue-detail/${issueInfo.id}`;
      return (
        <Col key={`issue_key_${index}`} span="sm">
          <OneIssueWidget issueInfo={issueInfo} detailUrl={detailUrl} />
        </Col>
      );
    });

  return (
    <React.Fragment>
      <h3>All Issues</h3>
      <div className="header-section">
        <h6 className="header-section-title">Showing all Issues.</h6>
        <Select
          suffixIcon={<FilterOutlined />}
          allowClear
          showArrow
          mode="multiple"
          className="header-section-input"
          placeholder="Apply Filters"
          onChange={handleSelectChange}
        >
          {defaultFilters.map((filter: string, index: number) => {
            return (
              <Option key={`filter_key_${index}`} value={filter}>
                {filter}
              </Option>
            );
          })}
        </Select>
        <Input
          className="header-section-input"
          allowClear
          placeholder="Search in issues"
          prefix={<SearchOutlined />}
          onChange={handleTextFieldChange}
        />
      </div>
      <Row gutter={[32, 32]} className="popular-issues-items-container">
        {issuesUIList}
      </Row>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch: any, _: any) => ({
  dispatch: dispatch,
});
const mapStateToProps = (state: any, _: any) => ({
  IssueData: state.IssueData,
});
export default connect(mapStateToProps, mapDispatchToProps)(IssuesPage);
