import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { Input, Spin, Table, Tag } from "antd";
import "./verify_votes_page.css";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import DateWithCalendar from "../../components/dashboard_page_components/date_with_calendar/date_with_calendar";
import VerifiedVote from "../../models/VerifiedVote";
import { connect } from "react-redux";
import {
  getVerifiedVotesData,
  getVerifiedVotesDataByUid,
} from "../../services/redux/reducers/verified_votes_data_service";
import { AuthContext } from "../../services/Auth";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import { useMediaQuery } from "react-responsive";
const antIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

const VerifyVotesPage = (props: any) => {
  const currentUser = useContext(AuthContext);
  const { VerifiedVoteData, dispatch, isAdmin } = props;
  const { verifiedVote_data_list } = VerifiedVoteData || {};
  const chain = process.env.REACT_APP_ETH_CHAIN?.replaceAll("\r", "") as string;
  const verifiedVotingList = (verifiedVote_data_list || []) as VerifiedVote[];
  const [verifiedVotes, setVerifiedVotes] = useState(
    isAdmin
      ? verifiedVotingList
      : verifiedVotingList.filter(
          (verified) => verified.uid === currentUser?.uid
        )
  );
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    isAdmin
      ? getVerifiedVotesData(dispatch, (verifiedVotes: VerifiedVote[]) => {
          setIsLoading(false);
          setVerifiedVotes(verifiedVotes);
        })
      : getVerifiedVotesDataByUid(
          dispatch,
          currentUser?.uid,
          (verifiedVotes: VerifiedVote[]) => {
            setIsLoading(false);
            setVerifiedVotes(verifiedVotes);
          }
        );
  }, [dispatch, currentUser, isAdmin]);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const columns = [
    {
      title: "Issue Id",
      dataIndex: "issueId",
      key: "issueId",
      responsive: ["sm", "xs"] as Breakpoint[],
      render: (id: string) => {
        const truncatedId = id.substring(0, 5);
        return (
          <a href={`/vote-detail/${id}`}>
            {isTabletOrMobile ? `${truncatedId}...` : id}
          </a>
        );
      },
    },
    {
      title: "User Id",
      dataIndex: "uid",
      key: "uid",
      responsive: ["sm"] as Breakpoint[],
    },
    {
      title: "Transaction Hash",
      dataIndex: "transactionHash",
      key: "transactionHash",
      width: 0.5,
      responsive: ["sm", "xs"] as Breakpoint[],
      render: (id: string) => {
        const truncatedId = id.substring(0, 5);
        return (
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://${chain}.etherscan.io/tx/${id}`}
          >
            {truncatedId}
          </a>
        );
      },
    },
    {
      title: "Block Number",
      dataIndex: "blockNumber",
      key: "blockNumber",
      responsive: ["sm"] as Breakpoint[],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      responsive: ["sm", "xs"] as Breakpoint[],
      render: (text: string) => (
        <Tag
          color={text === "" ? "blue" : text === "success" ? "green" : "red"}
        >
          {text}
        </Tag>
      ),
    },
    {
      title: "Decision",
      dataIndex: "decision",
      key: "decision",
      responsive: ["sm"] as Breakpoint[],
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      responsive: ["sm", "xs"] as Breakpoint[],
      render: (time: number) => <DateWithCalendar date={time} size={"14px"} />,
    },
  ];
  return (
    <div>
      <h3>
        {isAdmin ? <span>Verified Votes</span> : <span>Verify Your Votes</span>}
      </h3>
      <Input
        className="manage-vote-input"
        prefix={<SearchOutlined />}
        value={searchText}
        onChange={(e: ChangeEvent<{ value: string }>) => {
          const value = e.target.value;
          setSearchText(value);
          setVerifiedVotes(
            verifiedVotingList.filter(
              (vote: VerifiedVote) =>
                vote.transactionHash.includes(value) ||
                vote.uid.includes(value) ||
                vote.issueId.includes(value) ||
                vote.status.includes(value) ||
                value === ""
            )
          );
        }}
      />

      {isLoading ? (
        <Spin
          indicator={antIcon}
          style={{ display: "flex", justifyContent: "center" }}
        />
      ) : (
        <Table dataSource={verifiedVotes} columns={columns} />
      )}
    </div>
  );
};
const mapDispatchToProps = (dispatch: any, _: any) => ({
  dispatch: dispatch,
});
const mapStateToProps = (state: any, _: any) => ({
  VerifiedVoteData: state.VerifiedVoteData,
});
export default connect(mapStateToProps, mapDispatchToProps)(VerifyVotesPage);
