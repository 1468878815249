import Web3 from "web3";
import byteCode from "../contracts/build/voting.sol/Voting/Voting_bytecode.json";
import abi from "../contracts/build/voting.sol/Voting/Voting_abi.json";
import { Transaction } from "ethereumjs-tx";
import { Contract } from "web3-eth-contract";
import { TransactionReceipt } from "web3-core";
import { AbiItem, stringToHex } from "web3-utils";
import { YesNoType } from "../constants/enums";
const key = () =>
  Buffer.from(
    process.env.REACT_APP_PRIVATE_KEY?.replaceAll("\r", "") as string,
    "hex"
  );

export const createVotingContract = async (
  issue: string
): Promise<TransactionReceipt> => {
  const { web3, votingContract } = await buildVotingContract();
  const hexIssue = stringToHex(issue);
  const deployedContract = votingContract
    .deploy({
      data: byteCode.object,
      arguments: [hexIssue],
    })
    .encodeABI();
  const transaction = sendTransaction(web3, deployedContract, key());
  return await transaction;
};

export const voteForIssue = async (
  web3: Web3,
  contract: Contract,
  issue: string,
  voter: string
): Promise<any> => {
  const hexIssue = stringToHex(issue);
  const hexVoter = stringToHex(`${voter}-${YesNoType.YES}`);
  const voteForIssueContract = contract.methods
    .voteForIssue(hexIssue, hexVoter)
    .encodeABI();
  const transaction = sendTransaction(
    web3,
    voteForIssueContract,
    key(),
    contract.options.address
  );

  return await transaction;
};

export const voteAgainstIssue = async (
  web3: Web3,
  contract: Contract,
  issue: string,
  voter: string
): Promise<any> => {
  const hexIssue = stringToHex(issue);
  const hexVoter = stringToHex(`${voter}-${YesNoType.NO}`);
  const voteAgainstIssueContract = contract.methods
    .voteAgainstIssue(hexIssue, hexVoter)
    .encodeABI();
  const transaction = sendTransaction(
    web3,
    voteAgainstIssueContract,
    key(),
    contract.options.address
  );

  return await transaction;
};

export const buildVotingContract = async () => {
  const web3 = new Web3(
    new Web3.providers.HttpProvider(
      process.env.REACT_APP_DEFAULT_BLOCKCHAIN_HOST_ADDRESS?.replaceAll(
        "\r",
        ""
      ) as string
    )
  );
  const abiDefinition: AbiItem[] = abi as AbiItem[];
  const votingContract = new web3.eth.Contract(abiDefinition);
  return { web3, votingContract };
};

const sendTransaction = async (
  web3: Web3,
  deployedContract: string,
  privateKey: Buffer,
  toAddress?: string
) => {
  const to = toAddress ? { to: toAddress } : undefined;
  const fromEtherAddress = process.env.REACT_APP_FROM_ETH_ADDRESS?.replaceAll(
    "\r",
    ""
  ) as string;
  const chain = process.env.REACT_APP_ETH_CHAIN?.replaceAll("\r", "") as string;
  const txCount = await web3.eth.getTransactionCount(fromEtherAddress);
  const nonce = web3.utils.toHex(txCount);
  const gas = await web3.eth.estimateGas({
    from: fromEtherAddress,
    nonce: txCount,
    data: deployedContract,
    ...to,
  });
  const gasPrice = await web3.eth.getGasPrice();
  const txObject = {
    nonce,
    gasLimit: web3.utils.toHex(gas),
    gasPrice: web3.utils.toHex(gasPrice),
    data: deployedContract,
    ...to,
  };
  const tx = new Transaction(txObject, {
    chain: chain,
    hardfork: "petersburg",
  });
  tx.sign(privateKey);
  const serializedTx = tx.serialize();
  const raw = "0x" + serializedTx.toString("hex");
  web3.eth.defaultAccount = fromEtherAddress;
  return await web3.eth.sendSignedTransaction(raw);
};
