import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Input, message, Modal, Spin, Table } from "antd";
import {
  LoadingOutlined,
  EditFilled,
  DeleteFilled,
  ExclamationCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import DateWithCalendar from "../../components/dashboard_page_components/date_with_calendar/date_with_calendar";
import { connect } from "react-redux";
import "./manage_notifications_page.css";
import {
  getNotificationsData,
  removeNotificationData,
} from "../../services/redux/reducers/notification_data_service";
import Notification from "../../models/Notification";
const { confirm } = Modal;
const antIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

const ManageNotificationPage = (props: any) => {
  const history = useHistory();
  const { NotificationData, dispatch } = props;
  const { notification_data_list } = NotificationData;
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const notificationList = (notification_data_list
    ? notification_data_list
    : []) as Notification[];
  const [notifications, setNotifications] = useState(notificationList);
  useEffect(() => {
    getNotificationsData(
      dispatch,
      (notifications: Notification[] | undefined) => {
        setIsLoading(false);
        if (notifications) {
          setNotifications(notifications);
        }
      }
    );
  }, [dispatch]);

  const handleEditNotification = (id: string) => {
    history.push(`/notification-detail/${id}`);
  };

  const handleRemoveNotification = (id: string) => {
    const notification = notifications.find(
      (notification: Notification) => notification.id === id
    );
    confirm({
      title: `Are you sure you want to remove ${notification?.title ?? ""}?`,
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        removeNotificationData(id, (err) => {
          if (err != null) {
            message.error(err.message);
          } else {
            message.success(
              `Successfully removed ${notification?.title ?? ""}`
            );
          }
        });
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      render: (link: string) => <Link to={link}>{link}</Link>,
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      render: (time: number) => <DateWithCalendar date={time} size={"14px"} />,
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (id: string) => (
        <div style={{ display: "flex", gap: "18px" }}>
          <Button
            type="text"
            icon={<EditFilled />}
            onClick={(e: any) => {
              e.preventDefault();
              handleEditNotification(id);
            }}
          />
          <Button
            type="text"
            icon={<DeleteFilled />}
            onClick={(e: any) => {
              e.preventDefault();
              handleRemoveNotification(id);
            }}
          />
        </div>
      ),
    },
  ];
  return (
    <div>
      <h3>Manage Notifications</h3>
      <div className="manage-notifications-input-create">
        <Input
          className="manage-notifications-input"
          prefix={<SearchOutlined />}
          value={searchText}
          onChange={(e: ChangeEvent<{ value: string }>) => {
            const value = e.target.value;
            setSearchText(value);
            setNotifications(
              notificationList.filter(
                (notification: Notification) =>
                  notification.title.includes(value) ||
                  notification.description.includes(value) ||
                  value === ""
              )
            );
          }}
        />
        <Button
          size="large"
          type="primary"
          className="manage-notifications-input"
          onClick={() => history.push("/create-notification")}
        >
          Create Notification
        </Button>
      </div>

      {isLoading ? (
        <Spin
          indicator={antIcon}
          style={{ display: "flex", justifyContent: "center" }}
        />
      ) : (
        <Table dataSource={notifications} columns={columns} />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: any, _: any) => ({
  dispatch: dispatch,
});
const mapStateToProps = (state: any, _: any) => ({
  NotificationData: state.NotificationData,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageNotificationPage);
