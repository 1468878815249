import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/analytics";
import "firebase/storage";
import "firebase/remote-config";
import { message } from "antd";

var firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY?.replaceAll("\r", ""),
  authDomain: process.env.REACT_APP_AUTH_DOMAIN?.replaceAll("\r", ""),
  databaseURL: process.env.REACT_APP_DATABASE_URL?.replaceAll("\r", ""),
  projectId: process.env.REACT_APP_PROJECT_ID?.replaceAll("\r", ""),
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET?.replaceAll("\r", ""),
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID?.replaceAll(
    "\r",
    ""
  ),
  appId: process.env.REACT_APP_APP_ID?.replaceAll("\r", ""),
  measurementId: process.env.REACT_APP_MEASUREMENT_ID?.replaceAll("\r", ""),
};

export const firebaseService = firebase.initializeApp(firebaseConfig);
const firebaseDb = firebaseService.database().ref();
firebase.analytics();
export default firebaseService.database().ref();
export const storage = firebaseService.storage();
const auth = firebaseService.auth();

export const getFirebaseData = (
  collectionName: string,
  callback: (list: any) => void
) => {
  firebaseDb.child(collectionName).on("value", (snapshot) => {
    if (snapshot.val()) {
      const data = snapshot.val();
      const keyList = Object.keys(data);
      const list = keyList.map((key) => {
        return { ...data[key], id: key };
      });
      callback(list);
    } else {
      callback(undefined);
    }
  });
};

export const getFirebaseSingleData = (
  collectionName: string,
  child: string,
  matcher: any,
  callback: (obj: any) => void
) => {
  firebaseDb
    .child(collectionName)
    .orderByChild(child)
    .equalTo(matcher)
    .on(
      "value",
      (snapshot) => {
        if (snapshot.val() != null) {
          const data = snapshot.val();
          const keyList = Object.keys(data);
          const list = keyList.map((key) => {
            return { ...data[key], id: key };
          });
          callback(list[0]);
        } else {
          callback(undefined);
        }
      },
      (cancelCallbackOrContext: any) => {
        if (auth?.currentUser) {
          message.error(
            `${JSON.stringify(
              cancelCallbackOrContext
            )} for ${collectionName}/${child}/${matcher}`
          );
        }
        callback(undefined);
      }
    );
};

export const getFirebaseDataByMatcher = (
  collectionName: string,
  child: string,
  matcher: any,
  callback: (obj: any) => void
) => {
  firebaseDb
    .child(collectionName)
    .orderByChild(child)
    .equalTo(matcher)
    .on(
      "value",
      (snapshot) => {
        if (snapshot.val() != null) {
          const data = snapshot.val();
          const keyList = Object.keys(data);
          const list = keyList.map((key) => {
            return { ...data[key], id: key };
          });
          callback(list);
        } else {
          callback(undefined);
        }
      },
      (cancelCallbackOrContext: any) => {
        if (auth?.currentUser) {
          message.error(
            `${JSON.stringify(
              cancelCallbackOrContext
            )} for ${collectionName}/${child}/${matcher}`
          );
        }
        callback(undefined);
      }
    );
};

export const addFirebaseData = (
  collectionName: string,
  data: any,
  callback: (error: Error | null) => any
) => {
  return firebaseDb.child(collectionName).push(data, (err) => {
    callback(err);
  });
};

export const addFirebaseDataByUid = (
  collectionName: string,
  data: any,
  callback: (error: Error | null) => any
) => {
  return firebaseDb.child(`${collectionName}/${data.uid}`).set(data, (err) => {
    callback(err);
  });
};

export const addFirebaseDataById = (
  collectionName: string,
  data: any,
  callback: (error: Error | null) => any
) => {
  return firebaseDb.child(`${collectionName}/${data.id}`).set(data, (err) => {
    callback(err);
  });
};

export const updateFirebaseDataById = (
  collectionName: string,
  data: any,
  callback: (error: Error | null) => any
) => {
  return firebaseDb
    .child(`${collectionName}/${data.id}`)
    .update(data, (err) => {
      callback(err);
    });
};

export const updateFirebaseData = (
  collectionName: string,
  data: any,
  callback: (error: Error | null) => any
) => {
  return firebaseDb.child(collectionName).update(data, (err) => {
    callback(err);
  });
};

export const setFirebaseData = (
  collectionName: string,
  data: any,
  callback: (error: Error | null) => any
) => {
  return firebaseDb.child(collectionName).set(data, (err) => {
    callback(err);
  });
};

export const removeFirebaseData = (
  path: string,
  callback: (error: Error | null) => any
) => {
  return firebaseDb.child(path).remove((err) => {
    callback(err);
  });
};
