import React, { useContext } from "react";
import { Route } from "react-router-dom";
import { AuthContext } from "./Auth";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const loadingIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;
const PrivateRoute = ({ children }: any) => {
  const currentUser = useContext(AuthContext);
  return (
    <Route
      render={() => (
        <>
          {currentUser && children}
          {currentUser === undefined && (
            <Spin
              indicator={loadingIcon}
              style={{ display: "flex", justifyContent: "center" }}
            />
          )}
        </>
      )}
    />
  );
};

export default PrivateRoute;
