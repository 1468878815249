import { Progress } from 'antd'
import React from 'react'
import './progress_circle_chart.css'
function ProgressCircleChart(props: any) {
    const {percentage} = props;
    return (
        <div className="progress_circle-chart-style">
            <Progress
                percent={percentage}
                success={{ percent: percentage }}
                type="circle"
                width={70}
                trailColor={"#FF6B6B"}
            />
        </div>
    )
}

export default ProgressCircleChart
