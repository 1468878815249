import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Button } from "antd";
import { connect } from "react-redux";
import "./voting_results_page.css";
import { ShareAltOutlined } from "@ant-design/icons";
import CustomProgress from "../../components/common/progress/custom_progress";
import ProgressItem from "../../models/ProgressItem";
import DetailHeading from "../../components/common/detail_heading/detail_heading";
import BackLink from "../../components/common/back_link/back_link";
import VotingResultsGraph from "../../components/voting_results_graphs/voting_results_graphs";
import { DataEntry } from "react-minimal-pie-chart/types/commonTypes";
import ParamTypes from "../../models/ParamTypes";
import { getIssueData } from "../../services/redux/reducers/issue_data_service";
import Issue from "../../models/Issue";
import User from "../../models/User";
import { getUsersData } from "../../services/redux/reducers/user_data_service";
import moment from "moment";
import { RoleType } from "../../constants/enums";
import ShareButtonsModal from "../../components/common/share_buttons/share_buttons";
import { useMediaQuery } from "react-responsive";

const VotingResultsPage = (props: any) => {
  let { id } = useParams<ParamTypes>();
  const { IssueData, dispatch } = props;
  const { issue_data_list } = IssueData;
  const [users, setUsers] = useState<User[]>([]);
  const [showShareResults, setShowShareResults] = useState(false);
  const [voteInfo, setVoteInfo] = useState<Issue>(
    issue_data_list.find((vote: Issue) => vote.id === id)
  );
  useEffect(() => {
    getIssueData(id, (issue) => {
      setVoteInfo(issue);
    });
    getUsersData(dispatch, (users) =>
      setUsers(users.filter((user: User) => user.role === RoleType.USER))
    );
  }, [dispatch, id]);

  const ages = users
    .filter((user: User) => {
      return (
        voteInfo?.yeses?.some((y) => y === user.uid) ||
        voteInfo?.noes?.some((n) => n === user.uid)
      );
    })
    .map((user: User) => moment().diff(user.dateOfBirth ?? 0, "years"));
  const between18And35 = ages.filter((age) => age >= 18 && age <= 35).length;
  const between36And40 = ages.filter((age) => age >= 36 && age <= 40).length;
  const greaterThan40 = ages.filter((age) => age > 40).length;
  const totalAges = between18And35 + between36And40 + greaterThan40;
  const groupProgressItems: ProgressItem[] = [
    {
      label: "18-35",
      percentage: Math.round((between18And35 / totalAges) * 100),
      order: 1,
    },
    {
      label: "36-40",
      percentage: Math.round((between36And40 / totalAges) * 100),
      order: 2,
    },
    {
      label: "+41",
      percentage: Math.round((greaterThan40 / totalAges) * 100),
      order: 3,
    },
  ];
 
  const graphData: DataEntry[] = [
    {
      title: "Voted in favor",
      value: voteInfo?.yeses?.length ?? 0,
      color: "#04C139",
    },
    {
      title: "Voted Against",
      value: voteInfo?.noes?.length ?? 0,
      color: "#FF6B6B",
    }
  ];
  const handleShare = () => {
    setShowShareResults(true);
  };
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  return (
    <React.Fragment>
      {isTabletOrMobile ? (
        <BackLink link="/voting" heading="Voting Results" />
      ) : (
        <>
          <BackLink link="/voting" />
          <DetailHeading
            heading="Voting Results"
            subHeading="Showing Details for vote"
          />
        </>
      )}
      <div className="voting-results-main">
        <div className="voting-results-center">
          <div className="voting-results-group">
            <h6 style={{ paddingBottom: "40px" }}>Voters by age group</h6>
            <CustomProgress
              progressItems={groupProgressItems}
              progressSize={535}
              successColor={"#00BA34"}
            />
          </div>
        </div>
        <div className="voting-results-side">
          <div className="voting-results-results-detail">
            <div>
              <h6>Voting Results</h6>
              <VotingResultsGraph data={graphData} />
            </div>
            <div>
              <div style={{ paddingTop: "40px" }} className="center-text">
                <Button
                  type="primary"
                  onClick={handleShare}
                  size="large"
                  icon={<ShareAltOutlined />}
                >
                  Share these Results
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ShareButtonsModal
        show={showShareResults}
        onHide={() => {
          setShowShareResults(false);
        }}
        linkToShare={`${window.location.href}`}
        source={`wethepeople`}
      />
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch: any, _: any) => ({
  dispatch: dispatch,
});
const mapStateToProps = (state: any, _: any) => ({
  IssueData: state.IssueData,
});
export default connect(mapStateToProps, mapDispatchToProps)(VotingResultsPage);
