import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import BackLink from "../../components/common/back_link/back_link";
import DetailHeading from "../../components/common/detail_heading/detail_heading";
import { connect } from "react-redux";
import { Button, message, Input, Tooltip, Select, Row, Col, Spin } from "antd";
import {
  QuestionCircleFilled,
  PaperClipOutlined,
  PlusCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  addFirebaseData,
  storage,
  updateFirebaseDataById,
} from "../../services/firebaseService";
import { v4 as uuidv4 } from "uuid";
import "./post_suggestion_page.css";
import Proposal from "../../models/Proposal";
import { AuthContext } from "../../services/Auth";
import { Attachment, BasicAttachment } from "../../models/Attachment";
import EditableTagGroupObject from "../../models/EditableTagGroupObject";
import EditableTagGroup from "../../components/Tags/EditableTagGroup";
import { RoleType } from "../../constants/enums";
import { useMediaQuery } from "react-responsive";
import State from "../../models/State";
import stateData from "../../states.json";

const { TextArea } = Input;
const { Option } = Select;
const loadingIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
);

interface IssueInput {
  heading: string;
  townhalls: string;
  state: string;
  scopeOfIssue: string;
  description: string;
  attachments: Attachment[];
}

const PostSuggestionPage = (props: any) => {
  const history = useHistory();
  const defaultTags: string[] = ["Voting", "Townhall"];
  const defaultAttachments: Attachment[] = [];
  const { CommonData } = props;
  const { userInfo } = CommonData;
  const states = stateData as State[];
  const initialIssueInput: IssueInput = {
    heading: "",
    townhalls: "",
    state: "",
    scopeOfIssue: "",
    description: "",
    attachments: defaultAttachments,
  };
  const [issueInput, setIssueInput] = useState(initialIssueInput);
  const [isPostButtonDisabled, setIsPostButtonDisabled] = useState(false);
  const imageAttachment: Attachment = { raw: undefined, url: "", name: "" };
  const [image, setImage] = useState(imageAttachment);
  const hiddenFileInput: any = React.createRef();
  const hiddenAttachmentInput: any = React.createRef();
  const currentUser = useContext(AuthContext);
  const [tagGrouped, setTagGroup] = useState<EditableTagGroupObject>({
    tags: [],
    inputVisible: false,
    inputValue: "",
    editInputIndex: -1,
    editInputValue: "",
  });
  const mainText = userInfo.role === RoleType.USER ? "suggestion" : "proposal";

  const handleHeadingInput = (e: any) => {
    setIssueInput({ ...issueInput, heading: e.target.value });
  };
  const handleTownHallsInput = (e: any) => {
    setIssueInput({ ...issueInput, townhalls: e.target.value });
  };

  const handleScopeOfIssueInput = (value: any, option: any) => {
    setIssueInput({ ...issueInput, scopeOfIssue: value });
  };
  const handleStateInput = (value: any, option: any) => {
    setIssueInput({ ...issueInput, state: value });
  };

  const handleDescriptionTextInput = (e: any) => {
    setIssueInput({ ...issueInput, description: e.target.value });
  };

  const handleUpload = (_: any) => {
    hiddenFileInput.current.click();
  };

  const handleAddAttachment = (_: any) => {
    hiddenAttachmentInput.current.click();
  };

  const handleImageChange = (e: any) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      setImage({
        url: URL.createObjectURL(file),
        raw: file,
        name: file.name,
      });
    }
  };

  const handleAttachmentChange = (e: any) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      setIssueInput({
        ...issueInput,
        attachments: [
          ...issueInput.attachments,
          {
            name: file.name,
            url: URL.createObjectURL(file),
            raw: file,
          },
        ],
      });
    }
  };

  const postProposal = async (
    imageUrl: string,
    attachmentUrls?: BasicAttachment[]
  ) => {
    const proposal: Proposal = {
      id: "",
      title: issueInput.heading,
      scopeOfIssue: issueInput.scopeOfIssue,
      description: issueInput.description,
      state: issueInput.state,
      coverImage: imageUrl,
      tags: tagGrouped?.tags ?? [],
      created: Date.now(),
      uid: currentUser ? currentUser.uid : "",
      locality: issueInput.townhalls,
      attachments: attachmentUrls ?? [],
      postedBy: currentUser ? currentUser.displayName ?? "" : "",
    };

    const data = await addFirebaseData(
      "proposals",
      proposal,
      (err: Error | null) => {
        if (err === null) {
          setIsPostButtonDisabled(false);
          history.push(
            userInfo.role === RoleType.USER ? "/town-hall" : "manage-proposals"
          );
          message.success("Suggestion captured, thank you!");
        } else {
          setIsPostButtonDisabled(false);
          message.error(err);
        }
      }
    );

    updateFirebaseDataById(
      "proposals",
      { id: data.key },
      (err: Error | null) => {
        if (err === null) {
          setIsPostButtonDisabled(false);
          message.success("Proposal saved successfully");
        } else {
          setIsPostButtonDisabled(false);
          message.error(err);
        }
      }
    );
  };

  const uploadAttachmentsAndPostProposal = (imageUrl: string) => {
    var counter = 0;
    var attachmentUrls: BasicAttachment[] = [];
    const uploadAttachment = (attachment: Attachment) => {
      if (attachment && attachment.raw) {
        const attachmentNameParts = attachment.name.split(".");
        const attachmentName = attachmentNameParts[0];
        const attachmentExtension = attachmentNameParts[1];
        const attachmentFileName = `${attachmentName}_${uuidv4()}.${attachmentExtension}`;
        const uploadAttachmentTask = storage
          .ref(`images/${attachmentFileName}`)
          .put(attachment.raw);

        uploadAttachmentTask.on(
          "state_changed",
          () => {},
          (error) => {
            message.error(error);
            setIsPostButtonDisabled(false);
          },
          () => {
            ++counter;
            uploadAttachmentTask.snapshot.ref
              .getDownloadURL()
              .then((downloadURL) => {
                attachmentUrls.push({
                  url: downloadURL,
                  name: attachment.name,
                });
                if (counter < issueInput.attachments.length) {
                  uploadAttachment(issueInput.attachments[counter]);
                } else {
                  postProposal(imageUrl, attachmentUrls);
                }
              });
          }
        );
      } else {
        postProposal(imageUrl);
      }
    };

    uploadAttachment(issueInput.attachments[counter]);
  };

  const postProposalAndUploadAllAttachments = () => {
    if (image.raw) {
      const imageNameParts = image.name.split(".");
      const imageName = imageNameParts[0];
      const imageExtension = imageNameParts[1];
      const imageFileName = `${imageName}_${uuidv4()}.${imageExtension}`;
      const uploadTask = storage.ref(`images/${imageFileName}`).put(image.raw);

      uploadTask.on(
        "state_changed",
        () => {},
        (error) => {
          message.error(error.stack);
          setIsPostButtonDisabled(false);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            uploadAttachmentsAndPostProposal(downloadURL);
          });
        }
      );
    }
  };

  const handlePostProposalClick = (_: any) => {
    if (!image.url) {
      message.error("Please choose a cover image");
      return;
    }
    if (!issueInput.heading) {
      message.error("Please enter a heading");
      return;
    }
    if (!issueInput.description) {
      message.error("Please enter a description text");
      return;
    }
    setIsPostButtonDisabled(true);
    postProposalAndUploadAllAttachments();
  };
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  return (
    <div>
      {isTabletOrMobile ? (
        <BackLink link="/town-hall" heading="Post a Suggestion" />
      ) : (
        <>
          <BackLink
            link={
              userInfo.role === RoleType.USER
                ? "/town-hall"
                : "manage-proposals"
            }
          />
          <DetailHeading
            heading={
              userInfo.role === RoleType.USER
                ? "Post a Suggestion"
                : "Post a Proposal"
            }
          />
        </>
      )}
      <div className="post-suggestion-main">
        <div className="post-suggestion-center">
          {image.url ? (
            <img src={image.url} alt="issue" width="300" height="300" />
          ) : (
            <React.Fragment>
              <div
                className="post-suggestion-upload center-text"
                onClick={handleUpload}
              >
                <img src="/assets/images/gallery.png" alt="gallery" />
                <h6>Choose a cover image for your {mainText}</h6>
              </div>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
            </React.Fragment>
          )}
          <div>
            <b>Heading for your {mainText}</b>
            <Input
              className="post-suggestion-input"
              value={issueInput.heading}
              onChange={handleHeadingInput}
            />
            <b>Scope of issue</b>{" "}
            <Tooltip
              placement="right"
              title={`Choose whether the issue is local, state or national`}
            >
              <QuestionCircleFilled />
            </Tooltip>
            <Select
              allowClear
              style={{ width: "100%" }}
              className="post-suggestion-select"
              value={issueInput.scopeOfIssue}
              onChange={handleScopeOfIssueInput}
            >
              <Option value={"local"}>Local</Option>
              <Option value={"state"}>State</Option>
              <Option value={"national"}>National</Option>
            </Select>
            {issueInput.scopeOfIssue === "state" && (
              <>
                <b>State</b>{" "}
                <Tooltip placement="right" title={`Choose the state`}>
                  <QuestionCircleFilled />
                </Tooltip>
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  className="post-suggestion-select"
                  value={issueInput?.state}
                  onChange={handleStateInput}
                >
                  {states.map((state: State, index: number) => {
                    return (
                      <Option key={`state_${index}`} value={state.name}>
                        {state.name}
                      </Option>
                    );
                  })}
                </Select>
              </>
            )}
            {issueInput.scopeOfIssue === "local" && (
              <>
                <b>Townhalls</b>{" "}
                <Tooltip
                  placement="right"
                  title={`Choose localities where this ${mainText} will be suited`}
                >
                  <QuestionCircleFilled />
                </Tooltip>
                <Input
                  className="post-suggestion-input"
                  value={issueInput.townhalls}
                  onChange={handleTownHallsInput}
                />
              </>
            )}
          </div>
          <div>
            <b>Description text</b>
            <TextArea
              rows={8}
              className="post-suggestion-input"
              value={issueInput.description}
              onChange={handleDescriptionTextInput}
            />
          </div>
        </div>
        <div className="post-suggestion-side">
          {!isTabletOrMobile && (
            <div className="post-suggestion-button">
              <Button
                size="large"
                type="primary"
                onClick={handlePostProposalClick}
                disabled={isPostButtonDisabled}
              >
                {isPostButtonDisabled ? (
                  <Spin indicator={loadingIcon} />
                ) : (
                  <span>Post this {mainText}</span>
                )}
              </Button>
            </div>
          )}
          <div className="post-suggestion-fit-content">
            <b>Attachments</b>{" "}
            <Tooltip
              placement="right"
              title={`Add attachments that will support this ${mainText}`}
            >
              <QuestionCircleFilled />
            </Tooltip>
            <div className="post-suggestion-attachments">
              <Row>
                {issueInput.attachments.map(
                  (attachment: Attachment, index: number) => (
                    <Col span="sm" key={`attachment_suggestion_${index}`}>
                      <div className="post-suggestion-attachment">
                        <a
                          className="post-suggestion-green"
                          href={attachment.url}
                        >
                          <PaperClipOutlined />
                          {attachment.name}
                        </a>
                        <label
                          className="post-suggestion-red post-suggestion-delete"
                          onClick={() => {
                            let array = [...issueInput.attachments];
                            array.splice(index, 1);
                            setIssueInput({
                              ...issueInput,
                              attachments: array,
                            });
                          }}
                        >
                          Delete
                        </label>
                      </div>
                    </Col>
                  )
                )}
              </Row>
              <Tooltip placement="right" title="Add an attachment">
                <label onClick={handleAddAttachment}>
                  <PlusCircleOutlined style={{ fontSize: "xx-large" }} />
                </label>
                <input
                  type="file"
                  ref={hiddenAttachmentInput}
                  onChange={handleAttachmentChange}
                  style={{ display: "none" }}
                />
              </Tooltip>
            </div>
          </div>
          <div className="post-suggestion-fit-content">
            <b>Tags</b>{" "}
            <Tooltip
              placement="right"
              title={`Add topics that relate to this ${mainText}`}
            >
              <QuestionCircleFilled />
            </Tooltip>
            <EditableTagGroup
              tagGrouped={tagGrouped}
              setTagGroup={setTagGroup}
            />
            <br />
            <Row gutter={[10, 10]}>
              <Col span="sm">
                <p>Suggestions:</p>
              </Col>
              {defaultTags.map((tag: string, index) => (
                <Col span="sm" key={`tag_suggestion_${index}`}>
                  <p className="post-suggestion-green">{tag}</p>
                </Col>
              ))}
            </Row>
          </div>
          {isTabletOrMobile && (
            <div className="post-suggestion-button">
              <Button
                size="large"
                type="primary"
                onClick={handlePostProposalClick}
                disabled={isPostButtonDisabled}
              >
                {isPostButtonDisabled ? (
                  <Spin indicator={loadingIcon} />
                ) : (
                  <span>Post this suggestion</span>
                )}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch: any, _: any) => ({
  dispatch: dispatch,
});
const mapStateToProps = (state: any, _: any) => ({
  CommonData: state.CommonData,
  LocalityData: state.LocalityData,
});
export default connect(mapStateToProps, mapDispatchToProps)(PostSuggestionPage);
