import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Input, message, Modal, Spin, Table, Tag } from "antd";
import {
  LoadingOutlined,
  EditFilled,
  DeleteFilled,
  ExclamationCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import DateWithCalendar from "../../components/dashboard_page_components/date_with_calendar/date_with_calendar";
import { connect } from "react-redux";
import "./manage_voting_page.css";
import {
  getIssuesData,
  removeIssueData,
} from "../../services/redux/reducers/issue_data_service";
import Issue from "../../models/Issue";

const { confirm } = Modal;
const antIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

const ManageVotingPage = (props: any) => {
  const history = useHistory();
  const { IssueData, dispatch } = props;
  const { issue_data_list } = IssueData;
  const [searchText, setSearchText] = useState("");
  const votingList = issue_data_list as Issue[];
  const [votes, setVotes] = useState(votingList);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getIssuesData(dispatch, (votes: Issue[]) => {
      setIsLoading(false);
      setVotes(votes);
    });
  }, [dispatch]);

  const handleEditVote = (id: string) => {
    history.push(`/voting-detail/${id}`);
  };

  const handleRemoveVote = (id: string) => {
    const vote = votes.find((vote: Issue) => vote.id === id);
    confirm({
      title: `Are you sure you want to remove ${vote?.title ?? ""}?`,
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        removeIssueData(id, (err) => {
          if (err != null) {
            message.error(err.message);
          } else {
            message.success(`Successfully removed ${vote?.title ?? ""}`);
          }
        });
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: 1,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Vote Target",
      dataIndex: "voteTarget",
      key: "voteTarget",
      render: (voteTarget: number, record: Issue) => (
        <Tag
          color={
            record.yeses && record.yeses.length >= voteTarget ? "green" : "red"
          }
        >
          {voteTarget}
        </Tag>
      ),
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      render: (time: number) => <DateWithCalendar date={time} size={"14px"} />,
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "action",
      render: (id: string) => (
        <div style={{ display: "flex", gap: "18px" }}>
          <Button
            type="text"
            icon={<EditFilled />}
            onClick={(e: any) => {
              e.preventDefault();
              handleEditVote(id);
            }}
          />
          <Button
            type="text"
            icon={<DeleteFilled />}
            onClick={(e: any) => {
              e.preventDefault();
              handleRemoveVote(id);
            }}
          />
        </div>
      ),
    },
  ];
  return (
    <div>
      <h3>Manage Voting</h3>
      <Input
        className="manage-vote-input"
        prefix={<SearchOutlined />}
        value={searchText}
        onChange={(e: ChangeEvent<{ value: string }>) => {
          const value = e.target.value;
          setSearchText(value);
          setVotes(
            votingList.filter(
              (vote: Issue) =>
                vote.title.includes(value) ||
                vote.description.includes(value) ||
                value === ""
            )
          );
        }}
      />

      {isLoading ? (
        <Spin
          indicator={antIcon}
          style={{ display: "flex", justifyContent: "center" }}
        />
      ) : (
        <Table
          dataSource={(votes??[]).map((vote: any) => {
            const { description, ...restOfVote } = vote;
            return { ...restOfVote };
          })}
          columns={columns}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: any, _: any) => ({
  dispatch: dispatch,
});
const mapStateToProps = (state: any, _: any) => ({
  IssueData: state.IssueData,
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageVotingPage);
