import React from "react";
import { Progress } from "antd";
import "./custom_progress.css";
import ProgressItem from "../../../models/ProgressItem";

type CustomProgressProps = {
  progressItems: ProgressItem[];
  successColor: string;
  progressSize?: number;
};

const CustomProgress = (props: CustomProgressProps) => {
  const highestPercentageProgressItem: ProgressItem = props.progressItems.sort(
    (a, b) => a.percentage - b.percentage
  )[props.progressItems.length - 1];
  return (
    <React.Fragment>
      {props.progressItems
        .sort((a, b) => a.order - b.order)
        .map((progressItem, index) => {
          return (
            <React.Fragment key={`progress_${index}`}>
              <b>{progressItem.label}</b>
              <br />
              <Progress
                percent={progressItem.percentage}
                size="small"
                trailColor="#959595"
                strokeColor={props.successColor}
                width={props.progressSize ? props.progressSize : 180}
                format={(percent) => {
                  return highestPercentageProgressItem.percentage ===
                    percent ? (
                    <span style={{ color: props.successColor }}>
                      {percent}%
                    </span>
                  ) : (
                    `${percent}%`
                  );
                }}
              />
            </React.Fragment>
          );
        })}
    </React.Fragment>
  );
};

export default CustomProgress;
