import Issue from "../../../models/Issue";
import {
  addFirebaseDataById,
  getFirebaseData,
  getFirebaseDataByMatcher,
  getFirebaseSingleData,
  removeFirebaseData,
} from "../../firebaseService";
import * as actionTypes from "../actionType";

const UpdateIssueData = (data: Issue[], dispatch: any) => {
  dispatch({
    type: actionTypes.UpdateIssueData,
    data: data,
  });
};

export const getIssuesData = (
  dispatch: any,
  callback: (issues: Issue[]) => void = () => {}
) => {
  getFirebaseData("issues", (issueData: Issue[]) => {
    const issues = issueData;
    if (issues !== null) {
      UpdateIssueData(issues, dispatch);
      callback(issues);
    }
  });
};

export const getIssuesDataByUid = (
  dispatch: any,
  uid?: string,
  callback: (issues: Issue[]) => void = () => {}
) => {
  getFirebaseDataByMatcher("issues", "uid", uid, (issueData: Issue[]) => {
    const issues = issueData;
    if (issues !== null) {
      UpdateIssueData(issues, dispatch);
      callback(issues);
    }
  });
};

export const removeIssueData = (
  id: string,
  callback: (err: Error | null) => void
) => {
  removeFirebaseData(`/issues/${id}`, callback);
};

export const updateVoteData = (
  data: Issue,
  callback: (err: Error | null) => void
) => {
  addFirebaseDataById("issues", data, callback);
};

export const getIssueData = (
  id: string,
  callback: (error?: any) => void = () => {}
) => {
  getFirebaseSingleData("issues", "id", id, (result: any) => {
    const issue = result as Issue;
    if (issue) {
      callback(issue);
    } else {
      callback(undefined);
    }
  });
};

const reducer = (state: any = { issue_data_list: [] }, action: any) => {
  switch (action.type) {
    case actionTypes.UpdateIssueData:
      return {
        ...state,
        issue_data_list: action.data,
      };
    default:
      return state;
  }
};
export default reducer;
