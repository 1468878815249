import React, { ChangeEvent, useState } from "react";
import BackLink from "../../../components/common/back_link/back_link";
import DetailHeading from "../../../components/common/detail_heading/detail_heading";
import { connect } from "react-redux";
import { Button, Form, Input, message } from "antd";
import "./notification_detail_page.css";
import ParamTypes from "../../../models/ParamTypes";
import { useHistory, useParams } from "react-router-dom";
import Notification from "../../../models/Notification";
import { updateNotificationData } from "../../../services/redux/reducers/notification_data_service";

const layout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 2, span: 12 },
};

const NotificationDetailPage = (props: any) => {
  const history = useHistory();
  let { id } = useParams<ParamTypes>();
  const { NotificationData } = props;
  const { notification_data_list } = NotificationData;
  const currentNotification = notification_data_list.find(
    (locality: Notification) => locality.id === id
  );
  const [notification, setNotification] = useState<Notification>(
    currentNotification
  );

  const handleNotificationChange = (notification: Notification) => {
    setNotification(notification);
  };
  const onFinish = (values: any) => {
    message.success("Success:", values);
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error("Failed:", errorInfo);
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    if (!notification.title) {
      message.error("Please enter a title");
      return;
    }
    if (!notification.description) {
      message.error("Please select a description");
      return;
    }
    if (!notification.link) {
      message.error("Please select a link");
      return;
    }
    updateNotificationData(notification, (err) => {
      if (err) {
        message.error(err.message);
      } else {
        history.push("/manage-notifications");
      }
    });
  };

  return (
    <div>
      <BackLink link="/manage-notifications" />
      <DetailHeading
        heading="Notification details"
        subHeading="Showing Details for a notification"
        style={{ marginBottom: "48px" }}
      />
      <div>
        <Form
          {...layout}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="title"
            label="Title"
            initialValue={notification.title}
            rules={[{ required: true, message: "Please enter a title" }]}
          >
            <Input
              value={notification.title}
              onChange={(e: ChangeEvent<{ value: string }>) => {
                handleNotificationChange({
                  ...notification,
                  title: e.target.value,
                });
              }}
            />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            initialValue={notification.description}
            rules={[{ required: true, message: "Please enter a description" }]}
          >
            <Input
              value={notification.description}
              onChange={(e: ChangeEvent<{ value: string }>) => {
                handleNotificationChange({
                  ...notification,
                  description: e.target.value,
                });
              }}
            />
          </Form.Item>
          <Form.Item
            name="link"
            label="Link"
            initialValue={notification.link}
            rules={[{ required: true, message: "Please enter a link" }]}
          >
            <Input
              value={notification.link}
              onChange={(e: ChangeEvent<{ value: string }>) => {
                handleNotificationChange({
                  ...notification,
                  link: e.target.value,
                });
              }}
            />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <div className="notification-detail-buttons">
              <Button
                size="large"
                type="default"
                onClick={(e: any) => history.push("/manage-notifications")}
              >
                CANCEL
              </Button>
              <Button
                size="large"
                type="primary"
                onClick={handleSave}
                disabled={currentNotification === notification}
              >
                SAVE
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any, _: any) => ({
  dispatch: dispatch,
});

const mapStateToProps = (state: any, _: any) => ({
  NotificationData: state.NotificationData,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationDetailPage);
