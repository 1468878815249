import React, { useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import {
  EnvironmentOutlined,
  PaperClipOutlined,
  EyeFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import BackLink from "../../components/common/back_link/back_link";
import DetailHeading from "../../components/common/detail_heading/detail_heading";
import { Button, message, Spin } from "antd";
import DateWithCalendar from "../../components/dashboard_page_components/date_with_calendar/date_with_calendar";
import { connect } from "react-redux";
import "./issue_detail_page.css";
import Issue from "../../models/Issue";
import {
  addFirebaseData,
  setFirebaseData,
} from "../../services/firebaseService";
import { AuthContext } from "../../services/Auth";
import ParamTypes from "../../models/ParamTypes";
import VotingResultsGraph from "../../components/voting_results_graphs/voting_results_graphs";
import { DataEntry } from "react-minimal-pie-chart/types/commonTypes";
import { getIssueData } from "../../services/redux/reducers/issue_data_service";
import {
  buildVotingContract,
  voteAgainstIssue,
  voteForIssue,
} from "../../services/contractService";
import VerifiedVote from "../../models/VerifiedVote";
import { useMediaQuery } from "react-responsive";
import { YesNoType } from "../../constants/enums";
import { v4 as uuidv4 } from "uuid";
const loadingIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "black" }} spin />
);
const IssueDetailPage = (props: any) => {
  const currentUser = useContext(AuthContext);
  let { id } = useParams<ParamTypes>();
  const history = useHistory();
  const { dispatch } = props;
  const [issue, setIssue] = useState<Issue | undefined>(undefined);
  const [isVoting, setIsVoting] = useState(false);

  useEffect(() => {
    getIssueData(id, (issue) => {
      setIssue(issue);
    });

    if (currentUser?.uid) {
      const previousIssueWatches = issue?.watches
        ? issue.watches.filter((w: string) => w !== currentUser?.uid)
        : [];
      setFirebaseData(
        `issues/${id}/watches`,
        [...previousIssueWatches, currentUser?.uid],
        (err) => {
          if (err) {
            console.log(err);
            message.error(err.message);
          }
        }
      );
    }
    // eslint-disable-next-line
  }, [dispatch, id, currentUser?.uid]);

  const watches: number = issue?.watches?.length ?? 0;

  const graphData: DataEntry[] = [
    {
      title: "Voted in favor",
      value: issue?.yeses?.length ?? 0,
      color: "#04C139",
    },
    {
      title: "Voted Against",
      value: issue?.noes?.length ?? 0,
      color: "#FF6B6B",
    },
  ];

  const updateYeses = (
    uid: string | null = null,
    callback: () => void = () => {}
  ) => {
    if (uid) {
      const previousIssueYeses: string[] = issue?.yeses
        ? issue.yeses.filter((l: string) => l !== currentUser?.uid)
        : [];
      setFirebaseData(
        `issues/${id}/yeses`,
        [...previousIssueYeses, uid],
        (err) => {
          if (err) {
            message.error(err.message);
          } else {
            callback();
          }
        }
      );
    }
  };

  const updateNoes = (
    uid: string | null = null,
    callback: () => void = () => {}
  ) => {
    if (uid) {
      const previousIssueNoes: string[] = issue?.noes
        ? issue.noes.filter((d: string) => d !== currentUser?.uid)
        : [];
      setFirebaseData(
        `issues/${id}/noes`,
        [...previousIssueNoes, uid],
        (err) => {
          if (err) {
            message.error(err.message);
          } else {
            callback();
          }
        }
      );
    }
  };

  const onClickYes = async (event: any) => {
    event.preventDefault();
    if (currentUser && issue && issue?.contractAddress) {
      setIsVoting(true);
      const { web3, votingContract } = await buildVotingContract();
      votingContract.options.address = issue?.contractAddress;
      voteForIssue(web3, votingContract, issue.id, currentUser?.uid)
        .then((result) => {
          const verifiedVote: VerifiedVote = {
            id: uuidv4(),
            uid: currentUser?.uid,
            issueId: issue.id,
            transactionHash: result.transactionHash,
            blockNumber: result.blockNumber,
            status: result.status ? "success" : "error",
            created: Date.now(),
            decision: YesNoType.YES,
          };
          addFirebaseData(
            "verifiedVotes",
            verifiedVote,
            (err: Error | null) => {
              if (err === null) {
                updateYeses(currentUser?.uid, () => {
                  message.success("Vote captured, thank you!");
                  setIsVoting(false);
                  history.push("/issues");
                });
              } else {
                message.error(
                  "Voting unavailable. Please contact tech support"
                );
              }
            }
          );
        })
        .catch((err: Error) => {
          if (err.message.includes("Voter cannot vote again on issue")) {
            message.error("You voted already");
          } else {
            message.error("Voting unavailable. Please contact tech support");
          }
        });
    } else {
      message.error("Voting unavailable. Please contact tech support");
    }
  };
  const onClickNo = async (event: any) => {
    event.preventDefault();
    if (currentUser && issue && issue?.contractAddress) {
      setIsVoting(true);
      const { web3, votingContract } = await buildVotingContract();
      votingContract.options.address = issue?.contractAddress;
      voteAgainstIssue(web3, votingContract, issue.id, currentUser?.uid)
        .then((result) => {
          const verifiedVote: VerifiedVote = {
            id: "",
            uid: currentUser?.uid,
            issueId: issue.id,
            transactionHash: result.transactionHash,
            blockNumber: result.blockNumber,
            status: result.status ? "success" : "error",
            created: Date.now(),
            decision: YesNoType.NO,
          };
          addFirebaseData(
            "verifiedVotes",
            verifiedVote,
            (err: Error | null) => {
              if (err === null) {
                updateNoes(currentUser?.uid, () => {
                  message.success("Vote captured, thank you!");
                  setIsVoting(false);
                  history.push("/issues");
                });
              } else {
                message.error(
                  "Voting unavailable. Please contact tech support"
                );
              }
            }
          );
        })
        .catch((err) => {
          if (err.message.includes("Voter cannot vote again on issue")) {
            message.error("You voted already");
          } else {
            message.error("Voting unavailable. Please contact tech support");
          }
        });
    } else {
      message.error("Voting unavailable. Please contact tech support");
    }
  };
  const hasVoted =
    ((issue?.yeses && issue.yeses.some((yes) => yes === currentUser?.uid)) ||
      (issue?.noes && issue.noes.some((no) => no === currentUser?.uid))) ??
    false;
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const heading = { heading: "All Issues" };
  return (
    <div>
      {isTabletOrMobile ? (
        <BackLink link="/issues" {...heading} />
      ) : (
        <>
          <BackLink link="/issues" />
          <DetailHeading {...heading} subHeading="Showing Details for Issue" />
        </>
      )}
      <div className="issue-detail-main">
        <div className="issue-detail-center">
          <img
            src={issue?.coverImage}
            className="issue-detail-image"
            alt="issue"
          ></img>
          <h3>{issue?.title}</h3>
          <div className="issue-detail-info">
            <div>
              <b>Posted by</b>
              <p className="grey-text">
                {issue?.postedBy} on{" "}
                <DateWithCalendar date={issue?.created} size={14} />
              </p>
            </div>
            {issue?.scopeOfIssue === "local" && (
              <div>
                <b>Concerning Localities</b>
                <p>
                  <EnvironmentOutlined />
                  <span className="grey-text">{issue?.locality}</span>
                </p>
              </div>
            )}
            {issue?.scopeOfIssue === "state" && (
              <div>
                <b>State</b>
                <p>
                  <EnvironmentOutlined />
                  <span className="grey-text">{issue?.state}</span>
                </p>
              </div>
            )}
            {issue?.scopeOfIssue && (
              <div>
                <b>Scope of Issue</b>
                <p>
                  <span className="grey-text">{issue?.scopeOfIssue}</span>
                </p>
              </div>
            )}
            <div>
              <b>Attachments</b>
              <p>
                <PaperClipOutlined />
                {issue?.attachments &&
                  issue?.attachments.map((attachment, index) => {
                    return (
                      <a
                        key={`attachment_link_${index}`}
                        href={attachment.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="green-text">
                          {attachment.name}{" "}
                          {issue?.attachments.length - 1 === index ? "" : ", "}
                        </span>
                      </a>
                    );
                  })}
              </p>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <b>Description</b>
            <p>{issue?.description}</p>
          </div>
        </div>
        <div className="issue-detail-side">
          <div className="issue-detail-block">
            <div className="issue-detail-opinion-box">
              <img src="/assets/images/vote.svg" alt="your opinion"></img>
              <b>
                {hasVoted && <span>Your vote has been casted</span>}
                {!hasVoted && <span>Cast your vote on this issue</span>}
              </b>
              <div className="issue-detail-buttons">
                {!hasVoted &&
                  (isVoting ? (
                    <Spin indicator={loadingIcon} />
                  ) : (
                    <>
                      <Button type="primary" size="large" onClick={onClickYes}>
                        Yes
                      </Button>
                      <Button type="primary" size="large" onClick={onClickNo}>
                        No
                      </Button>{" "}
                    </>
                  ))}
              </div>
            </div>
          </div>
          <div className="issue-detail-block">
            <div>
              <h6>Statistics</h6>
              <EyeFilled
                className="issue-detail-icon-color"
                style={{ fontSize: "24px" }}
              />{" "}
              <b className="issue-detail-text">{watches}</b>
            </div>
            <div>
              <h6>Voting Results</h6>
              <VotingResultsGraph data={graphData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any, _: any) => ({
  dispatch: dispatch,
});
const mapStateToProps = (state: any, _: any) => ({
  IssueData: state.IssueData,
  UserData: state.UserData,
});
export default connect(mapStateToProps, mapDispatchToProps)(IssueDetailPage);
