import VerifiedVote from "../../../models/VerifiedVote";
import {
  addFirebaseDataById,
  getFirebaseData,
  getFirebaseDataByMatcher,
  getFirebaseSingleData,
  removeFirebaseData,
} from "../../firebaseService";
import * as actionTypes from "../actionType";

const UpdateVerifiedVoteData = (data: VerifiedVote[], dispatch: any) => {
  dispatch({
    type: actionTypes.UpdateVerifiedVoteData,
    data: data,
  });
};

export const getVerifiedVotesData = (
  dispatch: any,
  callback: (verifiedVotes: VerifiedVote[]) => void = () => {}
) => {
  getFirebaseData("verifiedVotes", (verifiedVote: VerifiedVote[]) => {
    const verifiedVotes = verifiedVote;
    if (verifiedVotes !== null) {
      UpdateVerifiedVoteData(verifiedVotes, dispatch);
      callback(verifiedVotes);
    }
  });
};

export const getVerifiedVotesDataByUid = (
  dispatch: any,
  uid?: string,
  callback: (verifiedVotes: VerifiedVote[]) => void = () => {}
) => {
  getFirebaseDataByMatcher(
    "verifiedVotes",
    "uid",
    uid,
    (verifiedVote: VerifiedVote[]) => {
      const verifiedVotes = verifiedVote;
      if (verifiedVotes !== null) {
        UpdateVerifiedVoteData(verifiedVotes, dispatch);
        callback(verifiedVotes);
      }
    }
  );
};

export const removeVerifiedVoteData = (
  id: string,
  callback: (err: Error | null) => void
) => {
  removeFirebaseData(`/verifiedVotes/${id}`, callback);
};

export const updateVerifiedVoteData = (
  data: VerifiedVote,
  callback: (err: Error | null) => void
) => {
  addFirebaseDataById("verifiedVotes", data, callback);
};

export const getVerifiedVoteData = (
  id: string,
  callback: (error?: any) => void = () => {}
) => {
  getFirebaseSingleData("verifiedVotes", "id", id, (result: any) => {
    const verifiedVote = result as VerifiedVote;
    if (verifiedVote) {
      callback(verifiedVote);
    } else {
      callback(result);
    }
  });
};

const reducer = (state: any = { verifiedVote_data_list: [] }, action: any) => {
  switch (action.type) {
    case actionTypes.UpdateVerifiedVoteData:
      return {
        ...state,
        verifiedVote_data_list: action.data,
      };
    default:
      return state;
  }
};
export default reducer;
