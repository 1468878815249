import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import OneTypeVotingWidget from "./one_type_voting_widget/one_type_voting_widget";
import "./voting_page.css";
import { connect } from "react-redux";
import { VOTE_TYPE } from "../../constants/enums";
import { Row, Col } from "antd";
import Issue from "../../models/Issue";
import { getIssuesData } from "../../services/redux/reducers/issue_data_service";
const { TabPane } = Tabs;

const VotingPage = (props: any) => {
  const { IssueData, dispatch } = props;

  const [selVoteType, setSelVoteType] = useState(VOTE_TYPE.mandates);
  const history = useHistory();

  useEffect(() => {
    getIssuesData(dispatch);
  }, [dispatch]);

  const { issue_data_list } = IssueData || {};

  const onChangeTab = (key: string) => {
    const keyValue: number = parseInt(key);
    if (keyValue !== selVoteType) {
      setSelVoteType(keyValue);
    }
  };
  const onClickOneVote = (voteId: string, isClosed: boolean) => {
    if (isClosed) {
      history.push(`/results/${voteId}`);
    } else {
      history.push(`/vote-detail/${voteId}`);
    }
  };
  // eslint-disable-next-line
  const voteList = (issue_data_list || []).filter(
    (x: Issue) => x.votingType === selVoteType
  );
  const voteUIList = voteList.map((voteInfo: Issue, index: number) => (
    <Col span="sm" key={`voting_page_${selVoteType}_${index}`}>
      <OneTypeVotingWidget
        voteInfo={voteInfo}
        onClickOneVote={onClickOneVote}
      />
    </Col>
  ));
  return (
    <React.Fragment>
      <h3>Voting In Progress</h3>
      <div className="voting-page-container">
        <Tabs
          defaultActiveKey={VOTE_TYPE.mandates.toString()}
          onChange={onChangeTab}
        >
          <TabPane
            className="voting-page-tab-content-container"
            tab="Voting on Mandates"
            key={VOTE_TYPE.mandates}
          >
            <Row style={{ marginTop: 50 }} gutter={[32, 32]}>
              {voteUIList}
            </Row>
          </TabPane>
        </Tabs>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch: any, _: any) => ({
  dispatch: dispatch,
});
const mapStateToProps = (state: any, _: any) => ({
  IssueData: state.IssueData,
});
export default connect(mapStateToProps, mapDispatchToProps)(VotingPage);
