import Feedback from "../../../models/Feedback";
import {
  addFirebaseDataById,
  getFirebaseData,
  getFirebaseDataByMatcher,
  getFirebaseSingleData,
  removeFirebaseData,
} from "../../firebaseService";
import * as actionTypes from "../actionType";

const UpdateFeedbackData = (data: Feedback[], dispatch: any) => {
  dispatch({
    type: actionTypes.UpdateFeedbackData,
    data: data,
  });
};

export const getFeedbacksData = (
  dispatch: any,
  callback: (feedbacks: Feedback[]) => void = () => {}
) => {
  getFirebaseData("feedbacks", (feedbackData: Feedback[]) => {
    const feedbacks = feedbackData;
    if (feedbacks !== null) {
      UpdateFeedbackData(feedbacks, dispatch);
      callback(feedbacks);
    }
  });
};

export const getFeedbacksDataByUid = (
  dispatch: any,
  uid?: string,
  callback: (feedbacks: Feedback[]) => void = () => {}
) => {
  getFirebaseDataByMatcher("feedbacks", 'uid', uid, (feedbackData: Feedback[]) => {
    const feedbacks = feedbackData;
    if (feedbacks !== null) {
      UpdateFeedbackData(feedbacks, dispatch);
      callback(feedbacks);
    }
  });
};

export const removeFeedbackData = (
  id: string,
  callback: (err: Error | null) => void
) => {
  removeFirebaseData(`/feedbacks/${id}`, callback);
};

export const updateFeedbackData = (
  data: Feedback,
  callback: (err: Error | null) => void
) => {
  addFirebaseDataById("feedbacks", data, callback);
};

export const getFeedbackData = (
  id: string,
  callback: (error?: any) => void = () => {}
) => {
  getFirebaseSingleData("feedbacks", "id", id, (result: any) => {
    const feedback = result as Feedback;
    if (feedback) {
      callback(feedback);
    } else {
      callback(result);
    }
  });
};

const reducer = (state: any = { feedback_data_list: [] }, action: any) => {
  switch (action.type) {
    case actionTypes.UpdateFeedbackData:
      return {
        ...state,
        feedback_data_list: action.data,
      };
    default:
      return state;
  }
};
export default reducer;
