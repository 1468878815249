import React from "react";
import { Modal } from "antd";
import { SocialIcon } from "react-social-icons";

type ShareButtonsModalProps = {
  show: boolean;
  onHide: () => void;
  linkToShare: string;
  source: string;
};

const ShareButtonsModal = (props: ShareButtonsModalProps) => {
  const { show, onHide, linkToShare, source } = props;
  const encodedLinkToShare = encodeURI(linkToShare);
  const encodedSource = encodeURI(source);
  return (
    <Modal
      title="Share Results"
      visible={show}
      onCancel={onHide}
      footer={null}
      focusTriggerAfterClose={false}
    >
      <div style={{ display: "flex", gap: "1em", justifyContent: "center"}}>
        <div
          className="fb-share-button"
          data-href={encodedLinkToShare}
          data-layout="button_count"
          data-size="large"
        >
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodedLinkToShare}&amp;src=${encodedSource}`}
            className="fb-xfbml-parse-ignore"
          >
            <SocialIcon network="facebook" />
          </a>
        </div>

        <a
          href={`https://twitter.com/share?ref_src=${encodedSource}`}
          className="twitter-share-button"
          data-size="large"
          data-url={encodedLinkToShare}
          data-show-count="false"
        >
          <SocialIcon network="twitter" />
        </a>
      </div>
    </Modal>
  );
};

export default ShareButtonsModal;
