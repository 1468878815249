import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import "./manage_users_page.css";
import { Button, Input, Table, Tag, Modal, message } from "antd";
import { connect } from "react-redux";
import {
  getUsersData,
  removeUserData,
} from "../../services/redux/reducers/user_data_service";
import User from "../../models/User";
import { RoleType } from "../../constants/enums";
import { SearchOutlined } from "@ant-design/icons";
import { AuthContext } from "../../services/Auth";
import { Spin } from "antd";
import {
  LoadingOutlined,
  EditFilled,
  DeleteFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import DateWithCalendar from "../../components/dashboard_page_components/date_with_calendar/date_with_calendar";
const { confirm } = Modal;
const loadingIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

const ManageUsersPage = (props: any) => {
  const history = useHistory();
  const { UserData, dispatch } = props;
  const { user_data_list } = UserData;
  const currentUser = useContext(AuthContext);
  const [searchText, setSearchText] = useState("");
  const filteredUsers = user_data_list.filter(
    (user: User) => user.uid !== currentUser?.uid
  ) as User[];
  const [users, setUsers] = useState(filteredUsers);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getUsersData(dispatch, (users: User[]) => {
      setIsLoading(false);
      setUsers(users.filter((user: User) => user.uid !== currentUser?.uid));
    });
  }, [dispatch, currentUser]);

  const handleEditUser = (id: string) => {
    history.push(`/user-detail/${id}`);
  };

  const handleRemoveUser = (id: string) => {
    const user = users.find((user) => user.uid === id);
    confirm({
      title: `Are you sure you want to remove ${user?.email ?? ""}?`,
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        removeUserData(id, (err) => {
          if (err != null) {
            message.error(err.message);
          } else {
            message.success(`Successfully removed ${user?.name ?? ""}`);
          }
        });
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: "User Id",
      dataIndex: "uid",
      key: "uid",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Date of Birth",
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
      render: (date: number) => <DateWithCalendar date={date} size={"14px"} />,
    },
    {
      title: "Phone number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (role: string) => (
        <Tag color={role === RoleType.USER ? "green" : "blue"}>{role}</Tag>
      ),
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      render: (time: number) => <DateWithCalendar date={time} size={"14px"} />,
    },
    {
      title: "Actions",
      dataIndex: "uid",
      key: "uid",
      render: (id: string) => (
        <div style={{ display: "flex", gap: "18px" }}>
          <Button
            type="text"
            icon={<EditFilled />}
            onClick={(e: any) => {
              e.preventDefault();
              handleEditUser(id);
            }}
          />
          <Button
            type="text"
            icon={<DeleteFilled />}
            onClick={(e: any) => {
              e.preventDefault();
              handleRemoveUser(id);
            }}
          />
        </div>
      ),
    },
  ];
  return (
    <div>
      <h3>Manage Users</h3>
      <Input
        className="manage-users-input"
        prefix={<SearchOutlined />}
        value={searchText}
        onChange={(e: ChangeEvent<{ value: string }>) => {
          const value = e.target.value;
          setSearchText(value);
          setUsers(
            filteredUsers.filter(
              (user: User) =>
                user.name.includes(value) ||
                user.phoneNumber.includes(value) ||
                user.role.includes(value) ||
                value === ""
            )
          );
        }}
      />

      {isLoading ? (
        <Spin
          indicator={loadingIcon}
          style={{ display: "flex", justifyContent: "center" }}
        />
      ) : (
        <Table dataSource={users} columns={columns} />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: any, _: any) => ({
  dispatch: dispatch,
});
const mapStateToProps = (state: any, _: any) => ({
  UserData: state.UserData,
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageUsersPage);
