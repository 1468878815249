import firebase from "firebase";
import React, { useEffect, useState } from "react";
import { firebaseService } from "./firebaseService";
export const AuthContext = React.createContext<
  firebase.User | null | undefined
>(null);

export const AuthProvider = ({ children }: any) => {
  const auth = firebaseService.auth();
  const [currentUser, setCurrentUser] = useState<
    firebase.User | null | undefined
  >(undefined);
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });
  }, [auth]);

  return (
    <AuthContext.Provider value={currentUser}>{children}</AuthContext.Provider>
  );
};
