import User from "../../../models/User";
import {
  addFirebaseDataByUid,
  getFirebaseData,
  getFirebaseSingleData,
  removeFirebaseData,
} from "../../firebaseService";
import * as actionTypes from "../actionType";
const initialState = {
  user_data_list: [],
};

const UpdateUsersData = (data: User[], dispatch: any) => {
  dispatch({
    type: actionTypes.UpdateUserListData,
    data: data,
  });
};

export const getUsersData = (
  dispatch: any,
  callback: (user: User[]) => void = () => {}
) => {
  getFirebaseData("users", (userData: User[]) => {
    if (userData != null) {
      callback(userData);
      UpdateUsersData(userData, dispatch);
    }
  });
};

export const getSingleUserData = (
  id: string,
  callback: (error?: any) => void = () => {}
) => {
  getFirebaseSingleData("users", "uid", id, (result: any) => {
    const user = result as User;
    if (user) {
      callback(user);
    } else {
      callback(user);
    }
  });
};

export const removeUserData = (
  id: string,
  callback: (err: Error | null) => void
) => {
  removeFirebaseData(`/users/${id}`, callback);
};

export const updateUserData = (
  data: User,
  callback: (err: Error | null) => void
) => {
  addFirebaseDataByUid("users", data, callback);
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.UpdateUserListData:
      return {
        ...state,
        user_data_list: action.data,
      };
    default:
      return state;
  }
};
export default reducer;
