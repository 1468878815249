import React from "react";
import { PieChart } from "react-minimal-pie-chart";
import { Data } from "react-minimal-pie-chart/types/commonTypes";
import "./voting_results_graphs.css";

interface VotingResultsGraphProps {
  data: Data;
}

interface PieChartLegendProps {
  color: string;
  title?: string | number | undefined;
}

const PieChartLegend = (props: PieChartLegendProps) => {
  return (
    <div style={{ display: "flex", gap: "10px", alignItems: "baseline" }}>
      <div
        style={{
          backgroundColor: props.color,
          borderRadius: "2px",
          transform: "rotate(45deg)",
          width: "9px",
          height: "9px",
        }}
      ></div>
      <label style={{ color: "#929292" }}>{props.title}</label>
    </div>
  );
};

const VotingResultsGraph = (props: VotingResultsGraphProps) => {
  return (
    <div className="voting-results-graph">
      <div>
        {props.data.map((entry, index: number) => (
          <PieChartLegend
            key={`pie_${index}`}
            color={entry.color}
            title={entry.title}
          />
        ))}
      </div>
      <PieChart
        data={props.data}
        lineWidth={70}
        label={({ dataEntry }) => `${Math.round(dataEntry.percentage)}%`}
        labelStyle={{
          fill: "#ffff",
          fontSize: "10px",
          fontWeight: "bold",
        }}
        radius={50}
        paddingAngle={5}
        background="#fff"
      />
    </div>
  );
};

export default VotingResultsGraph;
