import React from 'react'
import './keyword_item.css'
function KeywordItem(props: any) {
    const { keyword } = props;
    return (
        <div className="one-keyword-item">
            {keyword}
        </div>
    )
}

export default KeywordItem
