import React, { useContext, useEffect } from "react";
import { Layout } from "antd";
import "./layout.css";
import MySidebar from "./Sidebar/mysidebar";
import MyHeader from "./header";
import { AuthContext } from "../../services/Auth";
import { getUserData } from "../../services/redux/reducers/commondata_service";
import { connect } from "react-redux";
import {
  getProposalsData,
  getProposalsDataByUid,
} from "../../services/redux/reducers/proposal_data_service";
import { getIssuesData } from "../../services/redux/reducers/issue_data_service";
import { useMediaQuery } from "react-responsive";
import BottomBar from "./mobile/BottomBar";

const { Content } = Layout;
function MyLayout(props: any) {
  const { dispatch, isAdmin } = props;
  const currentUser = useContext(AuthContext);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  useEffect(() => {
    if (currentUser) {
      getUserData(currentUser.uid, dispatch);
      isAdmin
        ? getProposalsData(dispatch)
        : getProposalsDataByUid(dispatch, currentUser.uid);
      getIssuesData(dispatch);
    }
  }, [currentUser, dispatch, isAdmin]);
  return (
    <React.Fragment>
      {isDesktopOrLaptop && <MySidebar />}
      {isDesktopOrLaptop && (
        <Layout className="layout-container">
          <>
            <MyHeader />
            <Content className="content-container">{props.children}</Content>
          </>
        </Layout>
      )}
      {isTabletOrMobile && (
        <>
          <MyHeader />
          <Content className="content-container">{props.children}</Content>
          <BottomBar />
        </>
      )}
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch: any, _: any) => ({
  dispatch: dispatch,
});

export default connect(null, mapDispatchToProps)(MyLayout);
