import { Col, Row } from "antd";
import React from "react";
import "./one_issue_widget.css";
import { Link } from "react-router-dom";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  EyeFilled,
} from "@ant-design/icons";

const OneIssueWidget = (props: any) => {
  const { issueInfo, detailUrl } = props;
  return (
    <div className="popular-one-issue-item">
      <Link to={detailUrl}>
        <img
          src={issueInfo.coverImage}
          className="popular-one-issue-image"
          alt={issueInfo.title}
        />
      </Link>
      <div className="popular-one-issue-title">
        <p className="popular-one-issue-title-text">{issueInfo.title}</p>
      </div>
      <div className="popular-one-issue-control-container">
        <Row>
          <Col span={8}>
            <div className="popular-one-issue-one-control">
              <EyeFilled style={{ fontSize: "24px" }} />

              <p style={{ fontSize: "20px" }}>
                {" "}
                {issueInfo.watches ? issueInfo.watches.length : 0}{" "}
              </p>
            </div>
          </Col>
          <Col span={8}>
            <div className="popular-one-issue-one-control">
              <CheckCircleFilled
                style={{ fontSize: "24px", color: "#00ba34" }}
              />
              <p style={{ fontSize: "20px" }}>
                {" "}
                {issueInfo.yeses ? issueInfo.yeses.length : 0}{" "}
              </p>
            </div>
          </Col>
          <Col span={8}>
            <div className="popular-one-issue-one-control">
              <CloseCircleFilled
                style={{ fontSize: "24px", color: "#ff6b6b" }}
              />
              <p style={{ fontSize: "20px" }}>
                {" "}
                {issueInfo.noes ? issueInfo.noes.length : 0}
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OneIssueWidget;
