export const VOTE_TYPE = {
    mandates: 0,
    issues: 1,
};

export const VOTE_STATUS = {
    started: 1,
    progress: 2,
    closed: 3
}

export const SETTINGS = {
    profile: 0,
}

export enum RoleType {
    USER = "user",
    ADMIN = "admin",
}

export enum YesNoType {
    YES = "yes",
    NO = "no",
}