import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import {
  PaperClipOutlined,
  EditFilled,
  PlusCircleOutlined,
  LoadingOutlined,
  ExclamationCircleOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import BackLink from "../../../components/common/back_link/back_link";
import DetailHeading from "../../../components/common/detail_heading/detail_heading";
import {
  Button,
  Col,
  Input,
  InputNumber,
  message,
  Modal,
  Popover,
  Row,
  Select,
  Spin,
  Steps,
} from "antd";
import DateWithCalendar from "../../../components/dashboard_page_components/date_with_calendar/date_with_calendar";
import "./voting_detail_page.css";
import {
  addFirebaseData,
  addFirebaseDataById,
  storage,
} from "../../../services/firebaseService";
import { v4 as uuidv4 } from "uuid";
import { AuthContext } from "../../../services/Auth";
import ParamTypes from "../../../models/ParamTypes";
import TextArea from "antd/lib/input/TextArea";
import { BasicAttachment, Attachment } from "../../../models/Attachment";
import Issue from "../../../models/Issue";
import { VOTE_STATUS, VOTE_TYPE } from "../../../constants/enums";
import Notification from "../../../models/Notification";
import VotingResultsGraph from "../../../components/voting_results_graphs/voting_results_graphs";
import { DataEntry } from "react-minimal-pie-chart/types/commonTypes";
import { connect } from "react-redux";
import StatusHouse from "../../../components/dashboard_page_components/status_house.css/status_house";
import { getIssueData } from "../../../services/redux/reducers/issue_data_service";
import State from "../../../models/State";
import stateData from "../../../states.json";
const { Option } = Select;
const { confirm } = Modal;
const { Step } = Steps;
const loadingIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
);
interface DisplayAttachment {
  url: string;
  name: string;
}

interface EditIssue {
  isTitleEditable: boolean;
  isDescriptionEditable: boolean;
  isLocalitiesEditable: boolean;
  isAttachmentsEditable: boolean;
  isTargetVotesEditable: boolean;
  isScopeOfIssueEditable: boolean;
  isStateEditable: boolean;
}

const VotingDetailPage = (props: any) => {
  const currentUser = useContext(AuthContext);
  let { id } = useParams<ParamTypes>();
  const history = useHistory();
  const { dispatch } = props;
  const [isPostButtonDisabled, setIsPostButtonDisabled] = useState(false);
  const [isMandateTextVisible, setIsMandateTextVisible] = useState(false);
  const [isExplanatoryMemorandaVisible, setIsExplanatoryMemorandaVisible] =
    useState(false);
  const [currentIssue, setCurrentIssue] = useState<Issue | undefined>(
    undefined
  );
  const [isClosed, setIsClosed] = useState(false);
  const states = stateData as State[];

  useEffect(() => {
    if (isClosed && currentIssue?.votingType === VOTE_TYPE.mandates) {
      addFirebaseData(
        "notifications",
        {
          title: `Voting Closed for ${currentIssue.title}`,
          link: `/vote-detail/${currentIssue.id}`,
          description: `It's being processed to be sent to congress`,
          created: Date.now(),
        } as Notification,
        () => {}
      );
      postAfterConfirmationIssue();
    }
    getIssueData(id, (issue) => {
      setCurrentIssue(issue);
    });
    // eslint-disable-next-line
  }, [dispatch, id]);

  const [currentEditIssue, setCurrentEditIssue] = useState({
    isTitleEditable: false,
    isDescriptionEditable: false,
    isLocalitiesEditable: false,
    isAttachmentsEditable: false,
    isTargetVotesEditable: false,
    isScopeOfIssueEditable: false,
    isStateEditable: false,
  });

  const graphData: DataEntry[] = [
    {
      title: "Voted in favor",
      value: currentIssue?.yeses?.length ?? 0,
      color: "#04C139",
    },
    {
      title: "Voted Against",
      value: currentIssue?.noes?.length ?? 0,
      color: "#FF6B6B",
    },
  ];
  const imageAttachment: Attachment = { raw: undefined, url: "", name: "" };
  const defaultAttachments: Attachment[] =
    currentIssue?.attachments?.map((attachment) => {
      return { raw: undefined, url: attachment.url, name: attachment.name };
    }) ?? [];
  const [image, setImage] = useState(imageAttachment);
  const [fileAttachments, setFileAttachments] = useState(defaultAttachments);
  const hiddenImageFileInput: any = React.createRef();
  const hiddenAttachmentInput: any = React.createRef();

  const handleIssue = (newIssue: Issue) => {
    setCurrentIssue(newIssue);
  };

  const handleEditIssue = (editIssue: EditIssue) => {
    setCurrentEditIssue(editIssue);
  };

  const handleUpload = (_: any) => {
    hiddenImageFileInput.current.click();
  };

  const handleAddAttachment = (_: any) => {
    hiddenAttachmentInput.current.click();
  };

  const handleImageChange = (e: any) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      setImage({
        url: URL.createObjectURL(file),
        raw: file,
        name: file.name,
      });
    }
  };

  const handleAttachmentChange = (e: any) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      setFileAttachments([
        ...fileAttachments,
        {
          name: file.name,
          url: URL.createObjectURL(file),
          raw: file,
        },
      ]);
    }
  };

  const postIssue = (imageUrl: string, attachmentUrls?: BasicAttachment[]) => {
    if (currentIssue) {
      const issue: Issue = {
        ...currentIssue,
        coverImage: currentIssue?.coverImage ?? imageUrl,
        uid: currentUser ? currentUser.uid : "",
        attachments:
          (fileAttachments.length === 0 &&
            currentIssue?.attachments &&
            currentIssue?.attachments.length === 0) ||
          fileAttachments.every(
            (f: Attachment) =>
              currentIssue?.attachments &&
              currentIssue?.attachments.every(
                (a: BasicAttachment) => a.url === f.url
              )
          )
            ? currentIssue?.attachments ?? []
            : attachmentUrls ?? [],
        postedBy: currentUser ? currentUser.displayName ?? "" : "",
      };

      addFirebaseDataById("issues", issue, (err: Error | null) => {
        if (err === null) {
          setIsPostButtonDisabled(false);
          message.success("Issue edited successfully, thank you!");
          if (issue?.status === VOTE_STATUS.closed) {
            addFirebaseData(
              "notifications",
              {
                title: `Voting Results available for ${currentIssue.title}`,
                link: `/results/${currentIssue.id}`,
                description: `Share the results`,
                created: Date.now(),
              } as Notification,
              () => {}
            );
          }
          history.push("/manage-voting");
        } else {
          setIsPostButtonDisabled(false);
          message.error(err);
        }
      });
    }
  };

  const uploadAttachmentsAndPostIssue = (imageUrl: string) => {
    if (
      !fileAttachments.every(
        (f: Attachment) =>
          currentIssue?.attachments &&
          currentIssue?.attachments.every(
            (a: DisplayAttachment) => a.url === f.url
          )
      )
    ) {
      postIssue(imageUrl);
      return;
    }
    var counter = 0;
    var basicAttachments: BasicAttachment[] = [];
    const uploadAttachment = (attachment: Attachment) => {
      if (attachment && attachment.raw) {
        const attachmentNameParts = attachment.name.split(".");
        const attachmentName = attachmentNameParts[0];
        const attachmentExtension = attachmentNameParts[1];
        const attachmentFileName = `${attachmentName}_${uuidv4()}.${attachmentExtension}`;
        const uploadAttachmentTask = storage
          .ref(`images/${attachmentFileName}`)
          .put(attachment.raw);

        uploadAttachmentTask.on(
          "state_changed",
          () => {},
          (error) => {
            setIsPostButtonDisabled(false);
            message.error(error);
          },
          () => {
            ++counter;
            uploadAttachmentTask.snapshot.ref
              .getDownloadURL()
              .then((downloadURL) => {
                basicAttachments.push({
                  url: downloadURL,
                  name: attachment.name,
                });
                if (counter < fileAttachments.length) {
                  uploadAttachment(fileAttachments[counter]);
                } else {
                  postIssue(imageUrl, basicAttachments);
                }
              });
          }
        );
      } else {
        postIssue(imageUrl);
      }
    };

    uploadAttachment(fileAttachments[counter]);
  };

  const postIssueAndUploadAllAttachments = () => {
    if (image.raw) {
      const imageNameParts = image.name.split(".");
      const imageName = imageNameParts[0];
      const imageExtension = imageNameParts[1];
      const imageFileName = `${imageName}_${uuidv4()}.${imageExtension}`;
      const uploadTask = storage.ref(`images/${imageFileName}`).put(image.raw);

      uploadTask.on(
        "state_changed",
        () => {},
        (error) => {
          setIsPostButtonDisabled(false);
          message.error(error.stack);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            uploadAttachmentsAndPostIssue(downloadURL);
          });
        }
      );
    } else {
      if (
        fileAttachments.every(
          (f: Attachment) =>
            currentIssue?.attachments &&
            currentIssue?.attachments.every(
              (a: BasicAttachment) => a.url === f.url
            ) &&
            fileAttachments.length === 0 &&
            currentIssue?.attachments &&
            currentIssue.attachments.length === 0
        )
      ) {
        postIssue(currentIssue?.coverImage ?? "");
      }
    }
  };

  const handlePostIssue = () => {
    confirm({
      title: (
        <>
          <h5>{`Are you sure you want to close voting for ${
            currentIssue?.title ?? ""
          }?`}</h5>{" "}
          <ul>
            <li>Votes in favor: {currentIssue?.yeses?.length ?? 0} </li>
            <li>Votes against: {currentIssue?.noes?.length ?? 0} </li>
            <li>Target votes: {currentIssue?.voteTarget} </li>
          </ul>
        </>
      ),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: <span>Yes</span>,
      okType: "danger",
      cancelText: "No",
      onOk() {
        if (currentIssue) {
          const issue = {
            ...currentIssue,
            votingType: VOTE_TYPE.mandates
          };
          handleIssue(issue);
          addFirebaseDataById("issues", issue, (err: Error | null) => {
            if (err === null) {
              setIsPostButtonDisabled(false);
              setIsClosed(true);
              message.success("Issue edited successfully, thank you!");
              history.push("/manage-voting");
            } else {
              setIsClosed(false);
              setIsPostButtonDisabled(false);
              message.error(err);
            }
          });
        }
      },
      onCancel() {},
    });
  };

  const postAfterConfirmationIssue = () => {
    const imageUrl = image.url !== "" ? image.url : currentIssue?.coverImage;
    if (!Boolean(imageUrl)) {
      message.error("Please choose a cover image");
      return;
    }
    if (!Boolean(currentIssue?.title)) {
      message.error("Please enter a heading");
      return;
    }
    if (!Boolean(currentIssue?.description)) {
      message.error("Please enter a description text");
      return;
    }

    setIsPostButtonDisabled(true);

    if (
      image.url === "" &&
      fileAttachments.every(
        (f: Attachment) =>
          currentIssue?.attachments &&
          currentIssue?.attachments.every(
            (a: DisplayAttachment) => a.url === f.url
          )
      ) &&
      fileAttachments.length === 0 &&
      currentIssue?.attachments &&
      currentIssue?.attachments.length === 0
    ) {
      postIssue(currentIssue?.coverImage ?? "");
      return;
    }
    postIssueAndUploadAllAttachments();
  };

  const handleMandateTextModal = (value: boolean) => {
    setIsMandateTextVisible(value);
  };
  const handleExplanatoryMemorandaModal = (value: boolean) => {
    setIsExplanatoryMemorandaVisible(value);
  };

  const MandateStatusUpdateSteps = () => {
    return (
      <Steps size="small">
        <Step
          title={<span>Preparing Mandate for <br/> submission</span>}
          status={
            currentIssue?.status && currentIssue?.status > VOTE_STATUS.started
              ? "finish"
              : currentIssue?.status === VOTE_STATUS.started
              ? "process"
              : "wait"
          }
          onClick={() => {
            if (currentIssue) {
              if (
                currentIssue?.status &&
                currentIssue?.status === VOTE_STATUS.started
              ) {
                const watchesDetail = currentIssue.watches
                  ? { watches: currentIssue.watches }
                  : undefined;
                const yesesDetail = currentIssue.yeses
                  ? { yeses: currentIssue.yeses }
                  : undefined;
                const noesDetail = currentIssue.noes
                  ? { noes: currentIssue.noes }
                  : undefined;
                const mandateTextDetail = currentIssue.mandateText
                  ? { mandateText: currentIssue.mandateText }
                  : undefined;
                const explanatoryMemorandaDetail =
                  currentIssue.explanatoryMemoranda
                    ? {
                        explanatoryMemoranda: currentIssue.explanatoryMemoranda,
                      }
                    : undefined;
                const voteTargetDetail = currentIssue.voteTarget
                  ? { voteTarget: currentIssue.voteTarget }
                  : undefined;
                handleIssue({
                  id: currentIssue.id,
                  title: currentIssue.title,
                  description: currentIssue.description,
                  coverImage: currentIssue.coverImage,
                  created: currentIssue.created,
                  tags: currentIssue.tags,
                  locality: currentIssue.locality,
                  state: currentIssue.state,
                  attachments: currentIssue.attachments,
                  postedBy: currentIssue.postedBy,
                  uid: currentIssue.uid,
                  votingType: currentIssue.votingType,
                  ...watchesDetail,
                  ...yesesDetail,
                  ...noesDetail,
                  ...mandateTextDetail,
                  ...explanatoryMemorandaDetail,
                  ...voteTargetDetail,
                });
              } else {
                handleIssue({ ...currentIssue, status: VOTE_STATUS.started });
              }
            }
          }}
          icon={<CheckCircleFilled style={{ fontSize: 32 }} />}
        />
        <Step
          title="At Congress"
          status={
            currentIssue?.status && currentIssue?.status > VOTE_STATUS.progress
              ? "finish"
              : currentIssue?.status === VOTE_STATUS.progress
              ? "process"
              : "wait"
          }
          icon={
            <StatusHouse
              background={
                currentIssue?.status &&
                currentIssue?.status >= VOTE_STATUS.progress
                  ? "#ab63fe"
                  : "rgba(0, 0, 0, 0.25)"
              }
            />
          }
          onClick={() => {
            if (currentIssue) {
              handleIssue({ ...currentIssue, status: VOTE_STATUS.progress });
            }
          }}
        />
        <Step
          title="Complete"
          status={
            currentIssue?.status === VOTE_STATUS.closed ? "finish" : "wait"
          }
          icon={<CheckCircleFilled style={{ fontSize: 32 }} />}
          onClick={() => {
            if (currentIssue) {
              handleIssue({ ...currentIssue, status: VOTE_STATUS.closed });
            }
          }}
        />
      </Steps>
    );
  };
  return (
    <div>
      <BackLink link="/manage-voting" />
      <DetailHeading heading="Voting Detail" />

      <div className="voting-detail-main">
        <div className="voting-detail-center">
          <div className="voting-detail-top-buttons">
            <Button
              type="primary"
              onClick={() => {
                postAfterConfirmationIssue();
              }}
            >
              {isPostButtonDisabled ? (
                <Spin indicator={loadingIcon} />
              ) : (
                <span>Update Vote</span>
              )}
            </Button>
            {currentIssue?.votingType === VOTE_TYPE.issues && (
              <Button type="primary" onClick={handlePostIssue}>
                <span>Close Voting</span>
              </Button>
            )}
          </div>
          <div className="voting-detail-image-container">
            <img
              src={image.url === "" ? currentIssue?.coverImage : image.url}
              className="voting-detail-image"
              alt="issue"
            />
            <input
              type="file"
              ref={hiddenImageFileInput}
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
            <div className="voting-detail-top-right-button">
              <Button
                type="default"
                className="ant-btn-inverted"
                shape="round"
                icon={<EditFilled />}
                onClick={handleUpload}
              >
                {" "}
                Edit Image
              </Button>
            </div>
          </div>
          {currentIssue?.votingType === VOTE_TYPE.mandates && (
            <Popover
              placement="top"
              title="Update status of the mandate here"
              content="Click on the icons to update status"
              trigger="hover"
            >
              <div>
                <MandateStatusUpdateSteps />
              </div>
            </Popover>
          )}
          <div className="voting-detail-edit-button">
            {currentEditIssue.isTitleEditable ? (
              <Input
                className="voting-detail-input"
                value={currentIssue?.title}
                onChange={(e: ChangeEvent<{ value: string }>) => {
                  if (currentIssue) {
                    handleIssue({
                      ...currentIssue,
                      title: e.target.value,
                    });
                  }
                }}
              />
            ) : (
              <h3>{currentIssue?.title}</h3>
            )}
            <Button
              type="primary"
              shape="circle"
              icon={<EditFilled />}
              onClick={(_: any) =>
                handleEditIssue({
                  ...currentEditIssue,
                  isTitleEditable: !currentEditIssue.isTitleEditable,
                })
              }
            />
          </div>
          <div className="vote-detail-mandate-buttons">
            <div className="vote-detail-mandate-section">
              <b>Mandate Text</b>
              <p>Text of the mandate as introduced into the parliament</p>
              {currentIssue?.mandateText ? (
                <Button
                  type="default"
                  className="ant-btn-inverted"
                  size="large"
                  onClick={() => handleMandateTextModal(true)}
                >
                  Edit Mandate Text
                </Button>
              ) : (
                <Button
                  type="primary"
                  size="large"
                  onClick={() => handleMandateTextModal(true)}
                >
                  Add Mandate Text
                </Button>
              )}
            </div>

            <div className="vote-detail-memoranda-section">
              <b>Explanatory Memoranda</b>
              <p>
                Accompanies and provides an explanation of the content
                introduced version (first reading) of the mandate.
              </p>
              {currentIssue?.explanatoryMemoranda ? (
                <Button
                  type="default"
                  className="ant-btn-inverted"
                  size="large"
                  onClick={() => handleExplanatoryMemorandaModal(true)}
                >
                  Edit Explanatory Memoranda
                </Button>
              ) : (
                <Button
                  type="primary"
                  size="large"
                  onClick={() => handleExplanatoryMemorandaModal(true)}
                >
                  Add Explanatory Memoranda
                </Button>
              )}
            </div>
          </div>

          <div className="voting-detail-info">
            <div>
              <b>Posted by</b>
              <span className="grey-text">
                {currentIssue?.postedBy} on{" "}
                <DateWithCalendar date={currentIssue?.created} size={14} />
              </span>
            </div>
            {currentIssue?.scopeOfIssue === "local" && (
              <div>
                <div className="voting-detail-edit-button">
                  <b>Concerning Localities</b>
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<EditFilled />}
                    onClick={(_: any) =>
                      handleEditIssue({
                        ...currentEditIssue,
                        isLocalitiesEditable:
                          !currentEditIssue.isLocalitiesEditable,
                      })
                    }
                  />
                </div>
                {currentEditIssue.isLocalitiesEditable ? (
                  <Input
                    className="voting-detail-input"
                    value={currentIssue?.locality}
                    onChange={(e: ChangeEvent<{ value: string }>) => {
                      if (currentIssue) {
                        handleIssue({
                          ...currentIssue,
                          locality: e.target.value,
                        });
                      }
                    }}
                  />
                ) : (
                  <p>{currentIssue?.locality}</p>
                )}
              </div>
            )}
            {currentIssue?.scopeOfIssue === "state" && (
              <div>
                <div className="voting-detail-edit-button">
                  <b>State</b>
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<EditFilled />}
                    onClick={(_: any) =>
                      handleEditIssue({
                        ...currentEditIssue,
                        isStateEditable: !currentEditIssue.isStateEditable,
                      })
                    }
                  />
                </div>

                {currentEditIssue.isStateEditable ? (
                  <Select
                    value={currentIssue?.state}
                    style={{ width: "8em" }}
                    defaultValue=""
                    onChange={(value: string) => {
                      if (currentIssue) {
                        handleIssue({
                          ...currentIssue,
                          state: value,
                        });
                      }
                    }}
                  >
                    {states.map((state: State, index: number) => {
                      return (
                        <Option
                          key={`state_${index}`}
                          value={state.name}
                        >
                          {state.name}
                        </Option>
                      );
                    })}
                  </Select>
                ) : (
                  <p>
                    <span className="grey-text">
                      {currentIssue?.state ? currentIssue?.state : "Not set"}
                    </span>
                  </p>
                )}
              </div>
            )}
            <div>
              <div className="voting-detail-edit-button">
                <b>Factual Docs</b>
                <Button
                  type="primary"
                  shape="circle"
                  icon={<EditFilled />}
                  onClick={(_: any) =>
                    handleEditIssue({
                      ...currentEditIssue,
                      isAttachmentsEditable: true,
                    })
                  }
                />
              </div>
              <p>
                <PaperClipOutlined />
                {currentIssue?.attachments &&
                  currentIssue?.attachments.map(
                    (attachment: BasicAttachment, index) => {
                      return (
                        <a
                          key={`attachment_link_${index}`}
                          href={attachment.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="green-text">
                            {attachment.name}{" "}
                            {currentIssue?.attachments.length - 1 === index
                              ? ""
                              : ", "}
                          </span>
                        </a>
                      );
                    }
                  )}
              </p>
            </div>
            <div>
              <div className="voting-detail-edit-button">
                <b>Target Votes</b>
                <Button
                  type="primary"
                  shape="circle"
                  icon={<EditFilled />}
                  onClick={(_: any) =>
                    handleEditIssue({
                      ...currentEditIssue,
                      isTargetVotesEditable:
                        !currentEditIssue.isTargetVotesEditable,
                    })
                  }
                />
              </div>
              {currentEditIssue.isTargetVotesEditable ? (
                <InputNumber
                  className="voting-detail-input"
                  defaultValue={currentIssue?.voteTarget}
                  onChange={(value: any) => {
                    if (currentIssue) {
                      handleIssue({
                        ...currentIssue,
                        voteTarget: value,
                      });
                    }
                  }}
                />
              ) : (
                <p>{currentIssue?.voteTarget}</p>
              )}
            </div>
          </div>
          <div>
            <div className="voting-detail-edit-button">
              <b>Scope of Issue</b>
              <Button
                type="primary"
                shape="circle"
                icon={<EditFilled />}
                onClick={(_: any) =>
                  handleEditIssue({
                    ...currentEditIssue,
                    isScopeOfIssueEditable:
                      !currentEditIssue.isScopeOfIssueEditable,
                  })
                }
              />
            </div>

            {currentEditIssue.isScopeOfIssueEditable ? (
              <Select
                value={currentIssue?.scopeOfIssue}
                defaultValue=""
                onChange={(value: string) => {
                  if (currentIssue) {
                    handleIssue({
                      ...currentIssue,
                      scopeOfIssue: value,
                    });
                  }
                }}
              >
                <Option value="">Select one</Option>
                <Option value="local">Local</Option>
                <Option value="state">State</Option>
                <Option value="national">National</Option>
              </Select>
            ) : (
              <p>
                <span className="grey-text">
                  {currentIssue?.scopeOfIssue
                    ? currentIssue?.scopeOfIssue
                    : "Not set"}
                </span>
              </p>
            )}
          </div>
          <div>
            <div className="voting-detail-edit-button">
              <b>Description</b>
              <Button
                type="primary"
                shape="circle"
                icon={<EditFilled />}
                onClick={(_: any) =>
                  handleEditIssue({
                    ...currentEditIssue,
                    isDescriptionEditable:
                      !currentEditIssue.isDescriptionEditable,
                  })
                }
              />
            </div>
            {currentEditIssue.isDescriptionEditable ? (
              <TextArea
                rows={8}
                className="voting-detail-input"
                value={currentIssue?.description}
                onChange={(e: ChangeEvent<{ value: string }>) => {
                  if (currentIssue) {
                    handleIssue({
                      ...currentIssue,
                      description: e.target.value,
                    });
                  }
                }}
              />
            ) : (
              <p>{currentIssue?.description}</p>
            )}
          </div>
        </div>
        <div className="voting-detail-side">
          <div className="voting-detail-block">
            <h6>Voting Results</h6>
            <VotingResultsGraph data={graphData} />
          </div>
        </div>
      </div>
      <Modal
        title="Factual Docs"
        visible={currentEditIssue.isAttachmentsEditable}
        onCancel={() =>
          handleEditIssue({
            ...currentEditIssue,
            isAttachmentsEditable: false,
          })
        }
        footer={null}
      >
        <div className="post-voting-attachments">
          <Row>
            {fileAttachments.map((attachment: Attachment, index: number) => (
              <Col span={12} key={`attachment_proposal_${index}`}>
                <div className="post-voting-attachment">
                  <a className="post-voting-green" href={attachment.url}>
                    <PaperClipOutlined />
                    {attachment.name}
                  </a>
                  <label
                    className="post-voting-red post-voting-delete"
                    onClick={() => {
                      let array = [...fileAttachments];
                      array.splice(index, 1);
                      setFileAttachments(array);
                    }}
                  >
                    Delete
                  </label>
                </div>
              </Col>
            ))}
          </Row>
          <Button
            type="primary"
            size="large"
            icon={<PlusCircleOutlined />}
            onClick={handleAddAttachment}
          >
            Add an attachment
          </Button>
          <input
            type="file"
            ref={hiddenAttachmentInput}
            onChange={handleAttachmentChange}
            style={{ display: "none" }}
          />
        </div>
      </Modal>
      <Modal
        title="Mandate Text"
        visible={isMandateTextVisible}
        onCancel={() => handleMandateTextModal(false)}
        footer={null}
        focusTriggerAfterClose={false}
      >
        <TextArea
          rows={24}
          className="voting-detail-input"
          value={currentIssue?.mandateText}
          onChange={(e: ChangeEvent<{ value: string }>) => {
            if (currentIssue) {
              handleIssue({
                ...currentIssue,
                mandateText: e.target.value,
              });
            }
          }}
        />
      </Modal>
      <Modal
        title="Explanatory Memoranda"
        visible={isExplanatoryMemorandaVisible}
        onCancel={() => handleExplanatoryMemorandaModal(false)}
        footer={null}
        focusTriggerAfterClose={false}
      >
        <TextArea
          rows={24}
          className="voting-detail-input"
          value={currentIssue?.explanatoryMemoranda}
          onChange={(e: ChangeEvent<{ value: string }>) => {
            if (currentIssue) {
              handleIssue({
                ...currentIssue,
                explanatoryMemoranda: e.target.value,
              });
            }
          }}
        />
      </Modal>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any, _: any) => ({
  dispatch: dispatch,
});
const mapStateToProps = (state: any, _: any) => ({
  UserData: state.UserData,
  LocalityData: state.LocalityData,
  IssueData: state.IssueData,
});
export default connect(mapStateToProps, mapDispatchToProps)(VotingDetailPage);
