export const TOPBAR = 'topbar';
export const SIDEBAR = 'sidebar';
export const FOOTER = 'footer';
export const LOGINPAGE = 'loginpage';
export const UpdateIssueData = "updateIssueData";
export const UpdateProposalData = "updateProposalData";
export const UpdateFeedbackData = "updateFeedbackData";
export const UpdateLocalityData = "updateLocalityData";
export const UpdateNotificationData = "updateNotificationData";
export const UpdateVerifiedVoteData = "updateVerifiedVoteData";
export const UpdateVoteData = "updateVoteData";
export const UpdateUserData = "updateUserData";
export const UpdateUserListData = "updateUserListData";
export const UpdateCouncilMemberData = "updateCouncilMemberData";
export const UpdateAdminData = "updateAdminData";
export const SetMenu = "setmenu";