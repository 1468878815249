import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import PrivateRoute from "./services/PrivateRoute";
import LoginPage from "./pages/login_page/login_page";
import MyRoutes from "./services/routeService";
const App = () => {
  return (
    <Router>
      <Route exact path="/login" component={LoginPage} />
      <Route
        exact
        path="/"
        render={() => <Redirect to="/login" />}
      />
      <PrivateRoute>
        <MyRoutes />
      </PrivateRoute>
    </Router>
  );
}

export default App;
