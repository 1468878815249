export const routeInfo = [
  {
    order: 0,
    route: "/dashboard",
    menu_name: "Dashboard",
    description: "Dashboard",
  },
  {
    order: 1,
    route: "/mandates",
    menu_name: "Mandates",
    description: "Mandates",
  },
  {
    order: 2,
    route: "/issues",
    menu_name: "Issues",
    description: "All Issues",
  },
  {
    order: 3,
    route: "/voting",
    menu_name: "Voting",
    description: "Voting In Progress",
  },
  {
    order: 4,
    route: "/verify",
    menu_name: "Verify your votes",
    description: "Verify your votes",
  },
  {
    order: 5,
    route: "/town-hall",
    menu_name: "Your TownHall",
    description: "Your TownHall",
  },
  { order: 7, route: "/profile", menu_name: "Profile", description: "Profile" },
  {
    order: 6,
    route: "/community",
    menu_name: "Community",
    description: "Community",
  },
  { order: 8, route: "/logout", menu_name: "Logout", description: "Logout" },
  { order: 9, route: "/login", menu_name: "login", description: "Login" },
  {
    order: 10,
    route: "/manage-users",
    menu_name: "Manage Users",
    description: "Register",
  },
  {
    order: 11,
    route: "/manage-proposals",
    menu_name: "Manage Proposals",
    description: "Manage Proposals",
  },
  {
    order: 12,
    route: "/manage-voting",
    menu_name: "Manage Voting",
    description: "Manage Voting",
  },
  {
    order: 13,
    route: "/app-usage-stats",
    menu_name: "App Usage Stats",
    description: "App Usage Stats",
  },
  {
    order: 14,
    route: "/manage-localities",
    menu_name: "Manage Localities",
    description: "Manage Localities",
  },
  {
    order: 15,
    route: "/manage-notifications",
    menu_name: "Manage Notifications",
    description: "Manage Notifications",
  },
  {
    order: 16,
    route: "/verified-votes",
    menu_name: "Verified Votes",
    description: "Verified Votes",
  },
];
