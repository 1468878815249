import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { connect } from "react-redux";
import { RoleType } from "../../constants/enums";
import User from "../../models/User";
import { getUsersData } from "../../services/redux/reducers/user_data_service";
import { getIssuesData } from "../../services/redux/reducers/issue_data_service";
import { getProposalsData } from "../../services/redux/reducers/proposal_data_service";
import Issue from "../../models/Issue";
import Proposal from "../../models/Proposal";
import { ColumnsType } from "antd/lib/table/interface";

interface AppUsageStats {
  numberOfUsers: number;
  numberOfRegisteredVoters: number;
  numberOfUSCitizens: number;
  numberOfAdmins: number;
  numberOfIssues: number;
  numberOfProposals: number;
}

const AppUsageStatsPage = (props: any) => {
  const { dispatch } = props;
  const [users, setUsers] = useState([] as User[]);
  const [issues, setIssues] = useState([] as Issue[]);
  const [proposals, setProposals] = useState([] as Proposal[]);
  useEffect(() => {
    getUsersData(dispatch, (users: User[]) => {
      setUsers(users);
    });
    getIssuesData(dispatch, (issues: Issue[]) => {
      setIssues(issues);
    });
    getProposalsData(dispatch, (proposals: Proposal[] | undefined) => {
      if (proposals) {
        setProposals(proposals);
      }
    });
  }, [dispatch]);
  const dataSource: AppUsageStats[] = [
    {
      numberOfUsers: (users || []).filter((user: User) => user.role === RoleType.USER)
        .length,
      numberOfUSCitizens: (users || []).filter((user: User) => user.isUSCitizen).length,
      numberOfRegisteredVoters: (users || []).filter(
        (user: User) => user.isRegisteredVoter
      ).length,
      numberOfAdmins: (users || []).filter((user: User) => user.role === RoleType.ADMIN)
        .length,
      numberOfIssues: issues?.length || 0,
      numberOfProposals: proposals?.length || 0,
    },
  ];

  const columns = [
    {
      title: "Number of users",
      dataIndex: "numberOfUsers",
      key: "numberOfUsers",
      align: "center",
    },
    {
      title: "Number of US citizens",
      dataIndex: "numberOfUSCitizens",
      key: "numberOfUSCitizens",
      align: "center",
    },
    {
      title: "Number of registered voters",
      dataIndex: "numberOfRegisteredVoters",
      key: "numberOfRegisteredVoters",
      align: "center",
    },
    {
      title: "Number of admins",
      dataIndex: "numberOfAdmins",
      key: "numberOfAdmins",
      align: "center",
    },
    {
      title: "Number of issues",
      dataIndex: "numberOfIssues",
      key: "numberOfIssues",
      align: "center",
    },
    {
      title: "Number of proposals",
      dataIndex: "numberOfProposals",
      key: "numberOfProposals",
      align: "center",
    },
  ] as ColumnsType<AppUsageStats>;
  return (
    <div>
      <h3>App Usage Stats</h3>
      <Table dataSource={dataSource} columns={columns} pagination={false} />
    </div>
  );
};

const mapDispatchToProps = (dispatch: any, _: any) => ({
  dispatch: dispatch,
});

export default connect(null, mapDispatchToProps)(AppUsageStatsPage);
