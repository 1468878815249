import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import {
  PaperClipOutlined,
  EditFilled,
  PlusCircleOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  SendOutlined,
} from "@ant-design/icons";
import BackLink from "../../components/common/back_link/back_link";
import DetailHeading from "../../components/common/detail_heading/detail_heading";
import {
  Badge,
  Button,
  Col,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Spin,
} from "antd";
import DateWithCalendar from "../../components/dashboard_page_components/date_with_calendar/date_with_calendar";
import { connect } from "react-redux";
import "./proposal_detail_page.css";
import {
  addFirebaseData,
  addFirebaseDataById,
  storage,
  updateFirebaseDataById,
} from "../../services/firebaseService";
import { v4 as uuidv4 } from "uuid";
import { AuthContext } from "../../services/Auth";
import ParamTypes from "../../models/ParamTypes";
import Proposal from "../../models/Proposal";
import TextArea from "antd/lib/input/TextArea";
import { Attachment, BasicAttachment } from "../../models/Attachment";
import {
  getProposalData,
  removeProposalData,
} from "../../services/redux/reducers/proposal_data_service";
import Issue from "../../models/Issue";
import { VOTE_TYPE } from "../../constants/enums";
import Notification from "../../models/Notification";
import { getIssueData } from "../../services/redux/reducers/issue_data_service";
import Feedback from "../../models/Feedback";
import {
  getFeedbacksData,
  getFeedbacksDataByUid,
} from "../../services/redux/reducers/feedback_data_service";
import { createVotingContract } from "../../services/contractService";
import { TransactionReceipt } from "web3-core";
import State from "../../models/State";
import stateData from "../../states.json";

const { confirm } = Modal;
const { Option } = Select;
const loadingIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
);
interface DisplayAttachment {
  url: string;
  name: string;
}

interface EditProposal {
  isTitleEditable: boolean;
  isDescriptionEditable: boolean;
  isLocalitiesEditable: boolean;
  isAttachmentsEditable: boolean;
  isTargetVotesEditable: boolean;
  isScopeOfIssueEditable: boolean;
  isStateEditable: boolean;
}

const ProposalDetailPage = (props: any) => {
  const currentUser = useContext(AuthContext);
  let { id } = useParams<ParamTypes>();
  const history = useHistory();
  const { CommonData, FeedbackData, isAdmin, dispatch } = props;
  const { userInfo } = CommonData;
  const { feedback_data_list } = FeedbackData;
  const [, setIssue] = useState<Issue | undefined>();
  const [feedbacks, setFeedbacks] = useState<Feedback[]>(
    ((feedback_data_list ? feedback_data_list : []) as Feedback[]).filter(
      (f) => f.proposalId === id
    )
  );
  const [feedback, setFeedback] = useState<Feedback | undefined>(undefined);
  const [currentProposal, setCurrentProposal] = useState<Proposal | undefined>(
    undefined
  );
  const states = stateData as State[];
  useEffect(() => {
    getProposalData(id, (proposal: Proposal | undefined) => {
      setCurrentProposal(proposal);
    });
    isAdmin
      ? getFeedbacksData(dispatch, (feedbacks: Feedback[]) => {
          if (feedbacks) {
            setFeedbacks(feedbacks.filter((f) => f.proposalId === id));
          }
        })
      : getFeedbacksDataByUid(
          dispatch,
          currentUser?.uid,
          (feedbacks: Feedback[]) => {
            if (feedbacks) {
              setFeedbacks(feedbacks.filter((f) => f.proposalId === id));
            }
          }
        );
    getIssueData(id, (issue: Issue | undefined) => {
      if (issue) {
        setIssue(issue);
      }
    });
  }, [currentUser?.uid, dispatch, id, isAdmin]);

  const [currentEditProposal, setCurrentEditProposal] = useState({
    isTitleEditable: false,
    isDescriptionEditable: false,
    isLocalitiesEditable: false,
    isAttachmentsEditable: false,
    isTargetVotesEditable: false,
    isScopeOfIssueEditable: false,
    isStateEditable: false,
  });
  const [isPostButtonDisabled, setIsPostButtonDisabled] = useState(false);
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);
  const imageAttachment: Attachment = { raw: undefined, url: "", name: "" };
  const defaultAttachments: Attachment[] =
    currentProposal?.attachments?.map((attachment: BasicAttachment) => {
      return { raw: undefined, url: attachment.url, name: attachment.name };
    }) ?? [];
  const [image, setImage] = useState(imageAttachment);
  const [fileAttachments, setFileAttachments] = useState(defaultAttachments);
  const [fileMessageAttachments, setFileMessageAttachments] = useState<
    Attachment[]
  >([]);
  const [voteTarget, setVoteTarget] = useState(0);
  const hiddenImageFileInput: any = React.createRef();
  const hiddenAttachmentInput: any = React.createRef();
  const hiddenMessageAttachmentInput: any = React.createRef();

  const handleProposal = (newProposal: Proposal) => {
    setCurrentProposal(newProposal);
  };

  const handleEditIssue = (editProposal: EditProposal) => {
    setCurrentEditProposal(editProposal);
  };

  const handleUpload = (_: any) => {
    hiddenImageFileInput.current.click();
  };

  const handleAddAttachment = (_: any) => {
    hiddenAttachmentInput.current.click();
  };
  const handleAddMessageAttachment = (_: any) => {
    hiddenMessageAttachmentInput.current.click();
  };

  const handleImageChange = (e: any) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      setImage({
        url: URL.createObjectURL(file),
        raw: file,
        name: file.name,
      });
    }
  };

  const handleAttachmentChange = (e: any) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      setFileAttachments([
        ...fileAttachments,
        {
          name: file.name,
          url: URL.createObjectURL(file),
          raw: file,
        },
      ]);
    }
  };
  const handleMessageAttachmentChange = (e: any) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      setFileMessageAttachments([
        ...fileMessageAttachments,
        {
          name: file.name,
          url: URL.createObjectURL(file),
          raw: file,
        },
      ]);
    }
  };

  const postFeedback = (
    feedback: Feedback | undefined,
    attachmentUrls: string[],
    userInfo: any,
    id: string
  ) => {
    const feedback1 = {
      created: feedback?.created ?? Date.now(),
      attachments: attachmentUrls,
      uid: userInfo?.uid ?? "",
      proposalId: id,
      message: feedback?.message ?? "",
    };
    addFirebaseData("feedbacks", feedback1, (err: Error | null) => {
      console.log(err);
      if (err) {
        setIsSendButtonDisabled(false);
        message.error(err?.message);
      } else {
        setIsSendButtonDisabled(false);
      }
    });
  };

  const handleSendMessage = (e: any) => {
    if ((feedback && feedback.message === "") || feedback === undefined) {
      message.warning("Enter in text in feedback block");
      return;
    }
    setIsSendButtonDisabled(true);
    var counter = 0;
    var attachmentUrls: string[] = [];
    const uploadAttachment = (attachment: Attachment) => {
      if (attachment && attachment.raw) {
        const attachmentNameParts = attachment.name.split(".");
        const attachmentName = attachmentNameParts[0];
        const attachmentExtension = attachmentNameParts[1];
        const attachmentFileName = `${attachmentName}_${uuidv4()}.${attachmentExtension}`;
        const uploadAttachmentTask = storage
          .ref(`images/${attachmentFileName}`)
          .put(attachment.raw);

        uploadAttachmentTask.on(
          "state_changed",
          () => {},
          (error) => {
            message.error(error);
            setIsSendButtonDisabled(false);
          },
          () => {
            ++counter;
            uploadAttachmentTask.snapshot.ref
              .getDownloadURL()
              .then((downloadURL) => {
                attachmentUrls.push(downloadURL);
                if (counter < fileMessageAttachments.length) {
                  uploadAttachment(fileMessageAttachments[counter]);
                } else {
                  postFeedback(feedback, attachmentUrls, userInfo, id);
                }
              });
          }
        );
      } else {
        postFeedback(feedback, [], userInfo, id);
      }
    };

    uploadAttachment(fileMessageAttachments[counter]);
    setFeedback(undefined);
    setFileMessageAttachments([]);
  };

  const handFeedback = (feedback: Feedback | undefined) => {
    if (feedback) {
      setFeedback(feedback);
    }
  };

  const postIssueAndProposal = (
    imageUrl: string,
    isBeingSaved: boolean,
    attachmentUrls?: BasicAttachment[]
  ) => {
    if (currentProposal) {
      const proposal: Proposal = {
        id: currentProposal?.id,
        title: currentProposal?.title,
        description: currentProposal?.description,
        coverImage: imageUrl,
        tags: currentProposal?.tags ?? [],
        created: Date.now(),
        uid: currentUser ? currentUser.uid : "",
        locality: currentProposal?.locality,
        state: currentProposal?.state,
        scopeOfIssue: currentProposal?.scopeOfIssue ?? "",
        attachments:
          (fileAttachments.length === 0 &&
            currentProposal?.attachments &&
            currentProposal?.attachments.length === 0) ||
          fileAttachments.every(
            (f: Attachment) =>
              currentProposal?.attachments &&
              currentProposal?.attachments.every(
                (a: BasicAttachment) => a.url === f.url
              )
          )
            ? currentProposal?.attachments ?? []
            : attachmentUrls ?? [],
        postedBy: currentUser ? currentUser.displayName ?? "" : "",
        voteTarget,
      };

      if (isBeingSaved) {
        updateFirebaseDataById("proposals", proposal, (err: Error | null) => {
          if (err === null) {
            setIsPostButtonDisabled(false);
            message.success("Proposal saved successfully");
          } else {
            setIsPostButtonDisabled(false);
            message.error(err);
          }
        });
      } else {
        const issue: Issue = {
          ...proposal,
          voteTarget,
          votingType: VOTE_TYPE.issues,
        };
        createVotingContract(issue.id)
          .then((transaction: TransactionReceipt) => {
            issue.transactionHash = transaction.transactionHash;
            issue.contractAddress = transaction.contractAddress;
            addFirebaseDataById("issues", issue, (err: Error | null) => {
              if (err === null) {
                addFirebaseDataById(
                  "proposals",
                  proposal,
                  (err: Error | null) => {
                    if (err === null) {
                      setIsPostButtonDisabled(false);
                      addFirebaseData(
                        "notifications",
                        {
                          title: "New Issue Posted",
                          link: `/issue-detail/${issue.id}`,
                          description: `Vote on ${issue.title}`,
                          created: Date.now(),
                        } as Notification,
                        () => {}
                      );
                      message.success(
                        "Proposal published successfully, thank you!"
                      );
                      history.push("/manage-voting");
                    } else {
                      setIsPostButtonDisabled(false);
                      message.error(err);
                    }
                  }
                );
              } else {
                message.error(err);
              }
            });
          })
          .catch((err) => {
            setIsPostButtonDisabled(false);
            console.error(err);
          });
      }
    }
  };

  const uploadAttachmentsAndPostIssue = (
    imageUrl: string,
    isBeingSaved: boolean
  ) => {
    if (!fileAttachments || (fileAttachments && fileAttachments.length === 0)) {
      postIssueAndProposal(imageUrl, isBeingSaved);
      return;
    }
    var counter = 0;
    var attachmentUrls: BasicAttachment[] = [];
    const uploadAttachment = (attachment: Attachment) => {
      if (attachment && attachment.raw) {
        const attachmentNameParts = attachment.name.split(".");
        const attachmentName = attachmentNameParts[0];
        const attachmentExtension = attachmentNameParts[1];
        const attachmentFileName = `${attachmentName}_${uuidv4()}.${attachmentExtension}`;
        const uploadAttachmentTask = storage
          .ref(`images/${attachmentFileName}`)
          .put(attachment.raw);

        uploadAttachmentTask.on(
          "state_changed",
          () => {},
          (error) => {
            message.error(error);
            setIsPostButtonDisabled(false);
          },
          () => {
            ++counter;
            uploadAttachmentTask.snapshot.ref
              .getDownloadURL()
              .then((downloadURL) => {
                attachmentUrls.push({
                  url: downloadURL,
                  name: attachment.name,
                });
                if (counter < fileAttachments.length) {
                  uploadAttachment(fileAttachments[counter]);
                } else {
                  postIssueAndProposal(imageUrl, isBeingSaved, attachmentUrls);
                }
              });
          }
        );
      } else {
        postIssueAndProposal(imageUrl, isBeingSaved);
      }
    };

    uploadAttachment(fileAttachments[counter]);
  };

  const postIssueAndUploadAllAttachments = (isBeingSaved: boolean) => {
    if (image.raw) {
      const imageNameParts = image.name.split(".");
      const imageName = imageNameParts[0];
      const imageExtension = imageNameParts[1];
      const imageFileName = `${imageName}_${uuidv4()}.${imageExtension}`;
      const uploadTask = storage.ref(`images/${imageFileName}`).put(image.raw);

      uploadTask.on(
        "state_changed",
        () => {},
        (error) => {
          message.error(error.stack);
          setIsPostButtonDisabled(false);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            uploadAttachmentsAndPostIssue(downloadURL, isBeingSaved);
          });
        }
      );
    } else {
      if (
        fileAttachments.every(
          (f: Attachment) =>
            currentProposal?.attachments.every(
              (a: BasicAttachment) => a.url === f.url
            ) ||
            (fileAttachments.length === 0 &&
              currentProposal?.attachments.length === 0)
        )
      ) {
        postIssueAndProposal(currentProposal?.coverImage ?? "", isBeingSaved);
      }
    }
  };

  const handlePostIssue = () => {
    postAfterConfirmationIssue(false);
  };

  const handleSaveProposal = () => {
    postAfterConfirmationIssue(true);
  };

  const postAfterConfirmationIssue = (isBeingSaved: boolean) => {
    const imageUrl = image.url !== "" ? image.url : currentProposal?.coverImage;
    if (!Boolean(imageUrl)) {
      message.error("Please choose a cover image");
      return;
    }
    if (!Boolean(currentProposal?.title)) {
      message.error("Please enter a heading");
      return;
    }
    if (!Boolean(currentProposal?.description)) {
      message.error("Please enter a description text");
      return;
    }

    if (isBeingSaved && !currentProposal?.scopeOfIssue) {
      message.error("Please select a scopeOfIssue");
      return;
    }

    setIsPostButtonDisabled(true);

    if (
      image.url === "" &&
      fileAttachments.every(
        (f: Attachment) =>
          currentProposal?.attachments &&
          currentProposal?.attachments.every(
            (a: DisplayAttachment) => a.url === f.url
          )
      ) &&
      fileAttachments.length === 0 &&
      currentProposal?.attachments &&
      currentProposal?.attachments.length === 0
    ) {
      postIssueAndProposal(
        currentProposal?.coverImage ?? imageUrl,
        isBeingSaved
      );
      return;
    }
    postIssueAndUploadAllAttachments(isBeingSaved);
  };

  const handleDeleteProposal = () => {
    confirm({
      title: `Are you sure you want to remove ${currentProposal?.title ?? ""}?`,
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        removeProposalData(id, (err) => {
          if (err != null) {
            message.error(err.message);
          } else {
            history.push("/manage-proposals");
            message.success(
              `Successfully removed ${currentProposal?.title ?? ""}`
            );
          }
        });
      },
      onCancel() {},
    });
  };
  return (
    <div>
      <BackLink link="/manage-proposals" />
      <DetailHeading heading="Proposal Detail" />

      <div className="proposal-detail-main">
        <div className="proposal-detail-center">
          <b>
            Be aware that clicking the "Post this Proposal" button will publish
            the issue for voting
          </b>
          <div className="proposal-detail-top-buttons">
            <Button type="primary" onClick={handlePostIssue}>
              {isPostButtonDisabled ? (
                <Spin indicator={loadingIcon} />
              ) : (
                // eslint-disable-next-line
                <span>Post this Proposal</span>
              )}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                handleSaveProposal();
              }}
            >
              {isPostButtonDisabled ? (
                <Spin indicator={loadingIcon} />
              ) : (
                <span>Save Proposal</span>
              )}
            </Button>
            <Button
              type="default"
              className="ant-btn-inverted"
              onClick={handleDeleteProposal}
            >
              Delete this Proposal
            </Button>
          </div>
          <div className="proposal-detail-image-container">
            <img
              src={image.url === "" ? currentProposal?.coverImage : image.url}
              className="proposal-detail-image"
              alt="issue"
            />
            <input
              type="file"
              ref={hiddenImageFileInput}
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
            <div className="proposal-detail-top-right-button">
              <Button
                type="default"
                className="ant-btn-inverted"
                shape="round"
                icon={<EditFilled />}
                onClick={handleUpload}
              >
                {" "}
                Edit Image
              </Button>
            </div>
          </div>
          <div className="proposal-detail-edit-button">
            {currentEditProposal.isTitleEditable ? (
              <Input
                className="proposal-detail-input"
                value={currentProposal?.title}
                onChange={(e: ChangeEvent<{ value: string }>) => {
                  if (currentProposal) {
                    handleProposal({
                      ...currentProposal,
                      title: e.target.value,
                    });
                  }
                }}
              />
            ) : (
              <h3>{currentProposal?.title}</h3>
            )}
            <Button
              type="primary"
              shape="circle"
              icon={<EditFilled />}
              onClick={(_: any) =>
                handleEditIssue({
                  ...currentEditProposal,
                  isTitleEditable: !currentEditProposal.isTitleEditable,
                })
              }
            />
          </div>
          <div className="proposal-detail-info">
            <div>
              <b>Posted by</b>
              <span className="grey-text">
                {currentProposal?.postedBy} on{" "}
                <DateWithCalendar date={currentProposal?.created} size={14} />
              </span>
            </div>
            {currentProposal?.scopeOfIssue === "local" && (
              <div>
                <div className="proposal-detail-edit-button">
                  <b>Concerning Localities</b>
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<EditFilled />}
                    onClick={(_: any) =>
                      handleEditIssue({
                        ...currentEditProposal,
                        isLocalitiesEditable:
                          !currentEditProposal.isLocalitiesEditable,
                      })
                    }
                  />
                </div>
                {currentEditProposal.isLocalitiesEditable ? (
                  <Input
                    className="voting-detail-input"
                    value={currentProposal?.locality}
                    onChange={(e: ChangeEvent<{ value: string }>) => {
                      if (currentProposal) {
                        handleProposal({
                          ...currentProposal,
                          locality: e.target.value,
                        });
                      }
                    }}
                  />
                ) : (
                  <h3>{currentProposal?.locality}</h3>
                )}
              </div>
            )}
            {currentProposal?.scopeOfIssue === "state" && (
              <div>
                <div className="proposal-detail-edit-button">
                  <b>State</b>
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<EditFilled />}
                    onClick={(_: any) =>
                      handleEditIssue({
                        ...currentEditProposal,
                        isStateEditable: !currentEditProposal.isStateEditable,
                      })
                    }
                  />
                </div>

                {currentEditProposal.isStateEditable ? (
                  <Select
                    value={currentProposal?.state}
                    defaultValue=""
                    style={{ width: "8em" }}
                    onChange={(value: string) => {
                      if (currentProposal) {
                        handleProposal({
                          ...currentProposal,
                          state: value,
                        });
                      }
                    }}
                  >
                    {states.map((state: State, index: number) => {
                      return (
                        <Option
                          key={`state_${index}`}
                          value={state.name}
                        >
                          {state.name}
                        </Option>
                      );
                    })}
                  </Select>
                ) : (
                  <p>
                    <span className="grey-text">
                      {currentProposal?.state
                        ? currentProposal?.state
                        : "Not set"}
                    </span>
                  </p>
                )}
              </div>
            )}
            <div>
              <div className="proposal-detail-edit-button">
                <b>Factual Docs</b>
                <Button
                  type="primary"
                  shape="circle"
                  icon={<EditFilled />}
                  onClick={(_: any) =>
                    handleEditIssue({
                      ...currentEditProposal,
                      isAttachmentsEditable: true,
                    })
                  }
                />
              </div>
              <p>
                <PaperClipOutlined />
                {currentProposal?.attachments &&
                  currentProposal?.attachments.map(
                    (attachment: BasicAttachment, index) => {
                      return (
                        <a
                          key={`attachment_link_${index}`}
                          href={attachment.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="green-text">
                            {attachment.name}{" "}
                            {currentProposal?.attachments.length - 1 === index
                              ? ""
                              : ", "}
                          </span>
                        </a>
                      );
                    }
                  )}
              </p>
            </div>
            <div>
              <div className="voting-detail-edit-button">
                <b>Target Votes</b>
                <Button
                  type="primary"
                  shape="circle"
                  icon={<EditFilled />}
                  onClick={(_: any) =>
                    handleEditIssue({
                      ...currentEditProposal,
                      isTargetVotesEditable:
                        !currentEditProposal.isTargetVotesEditable,
                    })
                  }
                />
              </div>
              {currentEditProposal.isTargetVotesEditable ? (
                <InputNumber
                  className="voting-detail-input"
                  defaultValue={voteTarget}
                  onChange={(value: any) => {
                    setVoteTarget(value);
                  }}
                />
              ) : (
                <p>{voteTarget}</p>
              )}
            </div>
          </div>
          <div>
            <div className="voting-detail-edit-button">
              <b>Scope of Issue</b>
              <Button
                type="primary"
                shape="circle"
                icon={<EditFilled />}
                onClick={(_: any) =>
                  handleEditIssue({
                    ...currentEditProposal,
                    isScopeOfIssueEditable:
                      !currentEditProposal.isScopeOfIssueEditable,
                  })
                }
              />
            </div>

            {currentEditProposal.isScopeOfIssueEditable ? (
              <Select
                value={currentProposal?.scopeOfIssue}
                defaultValue=""
                onChange={(value: string) => {
                  if (currentProposal) {
                    handleProposal({
                      ...currentProposal,
                      scopeOfIssue: value,
                    });
                  }
                }}
              >
                <Option value="">Select one</Option>
                <Option value="local">Local</Option>
                <Option value="state">State</Option>
                <Option value="national">National</Option>
              </Select>
            ) : (
              <p>
                <span className="grey-text">
                  {currentProposal?.scopeOfIssue
                    ? currentProposal?.scopeOfIssue
                    : "Not set"}
                </span>
              </p>
            )}
          </div>
          <div>
            <div className="proposal-detail-edit-button">
              <b>Description</b>
              <Button
                type="primary"
                shape="circle"
                icon={<EditFilled />}
                onClick={(_: any) =>
                  handleEditIssue({
                    ...currentEditProposal,
                    isDescriptionEditable:
                      !currentEditProposal.isDescriptionEditable,
                  })
                }
              />
            </div>
            {currentEditProposal.isDescriptionEditable ? (
              <TextArea
                rows={8}
                className="proposal-detail-input"
                value={currentProposal?.description}
                onChange={(e: ChangeEvent<{ value: string }>) => {
                  if (currentProposal) {
                    handleProposal({
                      ...currentProposal,
                      description: e.target.value,
                    });
                  }
                }}
              />
            ) : (
              <p>{currentProposal?.description}</p>
            )}
          </div>
        </div>
        <div className="proposal-detail-side">
          <div className="proposal-detail-block">
            <div className="proposal-detail-profile">
              <img
                className="proposal-detail-profile-pic"
                alt="profile pic"
                src={userInfo?.profileUrl ?? "/assets/images/male.png"}
              />
              <div>
                <b>{userInfo.name}</b>
                <p className="grey-text">{userInfo.role}</p>
              </div>
            </div>
            <hr />
            <div
              style={{
                height: "32em",
                overflowY: "scroll",
              }}
            >
              {feedbacks &&
                feedbacks.map((feedback: Feedback) => {
                  return (
                    <div className="proposal-detail-date">
                      <div
                        className="proposal-detail-message-block"
                        style={{
                          justifyContent:
                            // eslint-disable-next-line
                            feedback.uid == userInfo.uid ? "end" : "start",
                        }}
                      >
                        <Badge dot={true} color="green">
                          <img
                            className="proposal-detail-message-profile-pic"
                            alt="profile message pic"
                            src="/assets/images/male.png"
                          />
                        </Badge>
                        <div
                          className="proposal-detail-message-text"
                          style={{
                            background:
                              // eslint-disable-next-line
                              feedback.uid == userInfo.uid ? "#fff" : "#EBEBEB",
                          }}
                        >
                          <p>{feedback.message}</p>
                          {feedback.attachments && <PaperClipOutlined />}
                          {feedback.attachments &&
                            feedback.attachments.map((attachment, index) => {
                              return (
                                <a
                                  key={`message_attachment_link_${index}`}
                                  href={attachment}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <span className="green-text">
                                    {"Attachment "}
                                    {index}
                                    {feedback.attachments.length - 1 === index
                                      ? ""
                                      : ", "}
                                  </span>
                                </a>
                              );
                            })}
                        </div>{" "}
                      </div>
                      <DateWithCalendar
                        date={feedback.created}
                        size={14}
                        textAlign={
                          // eslint-disable-next-line
                          feedback.uid == userInfo.uid ? "end" : "start"
                        }
                      />
                    </div>
                  );
                })}
            </div>
            <div style={{ width: "100%" }}>
              <input
                type="file"
                ref={hiddenMessageAttachmentInput}
                onChange={handleMessageAttachmentChange}
                style={{ display: "none" }}
              />
              <Input
                placeholder="Type your text here"
                size="large"
                value={feedback?.message}
                className="proposal-detail-white-text proposal-detail-send-input proposal-detail-send-input-color"
                onChange={(e: ChangeEvent<{ value: string }>) => {
                  handFeedback({
                    id: feedback?.id ?? "",
                    created: feedback?.created ?? Date.now(),
                    attachments: fileMessageAttachments.map((f) => f.url),
                    uid: userInfo?.uid ?? "",
                    proposalId: id,
                    message: e.target.value,
                  });
                }}
                prefix={
                  isSendButtonDisabled ? (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          className="proposal-detail-white-text"
                          spin
                        />
                      }
                    />
                  ) : (
                    <PaperClipOutlined
                      className="proposal-detail-white-text"
                      onClick={handleAddMessageAttachment}
                    />
                  )
                }
                suffix={
                  isSendButtonDisabled ? (
                    <Spin
                      indicator={
                        <LoadingOutlined style={{ color: "white" }} spin />
                      }
                    />
                  ) : (
                    <SendOutlined
                      className="proposal-detail-white-text"
                      onClick={handleSendMessage}
                    />
                  )
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSendMessage(e);
                  }
                }}
              />
            </div>
            {fileMessageAttachments && fileMessageAttachments.length > 0 && (
              <PaperClipOutlined />
            )}
            {fileMessageAttachments &&
              fileMessageAttachments.map((attachment: Attachment, index) => {
                return (
                  <a
                    key={`message_link_${index}`}
                    href={attachment.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="green-text">
                      {attachment.name}
                      {fileMessageAttachments.length - 1 === index ? "" : ", "}
                    </span>
                  </a>
                );
              })}
          </div>
        </div>
      </div>
      <Modal
        title="Factual Docs"
        visible={currentEditProposal.isAttachmentsEditable}
        onCancel={() =>
          handleEditIssue({
            ...currentEditProposal,
            isAttachmentsEditable: false,
          })
        }
        footer={null}
      >
        <div className="post-proposal-attachments">
          <Row>
            {fileAttachments.map((attachment: Attachment, index: number) => (
              <Col span={12} key={`attachment_proposal_${index}`}>
                <div className="post-proposal-attachment">
                  <a className="post-proposal-green" href={attachment.url}>
                    <PaperClipOutlined />
                    {attachment.name}
                  </a>
                  <label
                    className="post-proposal-red post-proposal-delete"
                    onClick={() => {
                      let array = [...fileAttachments];
                      array.splice(index, 1);
                      setFileAttachments(array);
                    }}
                  >
                    Delete
                  </label>
                </div>
              </Col>
            ))}
          </Row>
          <Button
            type="primary"
            size="large"
            icon={<PlusCircleOutlined />}
            onClick={handleAddAttachment}
          >
            Add an attachment
          </Button>
          <input
            type="file"
            ref={hiddenAttachmentInput}
            onChange={handleAttachmentChange}
            style={{ display: "none" }}
          />
        </div>
      </Modal>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any, _: any) => ({
  dispatch: dispatch,
});
const mapStateToProps = (state: any, _: any) => ({
  ProposalData: state.ProposalData,
  UserData: state.UserData,
  CommonData: state.CommonData,
  FeedbackData: state.FeedbackData,
  LocalityData: state.LocalityData,
});
export default connect(mapStateToProps, mapDispatchToProps)(ProposalDetailPage);
