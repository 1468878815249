import React from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./back_link.css";
import { Link } from "react-router-dom";

type BackLinkProps = {
  link: string;
  heading?: string;
};

const BackLink = (props: BackLinkProps) => {
  return (
    <React.Fragment>
      <Link to={props.link} className="back-link">
        <ArrowLeftOutlined className="back-arrow" />
        {props.heading ? props.heading : "Go Back"}
      </Link>
    </React.Fragment>
  );
};

export default BackLink;
