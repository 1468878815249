import React from "react";
import KeywordItem from "../../../components/dashboard_page_components/keyword_item/keyword_item";
import "./one_type_voting_widget.css";
import { Col, Row } from "antd";
import DateWithCalendar from "../../../components/dashboard_page_components/date_with_calendar/date_with_calendar";
import ProgressCircleChart from "../../../components/dashboard_page_components/progress_circle_chart/progress_circle_chart";
import { VOTE_STATUS } from "../../../constants/enums";

function OneTypeVotingWidget(props: any) {
  const { voteInfo, onClickOneVote } = props;
  const keywordUI = (voteInfo?.tags ?? []).map((tag: string, index: number) => {
    return <KeywordItem key={`one_keyword_key_${index}`} keyword={tag} />;
  });
  return (
    <div className="one-vote">
      <div
        className="one-type-vote-container"
        style={{
          background:
            voteInfo.status === VOTE_STATUS.closed ? "#FF6B6B" : "#00BA34",
        }}
        onClick={() =>
          onClickOneVote(voteInfo.id, voteInfo.status === VOTE_STATUS.closed)
        }
      >
        <div className="one-type-vote-info-container">
          <div className="one-type-vote-info-title">{voteInfo.title}</div>
          <Row>
            <div className="one-type-vote-info-keyword">{keywordUI}</div>
          </Row>
          <Row align={"middle"}>
            <Col span={16}>
              <DateWithCalendar date={voteInfo.created} size={14} />
            </Col>
            <Col span={8}>
              {voteInfo.status !== VOTE_STATUS.closed ? (
                <div style={{ textAlign: "center" }}>
                  {(voteInfo.yeses || voteInfo.noes) && (
                    <ProgressCircleChart
                      percentage={(
                        (((voteInfo.yeses ? voteInfo.yeses.length : 0) +
                          (voteInfo.noes ? voteInfo.noes.length : 0)) /
                          voteInfo.voteTarget) *
                        100
                      ).toFixed(2)}
                    />
                  )}
                </div>
              ) : (
                <div style={{ width: "70px", height: "70px" }}></div>
              )}
            </Col>
          </Row>
        </div>
        <div className="one-type-vote-select-container">
          {voteInfo.status === VOTE_STATUS.closed ? (
            <span>Closed</span>
          ) : (
            <span>Open</span>
          )}
        </div>
      </div>
      <div className="one-type-vote-status-image-container">
        <button
          className="vote-status-image"
          style={{
            background:
              voteInfo.status === VOTE_STATUS.closed ? "#FF6B6B" : "#B163FE",
          }}
        >
          <i className="fas fa-home vote-status-house" />
        </button>
      </div>
    </div>
  );
}

export default OneTypeVotingWidget;
